import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteData, getData, postFormDataWithToken, putEncodedData } from "../../../services/httphandler";

const initialState = {
    isLoading: false,
    faqs: '',
    faqDetails: '',
}

export const faq = state => state.faq.faqs?.faqs?.data;
export const totalFaq = state => state.faq.faqs?.faqs?.total;

export const AddFaqs = createAsyncThunk(
    'faqs/add',
    async (params, { dispatch }) => {
        let response = await postFormDataWithToken('cms/faq/add', params);
        if (response && response.response_code === 200) {
            dispatch(getFaqs())
        }
        return response
    }
)

export const UpdateFaq = createAsyncThunk(
    'faq/update',
    async (object, { dispatch }) => {
        let response = await putEncodedData('cms/faq/update', object);
        if (response && response.response_code === 200) {
            dispatch(getFaqs());
        }
        return response;


    }
)



export const deleteFaqs = createAsyncThunk(
    'faqs/delete',
    async (object, { dispatch }) => {
        const { id, filters } = object;
        let response = await deleteData('cms/faq/delete', { id });
        if (response && response.response_code === 200) {
            dispatch(getFaqs(filters));
            return response;
        }

    }
)

export const faqDetails = createAsyncThunk(
    'faq/details',
    async (object, { dispatch }) => {
        let response = await getData('cms/faq/detail', object);
        if (response && response.response_code === 200) {
            dispatch(getFaqs());
            return response.data;
        }

    }
)


export const getFaqs = createAsyncThunk(
    'getFaqs',
    async (object = {
        page: 1,
        limit: 10,
        active: 1,
        search: '',
    }, { dispatch }) => {
        let response = await getData('cms/faq/get', object);
        if (response && response.response_code === 200) {
            return response.data
        }
    }
)


const FaqSlice = createSlice({
    name: 'occasion',
    initialState,
    extraReducers: {
        // [addProduct.fulfilled]: (state, action) => {
        //     state.isLoading = false
        // },
        [getFaqs.fulfilled]: (state, action) => {
            state.faqs = action.payload
            state.isLoading = false
        },
        // [productDetails.fulfilled]: (state, action) => {
        //     state.productDetails = action.payload
        //     state.isLoading = false
        // }
    }

})

export default FaqSlice.reducer;