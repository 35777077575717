import { Redirect, Route, Switch } from "react-router"
import Sidebar from "../components/Sidebar";
import { router } from "../routes";

const FullLayout = () => {
    return (
        <div class="admin-panel">
            <div class="container-fluid px-0">
                <div class="row no-gutters">
                    <Sidebar />
                    <Switch>
                        {
                            router.map((prop, index) => {
                                if (prop.child && prop.child.length > 0) {
                                    return prop.child.map(data => {
                                        return <Route path={data.path} component={data.component} />
                                    })

                                }
                                else {
                                    return (
                                        <Route key={index} path={prop.path} component={prop.component} />
                                    )
                                }

                            })
                        }
                        <Redirect to="/admin/dashboard" />
                    </Switch>
                </div>
            </div>
        </div>

    )
}

export default FullLayout;