import React from "react";
import { Modal } from "reactstrap";

// .products li .image img {
//     object-fit: contain;
//     height: 100%;
//     width: 100%;
// }

function AddonModal({ show, handleShow, data }) {
  const handleFullImage = (i) => {
    let fullscreen = document.querySelector(`#fullImage${i}`);
    if (!document.fullscreenElement) {
      fullscreen.requestFullscreen();
    } else if (fullscreen.exitFullscreen) {
      document.exitFullscreen();
    }
  };
  return (
    <Modal className="modal-xl" isOpen={show} toggle={handleShow}>
      <div class="modal-content product-view-modal">
        <div
          className="modal-header"
          style={{
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Addons
        </div>
        <div class="modal-body text-capitalize">
          <div className="row">
            <div className="col-sm-12">
              <ul className="products">
                {data &&
                  data.images?.map((data, i) => {
                    return (
                      <li className="d-flex justify-content-between" key={i}>
                        <label>{data?.label}</label>
                        {data?.image ? (
                          <div className="image">
                            <img
                              id={`fullImage${i}`}
                              src={data.image || "assets/images/upload-img.png"}
                              alt=""
                              onClick={() => {
                                handleFullImage(i);
                              }}
                            />
                          </div>
                        ) : (
                          "No image added."
                        )}

                        {/* <div className="data">
                      <h2>{data.name || "N/A"}</h2>

                      <span>
                        <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                        {data.price}{" "}
                      </span>
                      <p>Units sold: {data?.total_purchase}</p>
                    </div> */}
                      </li>
                    );
                  })}

                {data &&
                  data.answers?.map((data, i) => {
                    return (
                      <li key={i} className="d-flex justify-content-between">
                        <label>{data?.product_label?.label}</label>
                        <p>{data?.answer}</p>

                        {/* <div className="data">
                      <h2>{data.name || "N/A"}</h2>

                      <span>
                        <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                        {data.price}{" "}
                      </span>
                      <p>Units sold: {data?.total_purchase}</p>
                    </div> */}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddonModal;
