import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function LineChart(props) {
  const labels = props.labels;
  const data = {
    labels,
    datasets: [
      {
        label: props.label,
        data: props?.data,
        // borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgb(255,255,255)",
        hoverOffset: 4,
        borderColor: "#fff",
        pointRadius: props.label === "Order" ? 1 : 4,
        pointBackgroundColor: props.label === "Order" ? "" : "black",
        borderWidth: props.label === "Order" ? 1 : 3,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      maintainAspectRatio: true,
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    // new version scaling
    scales: {
      x: {
        grid: {
          display: props?.full ? true : false,
          //   drawOnChartArea: false,
        },
        ticks: {
          display: props?.full ? true : false,
        },
      },

      y: {
        grid: {
          display: props?.full ? true : false,
          //   drawOnChartArea: false,
        },
        ticks: {
          beginAtZero: props?.full ? true : false,
          display: props?.full ? true : false,
        },
      },
    },
  };

  return <Line options={options} data={data} />;
}

export default LineChart;
