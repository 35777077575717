import React, { useCallback, useState } from "react";
import FormInputText from "../../../../components/FormInputText";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect } from "react";
import { getData, putEncodedData } from "../../../../services/httphandler";
import { toast } from "react-toastify";
import Loader from "../../../../components/loader";
function ManageCod() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const getDetails = useCallback(async (load = true) => {
    if (load) {
      setLoading(true);
    }
    const data = await getData("setting/cod/get");
    if (data && data?.response_code === 200) {
      console.log(data, "COD data");
      setData(data?.data);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const validationSchema = Yup.object().shape({
    label: Yup.string().required("*Title is Required"),
    cod_price: Yup.number()
      .min(0, "Must be a valid number")
      .required("*From Date is Required"),
  });

  const { values, handleSubmit, touched, errors, handleChange } = useFormik({
    initialValues: {
      cod_price: data?.cod_price,
      label: data?.label || "",
      id: data?.id || "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async ({ label, cod_price, id }) => {
      setSubmitting(true);

      await putEncodedData("setting/cod/update", {
        label,
        cod_price,
        id,
      })
        .then((response) => {
          if (response?.response_code === 200) {
            toast.success(response?.message);
            getDetails(false);
          } else {
            toast.error(response?.message);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });
  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <h2 class="head-title">Manage COD</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            {loading ? (
              <div className="">
                <Loader />
              </div>
            ) : (
              <form onSubmit={handleSubmit} class="form-style">
                <div class="row">
                  <div className="col-sm-12">
                    <div class="input-container">
                      <FormInputText
                        placeholder="Enter COD Label"
                        type="text"
                        image="product.png"
                        onChange={handleChange}
                        name="label"
                        value={values.label}
                        title="Label"
                      />
                      <span className="errorMessage">
                        {touched["label"] && errors["label"]}
                      </span>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="input-container">
                      <FormInputText
                        placeholder="Enter COD Amount"
                        type="number"
                        image="product.png"
                        onChange={handleChange}
                        name="cod_price"
                        value={values.cod_price}
                        title="Cod Price"
                      />
                    </div>

                    <span className="errorMessage">
                      {touched["cod_price"] && errors["cod_price"]}
                    </span>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="text-center">
                      <button class="btn btn-save" disabled={submitting}>
                        {submitting ? <Loader /> : "Save"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </article>
  );
}

export default ManageCod;
