import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import DeleteModal from "../../../components/deleteModal";
import { toast } from "react-toastify";
import {
  deleteRelation,
  relationList,
  relations,
  totalRelation,
} from "./relationSlice";
import { useCallback } from "react";
import { debounce } from "lodash";

const RelationList = () => {
  // const data = [];
  const dispatch = useDispatch();
  const History = useHistory();
  const [modal, setModal] = useState(false);
  const [id, setDeleteId] = useState("");
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    search: "",
  });
  const data = useSelector(relations);
  const total = useSelector(totalRelation);

  const listing = useCallback(() => {
    dispatch(relationList(filters));
  }, [dispatch, filters]);

  useEffect(() => {
    listing();
  }, [listing]);

  const handlePageChange = (pageNumber) => {
    setFilters({
      ...filters,
      page: pageNumber,
    });
  };

  const handleChange = debounce((e) => {
    setFilters({
      ...filters,
      search: e.target.value,
    });
  }, 300);

  const toggle = () => {
    setModal(!modal);
  };

  const deleteCat = () => {
    dispatch(deleteRelation({ id })).then((data) => {
      if (data?.payload?.response_code === 200) {
        toggle();
        toast.success("Success");
      } else {
        toast.error(data?.payload?.message);
      }
    });
  };

  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="link-active">
              <img alt="" src="assets/images/Categories-selected.png" />
              <span>
                <p>{total && total}</p>
                <p>Total Relations</p>
              </span>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="d-flex align-items-center lg-justify-content-end">
              <form class="has-search">
                <span class="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search Relations by name"
                  onChange={handleChange}
                />
              </form>
              <Link to="/admin/manage-relation/add" class="add-new">
                + Add New
              </Link>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive text-center">
              <table class="table storetable">
                <thead class="text-center">
                  <tr>
                    <th scope="col">image</th>
                    <th scope="col">Relation Name</th>
                    <th scope="col">action</th>
                  </tr>
                  <tr class="border-0 bg-none invisible">
                    <td class="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <div
                              style={{
                                height: "80px",
                                width: "80px",
                                margin: "0 auto",
                                borderRadius: "10px",
                                overflow: "hidden",
                              }}
                            >
                              <img
                                alt=""
                                src={data.image || "assets/images/employe.png"}
                                class="object-fit-cover"
                              />
                            </div>
                          </td>
                          <td>{data.name}</td>
                          <td>
                            <div class="d-flex justify-content-center action">
                              <button
                                onClick={() =>
                                  History.push(
                                    `/admin/manage-relation/edit/${data.id}`
                                  )
                                }
                                class="btn"
                              >
                                <img alt="" src="assets/images/edit.png" />
                              </button>
                              <button
                                onClick={() => {
                                  setDeleteId(data.id);
                                  toggle();
                                }}
                                class="btn"
                              >
                                <img alt="" src="assets/images/delete.png" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4}>No Data Found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {total && total > 10 ? (
          <div>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.limit}
              totalItemsCount={total}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </div>
        ) : (
          ""
        )}
      </div>

      <DeleteModal deleteCategory={deleteCat} modal={modal} toggle={toggle} />
    </article>
  );
};

export default RelationList;
