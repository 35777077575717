import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import DeleteModal from "../../../components/deleteModal";
import { toast } from "react-toastify";

function BlogList() {
  const dispatch = useDispatch();
  const History = useHistory();
  const [modal, setModal] = useState(false);
  const [id, setDeleteId] = useState("");
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    active: 1,
    search: "",
  });

  //   const data = useSelector(faq);
  //   const total = useSelector(totalFaq);
  //   console.log(data, "data");

  useEffect(() => {
    // dispatch(getFaqs(filters));
  }, [dispatch, filters]);

  const handlePageChange = (pageNumber) => {
    setFilters({
      ...filters,
      page: pageNumber,
    });
  };

  // const handleChange = ({ name, e }) => {
  //     setFilters({
  //         ...filters,
  //         [name]: e.target.value
  //     })
  // }

  const toggle = () => {
    setModal(!modal);
  };

  const deleteCat = () => {
    // dispatch(deleteFaqs({ filters, id })).then((data) => {
    //   if (data?.payload?.response_code === 200) {
    //     toggle();
    //     toast.success("Success");
    //   } else {
    //     toast.error(data?.payload?.message);
    //   }
    // });
  };

  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="link-active">
              <img src="assets/images/Users-selected.png" alt="" />
              <span>
                {/* <p>{total && total}</p> */}
                <p>Total Blogs</p>
              </span>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="d-flex align-items-center lg-justify-content-end">
              {/* <form class="has-search">
                                <span class="form-search-icon form-control-feedback"></span>
                                <input type="search" class="form-control" placeholder="Search faqs by name"
                                    onChange={(e) => handleChange({
                                        name: 'search',
                                        e
                                    })} />
                            </form> */}
              <Link to="/admin/blog/add" class="add-new">
                + Add New
              </Link>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-sm-12">
            <div class="col-sm-12">
              <div class="tab-content mt-4">
                <div id="inactive-product" class="tab-pane active">
                  <div class="table-responsive text-center">
                    <table class="table storetable">
                      <thead class="text-center">
                        <tr>
                          <th scope="col">Sr. No.</th>
                          <th scope="col">title</th>
                          <th scope="col">category</th>
                          <th scope="col">action</th>
                        </tr>
                        <tr class="border-0 bg-none invisible">
                          <td class="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        {Array(5)?.fill().length > 0 ? (
                          Array(5)
                            ?.fill()
                            .map((dataElem, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>fcd</td>
                                  <td>edfds</td>

                                  <td>
                                    <div class="d-flex justify-content-center action">
                                      <button
                                        onClick={() =>
                                          History.push(
                                            `/admin/blog/edit/${index}`
                                          )
                                        }
                                        class="btn"
                                      >
                                        <img
                                          alt=""
                                          src="assets/images/edit.png"
                                        />
                                      </button>

                                      <button
                                        onClick={() => {
                                          setDeleteId(index);
                                          toggle();
                                        }}
                                        class="btn"
                                      >
                                        <img
                                          src="assets/images/delete.png"
                                          alt=""
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                        ) : (
                          <tr>
                            <td colSpan={7}>No Data Found.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {total && total > 10 ? (
          <div>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.limit}
              totalItemsCount={total}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </div>
        ) : (
          ""
        )} */}
      </div>
      <DeleteModal deleteCategory={deleteCat} modal={modal} toggle={toggle} />
    </article>
  );
}

export default BlogList;
