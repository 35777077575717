const FormInputText = ({
  onChange,
  placeholder,
  value,
  name,
  image,
  title,
  type,
  min,
  readOnly,
  onBlur,
  onInput,
}) => {
  return (
    <div class="form-group">
      <label>{title || "Email Address"}</label>
      <div class="input-container">
        <input
          onChange={onChange}
          type={type || "email"}
          value={value}
          name={name}
          min={min || ""}
          class="form-control pl-5"
          placeholder={placeholder || "Email Address"}
          readOnly={readOnly}
          onBlur={onBlur}
          onInput={onInput}
        />
        <img
          alt=""
          src={image ? `assets/images/${image}` : "assets/images/email-id.png"}
          class="input-img"
        />
      </div>
    </div>
  );
};

export default FormInputText;
