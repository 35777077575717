import { Modal } from "reactstrap";
import React from "react";

const ProductModal = ({ data, text = "Products" }) => {
  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);
  return (
    <>
      <span
        style={{
          color: "blue",
          fontSize: "16px",
          cursor: "pointer",
        }}
        onClick={toggle}
      >
        see all
      </span>
      <Modal className="modal-xl" isOpen={modal} toggle={toggle}>
        <div class="modal-content product-view-modal">
          <div
            className="modal-header"
            style={{
              fontSize: "25px",
              fontWeight: "bold",
            }}
          >
            {text}
          </div>
          <div class="modal-body text-capitalize">
            <ul className="products">
              {data &&
                data.map((data) => {
                  return (
                    <li>
                      <div className="image">
                        <img
                          src={
                            data.images[0].name ||
                            "assets/images/upload-img.png"
                          }
                          alt=""
                        />
                      </div>

                      <div className="data">
                        <h2>{data.name || "N/A"}</h2>
                        {/* <p>{data.sizes && data.sizes.length > 0 ? (data.sizes.map(data => data.size + ',') || 'N/A') : 'N/A'}</p> */}
                        <span>
                          <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                          {data.price}{" "}
                        </span>
                        <p>Units sold: {data?.total_purchase}</p>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProductModal;
