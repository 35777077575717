import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { toast } from "react-toastify";
// import { deleteProduct, productList, toggleProduct } from "./productSlice";
import { categoryList } from "../ManageCategory/categorySlice";
import DateFilterModal from "../../../components/dateFilter";
import { subCategoryList } from "../ManageCategory/subCategorySlice";
import LightBoxImage from "../../../components/lightbox";
import { productDetails, productList } from "../ManageProduct/productSlice";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import FormInputText from "../../../components/FormInputText";
import { putEncodedData, putFormData } from "../../../services/httphandler";
import { useCallback } from "react";
import { debounce } from "lodash";

const InventoryList = () => {
  const dispatch = useDispatch();
  const History = useHistory();
  const [state, setstate] = useState([]);

  const [modal, setModal] = useState(false);
  const [unit, setUnit] = useState(0);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [id, setId] = useState("");
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    active: 1,
    search: "",
    category: "",
  });

  const data = useSelector((state) => state.product.productList.data);
  const total = useSelector((state) => state.product.productList.total);
  const catList = useSelector((state) => state.category.categoryList.data);
  const subCatList = useSelector(
    (state) => state.subCategory.subCategoryList.data
  );

  useEffect(() => {
    dispatch(categoryList({ page: "1", limit: "1000", search: "" }));
    dispatch(subCategoryList({ page: "1", limit: "1000", search: "" }));
  }, [dispatch]);

  const listing = useCallback(() => {
    dispatch(productList(filters));
  }, [dispatch, filters]);

  useEffect(() => {
    listing();
  }, [listing]);

  const handlePageChange = (pageNumber) => {
    setFilters({
      ...filters,
      page: pageNumber,
    });
  };

  const handleChange = debounce(({ name, e }) => {
    setFilters({
      ...filters,
      [name]: e.target.value,
    });
  }, 300);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleTab = (val) => {
    setFilters({
      ...filters,
      active: val,
      page: 1,
    });
  };

  const dateHandler = (object) => {
    const { startDate, endDate } = object;
    setFilters({
      ...filters,
      startDate,
      endDate,
    });
  };

  const updateUnit = async () => {
    let response = await putEncodedData("product/updateUnit", {
      id,
      unit,
    });

    if (response && response.response_code === 200 && response?.success) {
      toast.success(response.message);
      toggle();
      dispatch(productList(filters));
    } else {
      toast.error(response.message);
    }
  };

  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="link-active">
              <img src="assets/images/Users-selected.png" alt="" />
              <span>
                <p>{total && total}</p>
                <p>Total Products</p>
              </span>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="d-flex align-items-center lg-justify-content-end">
              <form class="has-search">
                <span class="form-search-icon form-control-feedback"></span>
                <input
                  type="search"
                  class="form-control"
                  placeholder="Search Product by name"
                  onChange={(e) =>
                    handleChange({
                      name: "search",
                      e,
                    })
                  }
                />
              </form>
              {/* <Link to="manage-products/add" class="add-new" >+ Add New</Link> */}
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-sm-12">
            <div class="col-sm-12">
              <div class="row align-items-center">
                <div class="col-sm-12 col-md-6 col-lg-5 mb-3">
                  <ul class="nav nav-tabs group-nav" role="tablist">
                    <li class="nav-item">
                      <Link
                        className={
                          `nav-link ` + (filters.active ? "active" : "")
                        }
                        onClick={() => toggleTab(1)}
                        data-toggle="tab"
                        to="#"
                      >
                        active
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        className={
                          `nav-link ` + (filters.active ? "" : "active")
                        }
                        onClick={() => toggleTab(0)}
                        data-toggle="tab"
                        to="#"
                      >
                        inactive
                      </Link>
                    </li>
                  </ul>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-7 mb-3">
                  <div class="d-flex filter-flex align-items-center lg-justify-content-end">
                    <span>Filter</span>
                    <span class="ml-3">
                      <select
                        onChange={(e) =>
                          handleChange({
                            name: "category",
                            e,
                          })
                        }
                        class="form-control h-auto"
                      >
                        <option value={""}>Select Category</option>
                        {catList &&
                          catList.map((data) => {
                            return <option value={data.id}>{data.name}</option>;
                          })}
                      </select>
                    </span>
                    <span class="ml-3">
                      <select
                        onChange={(e) =>
                          handleChange({
                            name: "subCategory",
                            e,
                          })
                        }
                        class="form-control h-auto"
                      >
                        <option value={""}>Select Sub-Category</option>
                        {subCatList &&
                          subCatList.map((data) => {
                            return <option value={data.id}>{data.name}</option>;
                          })}
                      </select>
                    </span>
                    <DateFilterModal dateHandler={dateHandler} />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="tab-content mt-4">
                <div id="inactive-product" class="tab-pane active">
                  <div class="table-responsive text-center">
                    <table class="table storetable">
                      <thead class="text-center">
                        <tr>
                          <th scope="col">product image</th>
                          <th scope="col">SKU ID</th>
                          <th scope="col">product Name</th>
                          <th scope="col">Units Left </th>
                          <th scope="col">action</th>
                        </tr>
                        <tr class="border-0 bg-none invisible">
                          <td class="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        {data && data.length > 0 ? (
                          data
                            .slice()
                            .sort(function (a, b) {
                              return a.unit - b.unit;
                            })
                            .map((dataElem) => {
                              return (
                                <tr key={dataElem.id}>
                                  <td>
                                    <div
                                      style={{
                                        height: "80px",
                                        width: "80px",
                                        margin: "0 auto",
                                        borderRadius: "10px",
                                        overflow: "hidden",
                                      }}
                                    >
                                      <img
                                        onClick={() => {
                                          setPhotoIndex(0);
                                          setstate(
                                            dataElem.images.map((data) => {
                                              return {
                                                url: data.name,
                                                title: "Banner Images",
                                              };
                                            })
                                          );
                                        }}
                                        src={
                                          dataElem.images[0].name ||
                                          "assets/images/employe.png"
                                        }
                                        class="object-fit-cover"
                                        alt=""
                                      />
                                    </div>
                                  </td>
                                  <td
                                    className={
                                      dataElem.unit < 10 ? "active" : ""
                                    }
                                  >
                                    {dataElem.sku_id}
                                  </td>
                                  <td
                                    className={
                                      dataElem.unit < 10 ? "active" : ""
                                    }
                                  >
                                    {dataElem.name}
                                  </td>

                                  <td
                                    className={
                                      dataElem.unit < 10 ? "active" : ""
                                    }
                                  >
                                    {dataElem.unit || "N/A"}
                                  </td>
                                  <td>
                                    <Link
                                      style={{ fontSize: "12px" }}
                                      to="#"
                                      onClick={() => {
                                        setId(dataElem.id);
                                        setUnit(dataElem.unit);
                                        toggle();
                                      }}
                                      class="add-new"
                                    >
                                      + Add Units
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })
                        ) : (
                          <tr>
                            <td colSpan={8}>No Data Found.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {state.length > 0 && (
          <LightBoxImage
            mainSrc={state[photoIndex]}
            onClose={() => setstate([])}
            images={state}
            nextSrc={state[(photoIndex + 1) % state.length]}
            prevSrc={state[(photoIndex + state.length - 1) % state.length]}
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + state.length + 1) % state.length)
            }
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + state.length - 1) % state.length)
            }
          />
        )}
        {total && total > 10 ? (
          <div>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.limit}
              totalItemsCount={total}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>Update Unit</ModalHeader>
        <ModalBody>
          <form className="form-style m-0">
            <label>Unit</label>
            <input
              type="number"
              onChange={(e) => setUnit(e.target.value)}
              value={unit}
              placeholder="Enter Unit"
              className="form-control"
            />
            {/* <FormInputText
                            placeholder="Enter Unit"
                            type="text"
                            image="product.png"
                            onChange={(e) => setUnit(e.target.value)}
                            value={unit}
                            title="Units"
                        /> */}
          </form>
        </ModalBody>
        <ModalFooter>
          <button onClick={updateUnit} className="btn btn-primary">
            Update
          </button>
          <button type="button" onClick={toggle} className="btn btn-danger">
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </article>
  );
};
export default InventoryList;
