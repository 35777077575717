import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import FormInputText from '../../../components/FormInputText';
import { getContactNumber, getContactUs, updateContactNumber, updateContactUs } from "./cmsSlice";

const UpdateContactNumber = () => {

    const dispatch = useDispatch();
    const [params, setparams] = useState({
        phone: "",
    });


    useEffect(() => {
        dispatch(getContactNumber()).then(data => {
            const { phone } = data.payload.data.contactNumber
            setparams({
                phone: phone,
            })

        })
    }, [dispatch]);





    const validationSchema = Yup.object().shape({
        phone: Yup.string().required('*phone is Required'),

    });



    const { values, handleSubmit, touched, errors, handleChange, } = useFormik({
        initialValues: params,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async () => {
            dispatch(updateContactNumber(values)).then(data => {
                if (data?.payload?.response_code === 200) {
                    toast.success(data?.payload?.message);
                }
                else {
                    toast.error(data?.payload?.message);
                }
            })
        }

    })





    return (
        <article class="col-lg-9">
            <div class="article-body store-bd-gy">
                <div class="row align-items-center col-rever">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <h2 class="head-title">Update Contact Number</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <form onSubmit={handleSubmit} class="form-style">
                            <div class="row">
                                <div class="col-md-2 col-lg-2"></div>
                                <div class="col-sm-12 col-md-8 col-lg-8">

                                    <FormInputText
                                        placeholder="Enter phone number"
                                        type="text"
                                        image="product.png"
                                        onChange={handleChange}
                                        name="phone"
                                        value={values.phone}
                                        title="Phone Number"
                                    />

                                    <span className="errorMessage">
                                        {touched["phone"] && errors["phone"]}
                                    </span>
                                </div>


                                <div class="col-sm-12 col-md-12 col-lg-12 mt-5">
                                    <div class="text-center">
                                        <button type="submit" class="btn btn-save">Save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </article>
    )
}

export default UpdateContactNumber;