import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import FormInputText from '../../../components/FormInputText';
import Upload from '../../../components/uploadFile.js'
import { toast } from 'react-toastify';
import { relationDetails, updateRelation } from './relationSlice';

const EditRelation = () => {

    const [state, setstate] = useState({
        id: '',
        image: '',
        name: ''
    })
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('*Category Name is Required'),
    });

    const {
        values,
        handleSubmit,
        touched,
        errors,
        handleChange,
    } = useFormik({
        initialValues: state,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async () => {
            if (state && state.image) {
                dispatch(updateRelation(values, { dispatch })).then(data => {
                    if (data?.payload?.response_code === 200) {
                        history.push('/admin/manage-relation');
                        toast.success('Success');
                    }
                    else {
                        toast.error(data?.payload?.message);
                    }
                })
            }
            else {
                toast.error('*Please add image.')
            }

        },
    });

    useEffect(() => {
        dispatch(relationDetails({ id })).then(data => {
            setstate({
                id: data.payload.relation.id,
                image: data.payload.relation.image,
                name: data.payload.relation.name
            })
        })
    }, [dispatch, id]);

    const dataHandler = (data) => {
        setstate({
            ...state,
            image: data.location
        })
    }
    return (
        <article class="col-lg-9">
            <div class="article-body store-bd-gy">
                <div class="row align-items-center col-rever">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <h2 class="head-title">Edit Relation</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <form onSubmit={handleSubmit} class="form-style">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                                    <Upload dataHandler={dataHandler} imager={state.image} />
                                </div>
                                <div class="col-sm-12 col-md-2 col-lg-2"></div>
                                <div class="col-sm-12 col-md-8 col-lg-8">
                                    <FormInputText
                                        placeholder="Enter Relation Name"
                                        image="small-cate.png"
                                        onChange={handleChange}
                                        name="name"
                                        type="text"
                                        value={values.name}
                                        title="Relation Name"
                                    />


                                    <span className="errorMessage">
                                        {touched["name"] && errors["name"]}
                                    </span>

                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="text-center">
                                        <button type="submit" class="btn btn-save">Save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </article>
    )
}

export default EditRelation;