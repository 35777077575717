import "./App.css";
import { Provider } from "react-redux";
import { store } from "./store";
import { BrowserRouter, Route } from "react-router-dom";
import { Switch } from "react-router";
import BlankLayout from "./layouts/blankLayout";
import FullLayout from "./layouts/fullLayout";
import { PrivateRoute } from "./routes/privateRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ToastContainer autoClose={2000} theme="colored" />
        <BrowserRouter>
          <Switch>
            <Route path="/auth" component={BlankLayout} />
            <PrivateRoute path="/" component={FullLayout} />
            {/* {
              routes.map(prop => {
                return (
                  <Route path={prop.path} component={prop.element} />
                )
              })
            } */}
          </Switch>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
