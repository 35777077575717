import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteData, getData, postFormDataWithToken, putEncodedData } from "../../../services/httphandler";

const initialState = {
    isLoading: false,
    occasionList: '',
    occasionDetails: '',
}

export const occasions = state => state.occasion.occasionList?.occasions?.data;
export const totalOccasion = state => state.occasion.occasionList?.occasions?.total;

export const addOccasion = createAsyncThunk(
    'occasion/add',
    async (params, { dispatch }) => {
        let response = await postFormDataWithToken('occasion/add', params);
        if (response && response.response_code === 200) {
            dispatch(occasionList())
        }
        return response
    }
)

export const updateOccasion = createAsyncThunk(
    'updateOccasion',
    async (object, { dispatch }) => {
        let response = await putEncodedData('occasion/update', object);
        if (response && response.response_code === 200) {
            dispatch(occasionList());
        }
        return response;


    }
)

export const toggleOccasion = createAsyncThunk(
    'toggleOccasion',
    async (object, { dispatch }) => {
        const { id, filters } = object;
        console.log(object, 'object')
        let response = await putEncodedData('occasion/toggle', { id });
        if (response && response.response_code === 200) {
            dispatch(occasionList(filters));
            return response;
        }

    }
)


export const deleteOccasion = createAsyncThunk(
    'occasion/delete',
    async (object, { dispatch }) => {
        const { id, filters } = object;
        let response = await deleteData('occasion/delete', { id });
        if (response && response.response_code === 200) {
            dispatch(occasionList(filters));
            return response;
        }

    }
)

export const occasionDetails = createAsyncThunk(
    'occasion/id',
    async (object, { dispatch }) => {
        let response = await getData('occasion/detail', object);
        if (response && response.response_code === 200) {
            dispatch(occasionList());
            return response.data;
        }

    }
)


export const occasionList = createAsyncThunk(
    'occasionList',
    async (object = {
        page: 1,
        limit: 10,
        active: 1,
        search: '',
    }, { dispatch }) => {
        let response = await getData('occasion/get', object);
        if (response && response.response_code === 200) {
            return response.data
        }
    }
)


const OccasionSLice = createSlice({
    name: 'occasion',
    initialState,
    extraReducers: {
        // [addProduct.fulfilled]: (state, action) => {
        //     state.isLoading = false
        // },
        [occasionList.fulfilled]: (state, action) => {
            state.occasionList = action.payload
            state.isLoading = false
        },
        // [productDetails.fulfilled]: (state, action) => {
        //     state.productDetails = action.payload
        //     state.isLoading = false
        // }
    }

})

export default OccasionSLice.reducer;