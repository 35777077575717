import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteData, getData } from "../../../services/httphandler";

const initialState = {
    isLoading: false,
    customerList: [],
    customerDetails: ''
}

export const customers = state => state.customer.customerList?.customers?.data;
export const detailsCustomer = state => state.customer.customerDetails?.customer;
export const totalCustomers = state => state.customer.customerList?.customers?.total;


export const getCustomer = createAsyncThunk(
    'customer',
    async (object = { page: 1, limit: 10 }) => {
        let response = await getData('customer/get', object);
        return response.data;
    }
)

export const customerDetails = createAsyncThunk(
    'customer/details',
    async (object) => {
        let response = await getData('customer/detail', object);
        return response.data
    }
)

export const deleteCustomer = createAsyncThunk(
    'customer/delete',
    async (object, { dispatch }) => {
        const { id, filters } = object;
        let response = await deleteData('customer/delete', { id });
        if (response && response.response_code === 200) {
            dispatch(getCustomer(filters));
        }
        return response;

    }
)


const CustomerSlice = createSlice({
    name: 'customer',
    initialState,
    extraReducers: {
        [getCustomer.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.customerList = action.payload;
        },
        [customerDetails.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.customerDetails = action.payload
        }
    }

})

export default CustomerSlice.reducer;