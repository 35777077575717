import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import DeleteModal from "../../../components/deleteModal";
import { toast } from "react-toastify";
import {
  cancel,
  deleteOrder,
  delivered,
  getOrder,
  orders,
  pending,
  progress,
  returned,
  totalOrders,
} from "./orderSlice";
import { getPaymentMode } from "../../../services/helper";
import { categoryList } from "../ManageCategory/categorySlice";
import { subCategoryList } from "../ManageCategory/subCategorySlice";
import { useCallback } from "react";
import { debounce } from "lodash";

const OrdersList = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [id, setDeleteId] = useState("");
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    search: "",
  });

  const data = useSelector(orders);
  const total = useSelector(totalOrders);
  const totalCancel = useSelector(cancel);
  const totaldelivered = useSelector(delivered);
  const totalpending = useSelector(pending);
  const totalprogress = useSelector(progress);
  const totalreturned = useSelector(returned);
  const catList = useSelector((state) => state.category.categoryList.data);

  useEffect(() => {
    dispatch(categoryList({ page: "1", limit: "1000", search: "" }));
    dispatch(subCategoryList({ page: "1", limit: "1000", search: "" }));
  }, [dispatch]);

  const listing = useCallback(() => {
    dispatch(getOrder(filters));
  }, [dispatch, filters]);

  useEffect(() => {
    listing();
  }, [listing]);

  const handlePageChange = (pageNumber) => {
    setFilters({
      ...filters,
      page: pageNumber,
    });
  };

  const handleChange = debounce((e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  }, 300);

  const toggle = () => {
    setModal(!modal);
  };

  const deleteCat = () => {
    dispatch(deleteOrder({ id, filters })).then((data) => {
      if (data?.payload?.response_code === 200) {
        toggle();
        toast.success(data?.payload.message);
      } else {
        toast.error(data?.payload?.message);
      }
    });
  };
  // const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div className="orderGrid charts p-0">
          <div class=" mb-3">
            <div className="wrapper">
              <div className="mb-4 px-4 pt-2 pb-2 text-center orderGridWidth">
                <p>Total Orders</p>
                <p>{total && total}</p>
                {/* <span> {data && data?.order_graph.percentage_diff.toFixed(0)}% (30 days)</span> */}
              </div>
            </div>
          </div>
          <div class=" mb-3">
            <div className="wrapper green">
              <div className="mb-4 px-4 pt-2 pb-2 text-center orderGridWidth">
                <p>Total Pending</p>
                <p>{totalpending && totalpending}</p>
                {/* <span> {data && data?.order_graph.percentage_diff.toFixed(0)}% (30 days)</span> */}
              </div>
            </div>
          </div>
          <div class=" mb-3">
            <div className="wrapper blue">
              <div className="mb-4 px-4 pt-2 pb-2 text-center orderGridWidth">
                <p>Total Cancel</p>
                <p>{totalCancel && totalCancel}</p>

                {/* <span> {data && data?.order_graph.percentage_diff.toFixed(0)}% (30 days)</span> */}
              </div>
            </div>
          </div>
          <div class=" mb-3">
            <div className="wrapper yellow">
              <div className="mb-4 px-4 pt-2 pb-2 text-center orderGridWidth">
                <p>Total Returned</p>
                <p>{totalreturned && totalreturned}</p>
              </div>
            </div>
          </div>
          <div class=" mb-3">
            <div className="wrapper">
              <div className="mb-4 px-4 pt-2 pb-2 text-center orderGridWidth">
                <p>Total Delivered</p>
                <p>{totaldelivered && totaldelivered}</p>

                {/* <span> {data && data?.order_graph.percentage_diff.toFixed(0)}% (30 days)</span> */}
              </div>
            </div>
          </div>

          <div class=" mb-3">
            <div className="wrapper green">
              <div className="mb-4 px-4 pt-2 pb-2 text-center orderGridWidth">
                <p>Total In Progress</p>
                <p>{totalprogress && totalprogress}</p>

                {/* <span> {data && data?.order_graph.percentage_diff.toFixed(0)}% (30 days)</span> */}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="row align-items-center">
              <div class="col-sm-12 col-md-5 col-lg-5 mb-3">
                <div class="d-flex align-items-center ">
                  <form class="has-search">
                    <span class="form-search-icon form-control-feedback"></span>
                    {/* <input type="text" class="form-control" placeholder="Search Order by name, ID" /> */}
                    <input
                      type="text"
                      class="form-control"
                      name="search"
                      placeholder="Search Order by name"
                      onChange={handleChange}
                    />
                  </form>
                </div>
              </div>
              <div class="col-sm-12 col-md-7 col-lg-7 mb-3">
                <div class="d-flex filter-flex align-items-center lg-justify-content-end">
                  <span>Filter</span>
                  <span class="ml-3">
                    <select
                      name="category"
                      onChange={handleChange}
                      class="form-control h-auto"
                    >
                      <option value={""}>Select Category</option>
                      {catList &&
                        catList.map((data) => {
                          return (
                            <option value={data?.name}>{data?.name}</option>
                          );
                        })}
                    </select>
                  </span>
                  <span class="ml-3">
                    <select
                      onChange={handleChange}
                      name="status"
                      class="form-control h-auto"
                    >
                      <option value="">Select Status</option>
                      <option value="pending">Pending</option>
                      <option value="in progress">In Progress</option>
                      <option value="delivered">Delivered</option>
                      <option value="returned">Returned</option>
                      <option value="cancel">Cancel</option>
                    </select>
                  </span>
                  <span class="ml-3">
                    <input
                      onFocus={(e) => (e.target.type = "date")}
                      type="text"
                      placeholder="From Date"
                      name="fromDate"
                      onChange={handleChange}
                      class="form-control h-auto"
                    />
                  </span>
                  <span class="ml-3">
                    <input
                      type="text"
                      onFocus={(e) => (e.target.type = "date")}
                      placeholder="To Date"
                      name="toDate"
                      onChange={handleChange}
                      class="form-control h-auto"
                    />
                  </span>
                  {/* <span class="ml-3">
                                        <select
                                            onChange={(e) => handleChange({
                                                name: 'subCategory',
                                                e
                                            })}
                                            class="form-control h-auto">
                                            <option value={""}>Select Sub-Category</option>
                                            {subCatList && subCatList.map(data => {
                                                return (
                                                    <option value={data?.id}>{data?.name}</option>
                                                )
                                            })}
                                        </select>
                                    </span>
                                    <DateFilterModal dateHandler={dateHandler} /> */}
                </div>
              </div>
            </div>

            <hr />
          </div>
          <div class="col-sm-12">
            <div class="table-responsive text-center">
              <table class="table storetable">
                <thead class="text-center">
                  <tr>
                    <th scope="col">order iD</th>
                    <th scope="col">customer name</th>
                    <th scope="col">product name</th>
                    <th scope="col">mobile number </th>
                    <th scope="col">payment type</th>
                    <th scope="col">Total Price</th>
                    <th scope="col">Coupon Applied</th>
                    <th scope="col">Payable Amount</th>
                    <th scope="col">order date</th>
                    <th scope="col">delivery date</th>
                    <th scope="col">Status</th>
                    <th scope="col">action</th>
                  </tr>
                  <tr class="border-0 bg-none invisible">
                    <td class="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {data && data?.length > 0 ? (
                    data?.map((data, i) => {
                      return (
                        <tr>
                          <td>{data?.bog_order_id}</td>
                          <td>
                            {data?.customer?.first_name
                              ? data?.customer?.first_name +
                                " " +
                                data?.customer?.last_name
                              : data?.customr?.first_name
                              ? data?.customr?.first_name +
                                " " +
                                data?.customr?.last_name
                              : "N/A"}
                          </td>
                          <td class="product-name">
                            {data?.order_product?.length > 0
                              ? data?.order_product.map(
                                  (data) => data?.product_name + ","
                                )
                              : data?.orderProdct?.length > 0
                              ? data?.orderProdct.map(
                                  (data) => data?.product_name + ","
                                )
                              : "NA"}
                          </td>
                          <td>
                            {data?.customer?.mobile_number
                              ? data?.customer?.country_code +
                                " " +
                                data?.customer?.mobile_number
                              : data?.customr?.mobile_number
                              ? data?.customr?.country_code +
                                " " +
                                data?.customr?.mobile_number
                              : "NA"}
                          </td>
                          <td>{getPaymentMode(data?.payment_mode)}</td>
                          <td>
                            <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                            {data?.total_price}
                          </td>
                          <td>{data?.coupon_applied || "NA"}</td>
                          <td>
                            <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                            {data?.total_payable}
                          </td>
                          <td>{data?.created_at}</td>
                          <td>{data?.delivery_date || "N/A"}</td>
                          <td>{data?.status}</td>
                          <td>
                            <div class="d-flex justify-content-center action">
                              <button
                                onClick={() =>
                                  window.open(
                                    `https://bookyourgift.in/bookyourgift-api/export/orderDetailPdf?id=${data?.id}`,
                                    "_blank"
                                  )
                                }
                                class="btn"
                              >
                                <i
                                  style={{
                                    color: "green",
                                  }}
                                  class="fa fa-file-excel-o"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <button class="btn">
                                <Link
                                  to={`/admin/manage-order/view/${data?.id}`}
                                >
                                  <img alt="" src="assets/images/view.png" />
                                </Link>
                              </button>
                              <button
                                onClick={() => {
                                  setDeleteId(data?.id);
                                  toggle();
                                }}
                                class="btn"
                              >
                                <img src="assets/images/delete.png" alt="" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10}>No Data Found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {total && total > 10 ? (
          <div>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.limit}
              totalItemsCount={total}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <DeleteModal deleteCategory={deleteCat} modal={modal} toggle={toggle} />
    </article>
  );
};

export default OrdersList;
