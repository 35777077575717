import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import FormInputText from "../../../components/FormInputText";
import Upload from "../../../components/uploadFile.js";
import { addCoupon } from "./couponSlice";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { categoryList } from "../ManageCategory/categorySlice";
import { useCallback } from "react";
import { subCategoryList } from "../ManageCategory/subCategorySlice";
import { productList } from "../ManageProduct/productSlice";

const reactSelectMultipleOptionStyle = {
  valueContainer: (styles) => ({
    ...styles,
    overflow: "hidden",
    overflowY: "scroll",
    height: "50px",
  }),
};

const AddCoupon = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [image, setImage] = useState({
    imagePreview: "",
    image: "",
  });

  const categoryListing = useSelector(
    (state) => state.category.categoryList?.data
  );
  const subCategoryData = useSelector(
    (state) => state.subCategory.subCategoryList?.data
  );
  const productListings = useSelector(
    (state) => state.product?.productList?.data
  );

  const categoryListingHandler = useCallback(() => {
    dispatch(categoryList({ page: 1 }));
  }, [dispatch]);

  useEffect(() => {
    categoryListingHandler();
  }, [categoryListingHandler]);

  const validationSchema = Yup.object().shape({
    code: Yup.string().required("*Coupon Code is Required"),
    discount_type: Yup.string().required("*Discount Type is Required"),
    name: Yup.string().required("*Name is Required"),
    discount: Yup.string().when(["discount_type"], {
      is: (discount_type) => discount_type === "percentage",
      then: Yup.string()
        .max(2, "*Discount Percentage must be less than 100.")
        .required("*Discount Percentage is Required"),
    }),
    max_discount: Yup.string().when(["discount_type"], {
      is: (discount_type) => discount_type === "percentage",
      then: Yup.string().required("*Max Discount Amount is Required"),
    }),
    fixed_amount: Yup.string().when(["discount_type"], {
      is: (discount_type) => discount_type === "fixed",
      then: Yup.string().required("*Fixed Amount is Required"),
    }),
    max_order_value: Yup.string().when(["discount_type"], {
      is: (discount_type) => discount_type === "fixed",
      then: Yup.string().required("*Max Order Value is Required"),
    }),
    order_amount: Yup.string().when(["discount_type"], {
      is: (discount_type) => discount_type === "freeShipping",
      then: Yup.string().required("*Order Amount is Required"),
    }),
    image: Yup.string().required("*Image is Required"),
    description: Yup.string().required("*Description is Required"),

    coupon_category_ids: Yup.array(),
    coupon_sub_category_ids: Yup.array(),
    coupon_product_ids: Yup.array(),
    is_new_user: Yup.boolean(),
    is_specific_user: Yup.boolean(),
    mobile_numbers: Yup.array(),
    mobile_number: Yup.string().when(["is_specific_user"], {
      is: (user_type) => user_type?.toString() === "1",
      then: Yup.string().required("*Mobile Number is Required"),
    }),
    from_date: Yup.string(),
    to_date: Yup.string(),
    payment_mode: Yup.string(),
    buy_one: Yup.string().when(["discount_type"], {
      is: (discount_type) => discount_type === "freeShipping",
      then: Yup.string().required("*Buy Quantity is Required"),
    }),
    get_one: Yup.string().when(["discount_type"], {
      is: (discount_type) => discount_type === "freeShipping",
      then: Yup.string().required("*Get Quantity Value is Required"),
    }),
    user_type: Yup.string(),
  });

  const {
    values,
    handleSubmit,
    touched,
    errors,
    handleChange,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = useFormik({
    initialValues: {
      code: "",
      discount_type: "",
      name: "",
      discount: "",
      image: "",
      description: "",
      coupon_category_ids: "",
      coupon_sub_category_ids: "",
      coupon_product_ids: "",
      is_new_user: 0,
      is_specific_user: 0,
      mobile_numbers: "",
      mobile_number: "",
      from_date: "",
      to_date: "",
      payment_mode: "",
      fixed_amount: "",
      max_discount: "",
      max_order_value: "",
      order_amount: "",
      buy_one: "",
      get_one: "",
      user_type: "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: ({
      code,
      discount_type,
      name,
      discount,
      image,
      description,
      coupon_category_ids,
      coupon_sub_category_ids,
      coupon_product_ids,
      is_new_user,
      is_specific_user,
      mobile_number,
      from_date,
      to_date,
      payment_mode,
      fixed_amount,
      max_discount,
      max_order_value,
      order_amount,
      buy_one,
      get_one,
    }) => {
      let formdata = new FormData();
      formdata.append("code", code);
      formdata.append("discount_type", discount_type);
      formdata.append("name", name);
      formdata.append("discount", discount);
      formdata.append("description", description);
      formdata.append(
        "coupon_category_ids",
        coupon_category_ids?.length > 0
          ? coupon_category_ids?.map((it) => it?.id)?.toString()
          : ""
      );
      formdata.append(
        "coupon_sub_category_ids",
        coupon_sub_category_ids?.length > 0
          ? coupon_sub_category_ids?.map((it) => it?.id)?.toString()
          : ""
      );
      formdata.append(
        "coupon_product_ids",
        coupon_product_ids?.length > 0
          ? coupon_product_ids?.map((it) => it?.id)?.toString()
          : ""
      );
      formdata.append("is_new_user", is_new_user);
      formdata.append("is_specific_user", is_specific_user);
      formdata.append("mobile_number", mobile_number);
      formdata.append("from_date", from_date);
      formdata.append("to_date", to_date);
      formdata.append("payment_mode", payment_mode);
      formdata.append("fixed_amount", fixed_amount);
      formdata.append("max_discount", max_discount);
      formdata.append("max_order_value", max_order_value);
      formdata.append("order_amount", order_amount);
      formdata.append("buy_one", buy_one);
      formdata.append("get_one", get_one);
      formdata.append("image", image);

      dispatch(addCoupon(formdata, { dispatch })).then((data) => {
        if (data?.payload?.response_code === 200 && data?.payload?.success) {
          history.push("/admin/manage-coupons");
          toast.success("Success");
        } else {
          toast.error(data?.payload?.message);
        }
      });
    },
  });

  const dataHandler = (data) => {
    setFieldValue("image", data?.location);
    setImage({
      ...image,
      image: data.location,
    });
  };

  const handleNumericValidation = (e) => {
    if (e.target.name === "discount" && e.target.value?.toString() >= "99") {
      setFieldError({ discount: "*Number must be less than 100." });
    }
    e.target.value = e.target.value.replace(/[^0-9.]/g, "");
    e.target.value = e.target.value.replace(/(\..*)\./g, "$1");
  };

  useEffect(() => {
    if (values?.coupon_category_ids?.length > 0)
      dispatch(
        subCategoryList({
          page: "1",
          category:
            values?.coupon_category_ids?.length > 0
              ? values?.coupon_category_ids?.map((it) => it?.id)?.toString()
              : "",
        })
      );
    // .then((data) => {
    //   values.coupon_sub_category_ids = [];
    // });
  }, [dispatch, values]);

  const getProductListing = useCallback(() => {
    if (
      values?.coupon_category_ids?.length > 0 ||
      values.coupon_sub_category_ids
    )
      dispatch(
        productList({
          page: 1,
          limit: 10,
          active: 1,
          search: "",
          category:
            values?.coupon_category_ids?.length > 0
              ? values?.coupon_category_ids?.map((it) => it?.id)?.toString()
              : "",
          subCategory:
            values?.coupon_sub_category_ids?.length > 0
              ? values?.coupon_sub_category_ids?.map((it) => it?.id)?.toString()
              : "",
        })
      );
  }, [dispatch, values?.coupon_category_ids, values?.coupon_sub_category_ids]);

  useEffect(() => {
    getProductListing();
  }, [getProductListing]);

  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <h2 class="head-title">Add New Coupon</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <form onSubmit={handleSubmit} class="form-style mt-5">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                  <Upload dataHandler={dataHandler} />
                  <span className="errorMessage mb-2">
                    {touched["image"] && errors["image"]}
                  </span>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6">
                  <FormInputText
                    placeholder="Enter Coupon Name"
                    type="text"
                    image="product.png"
                    onChange={handleChange}
                    name="name"
                    value={values.name}
                    title="Coupon Name"
                  />

                  <span className="errorMessage">
                    {touched["name"] && errors["name"]}
                  </span>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                  <FormInputText
                    placeholder="Enter Coupon Code"
                    type="text"
                    image="product.png"
                    onChange={(e) => {
                      setFieldValue("code", e.target.value?.toUpperCase());
                    }}
                    name="code"
                    value={values.code?.toUpperCase()}
                    title="Coupon Code"
                  />

                  <span className="errorMessage">
                    {touched["code"] && errors["code"]}
                  </span>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Coupon Type</label>
                    <div class="input-container">
                      <select
                        onChange={(e) => {
                          const oldDiscountType = values?.discount_type;
                          setFieldValue("discount_type", e.target.value);

                          switch (oldDiscountType) {
                            case "percentage":
                              setFieldTouched({
                                discount: false,
                                max_discount: false,
                              });
                              setFieldError({ discount: "", max_discount: "" });
                              break;
                            case "fixed":
                              setFieldTouched({
                                fixed_amount: false,
                                max_order_value: false,
                              });
                              setFieldError({
                                fixed_amount: "",
                                max_order_value: "",
                              });
                              break;
                            case "bogo":
                              setFieldTouched({
                                buy_one: false,
                                get_one: false,
                              });
                              setFieldError({ buy_one: "", get_one: "" });
                              break;
                            case "freeShipping":
                              setFieldTouched({
                                order_amount: false,
                              });
                              setFieldError({ order_amount: "" });
                              break;
                            default:
                              return;
                          }
                        }}
                        name="discount_type"
                        value={values.state}
                        class="form-control pl-5"
                      >
                        <option value="">Select Coupon Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="fixed">Fixed</option>
                        <option value="bogo">BOGO</option>
                        <option value="freeShipping">Free Shipping</option>
                      </select>
                      <img
                        src="assets/images/small-cate.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>

                  <span className="errorMessage">
                    {touched["discount_type"] && errors["discount_type"]}
                  </span>
                </div>
                {values?.discount_type === "percentage" && (
                  <>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                      <FormInputText
                        placeholder="Discount (%)"
                        type="text"
                        image="product.png"
                        onChange={handleChange}
                        name="discount"
                        value={values.discount}
                        title="Discount (%)"
                        onInput={(e) => {
                          if (e.target.value.length === 3) return false;
                          handleNumericValidation(e);
                        }}
                      />

                      <span className="errorMessage">
                        {touched["discount"] && errors["discount"]}
                      </span>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                      <FormInputText
                        placeholder="Max Discount Amount"
                        type="text"
                        image="product.png"
                        onInput={(e) => {
                          handleNumericValidation(e);
                        }}
                        onChange={handleChange}
                        name="max_discount"
                        value={values.max_discount}
                        title="Max Discount Amount"
                      />

                      <span className="errorMessage">
                        {touched["max_discount"] && errors["max_discount"]}
                      </span>
                    </div>
                  </>
                )}
                {values?.discount_type === "fixed" && (
                  <>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                      <FormInputText
                        placeholder="Fixed Amount"
                        type="text"
                        image="product.png"
                        onChange={handleChange}
                        name="fixed_amount"
                        value={values.fixed_amount}
                        title="Fixed Amount"
                        onInput={(e) => {
                          handleNumericValidation(e);
                        }}
                      />

                      <span className="errorMessage">
                        {touched["fixed_amount"] && errors["fixed_amount"]}
                      </span>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-6">
                      <FormInputText
                        placeholder="Max Order Value"
                        type="text"
                        image="product.png"
                        onChange={handleChange}
                        name="max_order_value"
                        value={values.max_order_value}
                        title="Max Order Value"
                        onInput={(e) => {
                          handleNumericValidation(e);
                        }}
                      />

                      <span className="errorMessage">
                        {touched["max_order_value"] &&
                          errors["max_order_value"]}
                      </span>
                    </div>
                  </>
                )}
                {values?.discount_type === "bogo" && (
                  <>
                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <FormInputText
                        placeholder="Buy Quantity"
                        type="text"
                        image="product.png"
                        onChange={handleChange}
                        name="buy_one"
                        value={values.buy_one}
                        title="Buy Quantity"
                        onInput={(e) => {
                          handleNumericValidation(e);
                        }}
                      />

                      <span className="errorMessage">
                        {touched["buy_one"] && errors["buy_one"]}
                      </span>
                    </div>{" "}
                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <FormInputText
                        placeholder="Get Quantity"
                        type="text"
                        image="product.png"
                        onChange={handleChange}
                        name="get_one"
                        value={values.get_one}
                        title="Get Quantity"
                        onInput={(e) => {
                          handleNumericValidation(e);
                        }}
                      />

                      <span className="errorMessage">
                        {touched["get_one"] && errors["get_one"]}
                      </span>
                    </div>
                  </>
                )}

                {values?.discount_type === "freeShipping" && (
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <FormInputText
                      placeholder="Order Amount"
                      type="text"
                      image="product.png"
                      onChange={handleChange}
                      name="order_amount"
                      value={values.order_amount}
                      title="Order Amount"
                      onInput={(e) => {
                        handleNumericValidation(e);
                      }}
                    />

                    <span className="errorMessage">
                      {touched["order_amount"] && errors["order_amount"]}
                    </span>
                  </div>
                )}
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label>Description</label>
                    <div class="input-container">
                      <textarea
                        onChange={handleChange}
                        value={values.description}
                        name="description"
                        class="textarea-control"
                        rows="7"
                      ></textarea>
                    </div>
                  </div>
                  <span className="errorMessage">
                    {touched["description"] && errors["description"]}
                  </span>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label>Coupon Category Ids</label>
                    <div class="input-container">
                      <Select
                        closeMenuOnSelect={false}
                        onChange={(val) => {
                          setFieldValue("coupon_category_ids", val);
                        }}
                        styles={reactSelectMultipleOptionStyle}
                        isMulti={true}
                        placeholder="Select Categories"
                        getOptionLabel={(option) => option?.name}
                        getOptionValue={(option) => option?.id}
                        options={
                          categoryListing || []
                          //   data &&
                          //   data.map((data) => {
                          //     return {
                          //       value: data.id,
                          //       label: data.name,
                          //     };
                          //   })
                        }
                      />

                      <img
                        src="assets/images/small-cate.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  <span className="errorMessage">
                    {touched["coupon_category_ids"] &&
                      errors["coupon_category_ids"]}
                  </span>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label>Coupon Sub Category Ids</label>
                    <div class="input-container">
                      <Select
                        closeMenuOnSelect={false}
                        onChange={(val) => {
                          setFieldValue("coupon_sub_category_ids", val);
                        }}
                        styles={reactSelectMultipleOptionStyle}
                        isMulti={true}
                        placeholder="Select Sub Categories"
                        getOptionLabel={(option) => option?.name}
                        getOptionValue={(option) => option?.id}
                        options={
                          subCategoryData || []
                          //   data &&
                          //   data.map((data) => {
                          //     return {
                          //       value: data.id,
                          //       label: data.name,
                          //     };
                          //   })
                        }
                      />

                      <img
                        src="assets/images/small-cate.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  <span className="errorMessage">
                    {touched["coupon_sub_category_ids"] &&
                      errors["coupon_sub_category_ids"]}
                  </span>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label>Product Ids</label>
                    <div class="input-container">
                      <Select
                        closeMenuOnSelect={false}
                        onChange={(val) => {
                          setFieldValue("coupon_product_ids", val);
                        }}
                        styles={reactSelectMultipleOptionStyle}
                        isMulti={true}
                        placeholder="Select Products"
                        getOptionLabel={(option) => option?.name}
                        getOptionValue={(option) => option?.id}
                        options={
                          productListings || []
                          //   data &&
                          //   data.map((data) => {
                          //     return {
                          //       value: data.id,
                          //       label: data.name,
                          //     };
                          //   })
                        }
                      />

                      <img
                        src="assets/images/small-cate.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  <span className="errorMessage">
                    {touched["coupon_product_ids"] &&
                      errors["coupon_product_ids"]}
                  </span>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>User Type</label>
                    <div class="input-container">
                      <select
                        onChange={(e) => {
                          let { value } = e.target;

                          setFieldValue("user_type", value);

                          if (value === "is_new_user") {
                            setFieldValue("is_new_user", 1);
                            setFieldValue("is_specific_user", 0);
                          } else if (value === "is_specific_user") {
                            setFieldValue("is_new_user", 0);
                            setFieldValue("is_specific_user", 1);
                          } else {
                            setFieldValue("is_new_user", 0);
                            setFieldValue("is_specific_user", 0);
                          }
                        }}
                        name="user_type"
                        value={values.state}
                        class="form-control pl-5"
                      >
                        <option value="">Select Coupon Type</option>
                        <option value="is_new_user">Is New User</option>
                        <option value="is_specific_user">
                          Is Specific User
                        </option>
                      </select>
                      <img
                        src="assets/images/small-cate.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>

                  <span className="errorMessage">
                    {touched["user_type"] && errors["user_type"]}
                  </span>
                </div>
                {Boolean(values.is_specific_user) && (
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label>User Type</label>
                      <div class="input-container">
                        <PhoneInput
                          country="in"
                          inputProps={{
                            name: "mobile_number",
                          }}
                          enableSearch
                          disableCountryCode
                          value={values.mobile_number}
                          onChange={(phone, country, e, formattedValue) => {
                            if (phone.length > 0) {
                              setFieldValue("mobile_number", phone);
                            } else {
                              setFieldValue("mobile_number", "");
                            }
                          }}
                          // onBlur={handleBlur}
                          placeholder="Enter Mobile Number"
                          containerClass="form-control"
                        />
                      </div>
                    </div>

                    <span className="errorMessage">
                      {touched["mobile_number"] && errors["mobile_number"]}
                    </span>
                  </div>
                )}
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <FormInputText
                    placeholder="Enter Price"
                    type="date"
                    image="product.png"
                    onChange={handleChange}
                    name="from_date"
                    value={values.from_date}
                    title="From"
                  />

                  <span className="errorMessage">
                    {touched["from_date"] && errors["from_date"]}
                  </span>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <FormInputText
                    placeholder="date"
                    type="date"
                    image="small-cate.png"
                    min={values.from_date}
                    onChange={handleChange}
                    name="to_date"
                    value={values.to_date}
                    title="To"
                  />

                  <span className="errorMessage">
                    {touched["to_date"] && errors["to_date"]}
                  </span>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Payment Mode</label>
                    <div class="input-container">
                      <select
                        onChange={handleChange}
                        name="payment_mode"
                        value={values.state}
                        class="form-control pl-5"
                      >
                        <option value="">Select Payment Mode</option>
                        <option value="1">Online</option>
                        <option value="2">COD</option>
                        <option value="3">Both</option>
                      </select>
                      <img
                        src="assets/images/small-cate.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>

                  <span className="errorMessage">
                    {touched["payment_mode"] && errors["payment_mode"]}
                  </span>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 mt-5">
                  <div class="text-center">
                    <button type="submit" class="btn btn-save">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </article>
  );
};

export default AddCoupon;
