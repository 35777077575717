import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import FormInputPassword from '../../../components/FormInputPassword';
import { putEncodedData } from '../../../services/httphandler';

const ChangePassword = () => {
    const history = useHistory();
    const validationSchema = Yup.object().shape({
        old_password: Yup.string().min(8, '*Min is 8').required('*Current Password is Required.'),
        new_password: Yup.string().min(8, '*Min is 8').required('*New Password is Required.'),
        confirmNewPassword: Yup.string()
            .oneOf([Yup.ref('new_password'), null], '*New Password & Confirm New Password must match.').required('*Confirm New Password Required.')
    });

    const {
        values,
        handleSubmit,
        touched,
        errors,
        handleChange,
    } = useFormik({
        initialValues: {
            old_password: "",
            new_password: "",
            confirmNewPassword: "",
        },
        validationSchema,
        onSubmit: async ({ old_password, new_password }) => {
            // const { old_password, new_password } = values;
            let response = await putEncodedData('changePassword', { old_password, new_password });
            console.log(response, 'response change password')
            if (response && response.response_code === 200) {
                toast.success(response.message);
                history.push('/admin/')
            }
            else {
                toast.error('Something went wrong.')
            }

        },
    });



    return (
        <article class="col-lg-9">
            <div class="article-body store-bd-gy">
                <div class="row align-items-center col-rever">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <h2 class="head-title">Change Password</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <form onSubmit={handleSubmit} class="form-style chng-pass">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 text-center mb-3">
                                    <img src="assets/images/reset.png" class="img-fluid" alt="" />
                                    <p>Please Change Password your password</p>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">

                                    <FormInputPassword
                                        placeholder="Enter Current Password"
                                        image="password.png"
                                        onChange={handleChange}
                                        name="old_password"
                                        type="text"
                                        value={values.old_password}
                                        title="Current Password"
                                    />


                                    <span className="errorMessage">
                                        {touched["old_password"] && errors["old_password"]}
                                    </span>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                    <FormInputPassword
                                        placeholder="Enter New Password"
                                        image="password.png"
                                        onChange={handleChange}
                                        name="new_password"
                                        type="text"
                                        value={values.new_password}
                                        title="New Password"
                                    />


                                    <span className="errorMessage">
                                        {touched["new_password"] && errors["new_password"]}
                                    </span>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">

                                    <FormInputPassword
                                        placeholder="Enter Confirm New Password"
                                        image="password.png"
                                        onChange={handleChange}
                                        name="confirmNewPassword"
                                        type="text"
                                        value={values.confirmNewPassword}
                                        title="Confirm Password"
                                    />


                                    <span className="errorMessage">
                                        {touched["confirmNewPassword"] && errors["confirmNewPassword"]}
                                    </span>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="text-center">
                                        <button type="submit" class="btn btn-save">Done</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </article>
    )
}

export default ChangePassword;