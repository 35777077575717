import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteData,
  getData,
  postFormDataWithToken,
  putEncodedData,
} from "../../../services/httphandler";

const initialState = {
  list: [],
};

export const updateOtherOrderStatus = createAsyncThunk(
  "updateProduct",
  async (object, { dispatch }) => {
    let response = await putEncodedData("otherOrder/updateStatus", {
      id: object?.id,
    });
    if (response && response.response_code === 200) {
      dispatch(otherOrderList(object?.filter));
      return response;
    }
  }
);

export const otherOrderDetails = createAsyncThunk(
  "otherOrderDetails/id",
  async (object, { dispatch }) => {
    let response = await getData("otherOrder/detail", object);
    if (response && response.response_code === 200) {
      // dispatch(productList());
      return response.data;
    }
  }
);

export const otherOrderLists = (state) => state.otherOrder.list;

export const otherOrderList = createAsyncThunk(
  "otherOrderList",
  async (object, { dispatch }) => {
    let response = await getData("otherOrder/get", object);
    console.log("response: ", response);

    if (response && response.response_code === 200) {
      return response.data;
    }
  }
);

const otherOrderSlice = createSlice({
  name: "otherOrderSlice",
  initialState,

  extraReducers: {
    [otherOrderList.fulfilled]: (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
    },
  },
});
// export const {} = otherOrderSlice.actions;

export default otherOrderSlice.reducer;
