import { Modal } from "reactstrap";
import React, { useState } from 'react';
import { toast } from "react-toastify";
import ReactStars from "react-rating-stars-component";
import Upload from "../uploadFile.js";


const ReviewModal = ({ handleUpload }) => {
    const [modal, setModal] = React.useState(false);
    const toggle = () => setModal(!modal);

    const [review, setreview] = useState({
        rating: '',
        review_message: '',
        image: '',
        name: ''
    });


    const ratingChanged = (newRating) => {
        setreview({
            ...review,
            rating: newRating
        })
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setreview({
            ...review,
            [name]: value
        })
    }


    const dataHandler = (data) => {
        setreview({
            ...review,
            image: data.location
        })
    }

    const handleSubmit = () => {
        const { rating, review_message, name, image } = review;
        if (rating && review_message && name && image) {
            handleUpload(review);
            toggle();
        }
        else {
            toast.error('*Please fill the form first.')
        }
    }
    return (
        <>
            <button
                style={{ fontSize: '14px', border: 'none' }}
                onClick={toggle}
                type="button"
                className="add-new">Add Review</button>
            <Modal className="modal-md" isOpen={modal} toggle={toggle}>
                <div class="modal-content">
                    <div class="modal-body text-capitalize">
                        <button onClick={toggle} type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="reviews-rating-form">
                            <div class="col-sm-12 col-md-12 col-lg-12 text-center mt-4">

                                <Upload dataHandler={dataHandler} />

                            </div>
                            <div className="rating-star">
                                <p>Click on star to review</p>
                                <ul id="stars" className="stars">
                                    <ReactStars
                                        count={5}
                                        onChange={ratingChanged}
                                        size={24}
                                        value={review.rating}
                                        edit={true}
                                        activeColor="#ffd700"
                                    />
                                </ul>
                            </div>
                            <div className="form mt-2 mb-3">
                                <label>Name</label>
                                <input
                                    type="text"
                                    placeholder="Enter Name"
                                    className="form-control"
                                    value={review.name}
                                    onChange={(e) => {
                                        setreview({
                                            ...review,
                                            name: e.target.value
                                        })
                                    }}

                                />
                            </div>

                            <div className="rating-form">
                                <form action="#">
                                    <div className="single-form form-default">
                                        <label>Write your Review</label>
                                        <div className="form-input">
                                            <textarea
                                                placeholder="Your review here"
                                                name="review_message"
                                                onChange={handleChange}
                                                value={review.review_message}
                                            ></textarea>
                                            <i className="fa fa-message-text-outline"></i>
                                        </div>
                                    </div>
                                    <div className="single-rating-form flex-wrap">
                                        <div className="rating-form-btn">
                                            <button
                                                type="button"
                                                onClick={handleSubmit}
                                                className="add-new">Write a Review</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ReviewModal;