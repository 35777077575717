import { Link, useHistory } from "react-router-dom";
import SubCategoryViewModal from "../../../components/subCategoryViewModal";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  deleteSubCategory,
  subCategoryList,
  toggleSubCategory,
} from "./subCategorySlice";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import DeleteModal from "../../../components/deleteModal";
import { toast } from "react-toastify";
import { categoryList } from "./categorySlice";

const SubCategoryList = () => {
  const dispatch = useDispatch();
  const History = useHistory();
  const [modal, setModal] = useState(false);
  const [id, setDeleteId] = useState("");
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    search: "",
    category: "",
    active: 1,
  });
  const catList = useSelector((state) => state.category.categoryList.data);
  const data = useSelector((state) => state.subCategory.subCategoryList.data);
  const total = useSelector((state) => state.subCategory.subCategoryList.total);

  useEffect(() => {
    dispatch(categoryList({ page: "1", limit: "1000", search: "" }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(subCategoryList(filters));
  }, [dispatch, filters]);

  const handlePageChange = (pageNumber) => {
    setFilters({
      ...filters,
      page: pageNumber,
    });
  };

  const handleChange = (e) => {
    setFilters({
      ...filters,
      search: e.target.value,
    });
  };

  const handleCategoryChange = (e) => {
    setFilters({
      ...filters,
      category: e.target.value,
    });
  };

  const toggle = () => {
    setModal(!modal);
  };

  const deleteCat = () => {
    dispatch(deleteSubCategory({ id })).then((data) => {
      if (data?.payload?.response_code === 200) {
        toggle();
        toast.success("Success");
      } else {
        toast.error(data?.payload?.message);
      }
    });
  };
  const handleToggle = (id) => {
    dispatch(
      toggleSubCategory(
        {
          id,
        },
        { dispatch }
      )
    ).then((data) => {
      if (data && data.payload.response_code === 200) {
        dispatch(subCategoryList(filters));
        toast.success(data.payload.message);
      } else {
        toast.error(data.payload.message);
      }
    });
  };

  const toggleTab = (val) => {
    setFilters({
      ...filters,
      active: val,
      page: 1,
    });
  };

  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="link-active">
              <img src="assets/images/Categories-selected.png" alt="" />
              <span>
                <p>{total}</p>
                <p>Total Sub-Categories</p>
              </span>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="d-flex align-items-center lg-justify-content-end">
              <form class="has-search">
                <span class="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search Categories by name"
                  onChange={handleChange}
                />
              </form>
              <Link to="/admin/sub-category/add" class="add-new">
                + Add New
              </Link>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-6 mb-3">
            <ul class="nav nav-tabs group-nav" role="tablist">
              <li class="nav-item">
                <Link
                  className={`nav-link ` + (filters.active ? "active" : "")}
                  onClick={() => toggleTab(1)}
                  data-toggle="tab"
                  to="#"
                >
                  active
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  className={`nav-link ` + (filters.active ? "" : "active")}
                  onClick={() => toggleTab(0)}
                  data-toggle="tab"
                  to="#"
                >
                  inactive
                </Link>
              </li>
            </ul>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6 mb-3">
            <div class="d-flex align-items-center lg-justify-content-end">
              <span>Filter</span>
              <span class="ml-3">
                <select
                  onChange={handleCategoryChange}
                  class="form-control h-auto"
                >
                  <option value={""}>Select Category</option>
                  {catList &&
                    catList.map((data) => {
                      return <option value={data.id}>{data.name}</option>;
                    })}
                </select>
              </span>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="table-responsive text-center">
              <table class="table storetable">
                <thead class="text-center">
                  <tr>
                    <th scope="col">sub-category image</th>
                    <th scope="col">sub-category Name</th>
                    <th scope="col">rank</th>
                    <th scope="col">category</th>
                    <th scope="col">action</th>
                  </tr>
                  <tr class="border-0 bg-none invisible">
                    <td class="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((data, i) => {
                      return (
                        <tr key={data?.id}>
                          <td>
                            <div
                              style={{
                                height: "80px",
                                width: "80px",
                                margin: "0 auto",
                                borderRadius: "10px",
                                overflow: "hidden",
                              }}
                            >
                              <img
                                src={data.image || "assets/images/employe.png"}
                                class="object-fit-cover"
                                alt=""
                              />
                            </div>
                          </td>
                          <td>{data.name}</td>
                          <td>{data.rank}</td>
                          <td>{data.category.name}</td>
                          <td>
                            <div class="d-flex justify-content-center action">
                              <SubCategoryViewModal id={data.id} />
                              <button
                                onClick={() =>
                                  History.push(
                                    `/admin/sub-category/edit/${data.id}`
                                  )
                                }
                                class="btn"
                              >
                                <img src="assets/images/edit.png" alt="" />
                              </button>
                              <button class="btn">
                                <input
                                  checked={data?.active}
                                  onChange={() => handleToggle(data.id)}
                                  type="checkbox"
                                  id={data.id}
                                  class="toggle"
                                  alt=""
                                />
                              </button>
                              <button
                                onClick={() => {
                                  setDeleteId(data.id);
                                  toggle();
                                }}
                                class="btn"
                              >
                                <img src="assets/images/delete.png" alt="" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5}>No Data Found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {total && total > 10 ? (
          <div>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.limit}
              totalItemsCount={total}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </div>
        ) : (
          ""
        )}

        <DeleteModal deleteCategory={deleteCat} modal={modal} toggle={toggle} />
      </div>
    </article>
  );
};

export default SubCategoryList;
