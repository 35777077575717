import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { cartList, getCartListing } from "./CartListingSlice";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";

const CartListing = () => {
  const dispatch = useDispatch();
  const data = useSelector(cartList);
  const total = data?.total;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [open, toggle] = useState(false);
  const limit = 10;

  const getList = useCallback(() => {
    dispatch(getCartListing({ page: page, limit, search: search }));
  }, [page, search, dispatch]);

  useEffect(() => {
    getList();
  }, [getList]);

  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-6 col-md-6 col-lg-7">
            <div class="link-active">
              <img alt="" src="assets/images/user-big.png" />
              <span>
                <p>{total && total}</p>
                <p>Total Cart Items</p>
              </span>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-5">
            <div class="d-flex align-items-center lg-justify-content-end">
              <form class="has-search">
                <span class="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search Customer by name"
                  onChange={(e) => {
                    e.preventDefault();
                    setSearch(e.target.value);
                  }}
                />
              </form>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive text-center">
              <table class="table storetable">
                <thead class="text-center">
                  <tr>
                    <th scope="col">iD</th>
                    <th scope="col">profile</th>
                    <th scope="col">full Name</th>
                    <th scope="col">mobile number </th>
                    <th scope="col">email address</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Total Quantity</th>
                    {/* <th scope="col">Product iD</th>
                    <th scope="col">Product Price</th> */}

                    <th scope="col">action</th>
                  </tr>
                  <tr class="border-0 bg-none invisible">
                    <td class="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {data && data?.data?.length > 0 ? (
                    data?.data?.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td>{data?.bog_id}</td>
                          <td>
                            <div
                              style={{
                                height: "80px",
                                width: "80px",
                                margin: "0 auto",
                                borderRadius: "10px",
                                overflow: "hidden",
                              }}
                            >
                              <img
                                alt=""
                                src={
                                  data?.image?.includes("http")
                                    ? data?.image
                                    : "assets/images/employe.png"
                                }
                                class="object-fit-cover"
                              />
                            </div>
                          </td>
                          <td>
                            {(data?.first_name && data?.first_name + " ") +
                              data?.last_name || "N/A"}
                          </td>
                          <td>
                            {(data?.country_code && data?.country_code + " ") +
                              data?.mobile_number || "N/A"}
                          </td>
                          <td>{data?.email || "N/A"}</td>
                          <td
                            className="w-50"
                            style={{
                              whiteSpace: "normal",
                            }}
                          >
                            {data.products
                              .map((it) => it?.product?.name)
                              ?.join(", ") || "N/A"}
                          </td>
                          {/* <td>{data.product.bog_product_id || "N/A"}</td> */}
                          <td>{data.total_quantity || "N/A"}</td>
                          <td>
                            <div class="d-flex justify-content-center action">
                              <button class="btn">
                                <Link to={`/admin/manage-cart/view/${data.id}`}>
                                  <img alt="" src="assets/images/view.png" />
                                </Link>
                              </button>
                              {/* <button
                                onClick={() => {
                                  setDeleteId(data.id);
                                  toggle();
                                }}
                                class="btn"
                              >
                                <img src="assets/images/delete.png" alt="" />
                              </button> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={8}>No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {total && total > 10 ? (
          <div>
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={total}
              pageRangeDisplayed={5}
              onChange={(val) => {
                setPage(val);
              }}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      {/* <DeleteModal deleteCategory={deleteCat} modal={modal} toggle={toggle} /> */}
    </article>
  );
};

export default CartListing;
