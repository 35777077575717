import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import FormInputText from "../../../components/FormInputText";
import { categoryDetails, updateCategory } from "./categorySlice";
import Upload from "../../../components/uploadFile.js";
import { toast } from "react-toastify";
import Select from "react-select";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import S3 from "react-aws-s3";
import { v4 as uuidv4 } from "uuid";
import { useMemo } from "react";
import { useRef } from "react";
import ImageResize from "quill-image-resize-module-react";

Quill.register("modules/imageResize", ImageResize);

var Size = Quill.import("attributors/style/size");
Size.whitelist = [
  "10px",
  "11px",
  "12px",
  "13px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
];
Quill.register(Size, true);

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  // [{ script: "sub" }, { script: "super" }], // superscript/subscript
  // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction
  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ["clean"], // remove formatting button
];

const reactSelectMultipleOptionStyle = {
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: 0,
  }),
};

const paymentModeOptions = [
  {
    label: "Online Pyament",
    value: 1,
  },
  {
    label: "COD",
    value: 2,
  },
];

const EditCategory = () => {
  const [state, setstate] = useState({
    id: "",
    image: "",
    category_name: "",
    description: "",
    rank: "",
    payment_method: [],
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const validationSchema = Yup.object().shape({
    category_name: Yup.string().required("*Category Name is Required"),
    description: Yup.string().required("*Description is Required"),
    rank: Yup.number()
      .positive("Kindly enter a positive number")
      ?.integer("Kindly enter a valid number")
      ?.required("*Rank is Required"),
  });

  const { values, handleSubmit, touched, errors, handleChange, setFieldValue } =
    useFormik({
      initialValues: state,
      enableReinitialize: true,
      validationSchema,
      onSubmit: async () => {
        if (state && state.image) {
          dispatch(
            updateCategory(
              {
                ...values,
                payment_method: values.payment_method
                  ?.map((it) => it?.value)
                  ?.join(","),
              },
              { dispatch }
            )
          ).then((data) => {
            if (data?.payload?.response_code === 200) {
              history.push("/admin/category");
              toast.success("Success");
            } else {
              toast.error(data?.payload?.message);
            }
          });
        } else {
          toast.error("* Please add image.");
        }
      },
    });

  useEffect(() => {
    dispatch(categoryDetails({ id })).then((data) => {
      setstate({
        id: data.payload.category.id,
        image: data.payload.category.image,
        category_name: data.payload.category.name,
        description: data.payload.category?.description,
        rank: data.payload.category?.rank,
        payment_method: data?.payload?.category?.category_payment_method?.map(
          (it) => {
            return {
              label:
                it?.payment_mode === 1
                  ? "Online Payment"
                  : it?.payment_mode === 2
                  ? "COD"
                  : "",
              value: it?.payment_mode,
            };
          }
        ),
      });
    });
  }, [dispatch, id]);

  const dataHandler = (data) => {
    setstate({
      ...state,
      image: data.location,
    });
  };

  const dataDescre = (data) => {
    setFieldValue("description", data);
    setstate({
      ...state,
      description: data,
    });
  };

  const handlePaymentChange = (val) => {
    setFieldValue("payment_method", val);
    setstate({ ...state, payment_method: val });
  };

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    s3Url: `https://${process.env.REACT_APP_BUCKET_NAME}.s3.amazonaws.com` /* without the suffix zone added */,
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      console.log(percentCompleted);
    },
  };

  const quillRef = useRef();

  const QID = uuidv4();

  const ReactS3Client = new S3(config);

  const newFileName = QID;

  const ImageUpload = (formData) => {
    var url = ReactS3Client.uploadFile(formData, newFileName).then((data) => {
      return data.location;
    });
    return url;
  };

  const imageHandler = (e) => {
    const editor = quillRef.current.getEditor();
    // console.log(editor);
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        // console.log(file);

        const res = await ImageUpload(input.files[0]); // upload data into server or aws or cloudinary

        // console.log(res, "res");
        editor.insertEmbed(editor.getSelection(), "image", res);
      }
    };
  };

  const [showHtml, setShowHtml] = useState(false);

  const handleHtml = () => {
    setShowHtml(!showHtml);
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],

          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["image", "link"],
          // ["blockquote", "code-block"],
          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ size: Size.whitelist }],
          [{ align: [] }],
        ],
        imageResize: {
          displaySize: true,
        },

        handlers: {
          image: imageHandler,
        },
      },
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
      },
    }),
    []
  );

  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <h2 class="head-title">Edit Category</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <form onSubmit={handleSubmit} class="form-style">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                  <Upload dataHandler={dataHandler} imager={state.image} />
                </div>
                <div class="col-sm-12 col-md-2 col-lg-2"></div>
                <div class="col-sm-12 col-md-8 col-lg-8">
                  <FormInputText
                    placeholder="Enter Category Name"
                    image="small-cate.png"
                    onChange={handleChange}
                    name="category_name"
                    type="text"
                    value={values.category_name}
                    title="Category Name"
                  />

                  <span className="errorMessage">
                    {touched["category_name"] && errors["category_name"]}
                  </span>
                </div>
                <div class="col-sm-12 col-md-2 col-lg-2"></div>
                <div class="col-sm-12 col-md-2 col-lg-2"></div>
                <div class="col-sm-12 col-md-8 col-lg-8">
                  <div class="form-group">
                    <label>Description</label>
                    <div class="input-container">
                      <ReactQuill
                        theme="snow"
                        value={values.description}
                        name="description"
                        onChange={(content, delta, source, editor) => {
                          dataDescre(content);
                        }}
                        ref={quillRef}
                        modules={modules}
                        style={{
                          minHeight: "30vh",
                        }}
                      />
                      {/* <textarea
                        onChange={handleChange}
                        value={values.description}
                        name="description"
                        class="textarea-control"
                        rows="7"
                      ></textarea> */}
                    </div>
                  </div>
                  <span className="errorMessage">
                    {touched["description"] && errors["description"]}
                  </span>
                </div>
                <div class="col-sm-12 col-md-2 col-lg-2"></div>
                <div class="col-sm-12 col-md-2 col-lg-2"></div>
                <div class="col-sm-12 col-md-8 col-lg-8">
                  <FormInputText
                    placeholder="Rank"
                    image="small-cate.png"
                    onChange={handleChange}
                    name="rank"
                    type="number"
                    value={values.rank}
                    title="Rank"
                  />

                  <span className="errorMessage">
                    {touched["rank"] && errors["rank"]}
                  </span>
                </div>
                <div class="col-sm-12 col-md-2 col-lg-2"></div>
                <div class="col-sm-12 col-md-2 col-lg-2"></div>
                <div class="col-sm-12 col-md-8 col-lg-8">
                  <div class="form-group">
                    <label>Accepted Payment Mode</label>
                    <div class="input-container">
                      <Select
                        onChange={handlePaymentChange}
                        isMulti={true}
                        placeholder="Select Accepted Payment Mode"
                        options={paymentModeOptions}
                        styles={reactSelectMultipleOptionStyle}
                        value={state?.payment_method}
                      />

                      <img
                        src="assets/images/small-cate.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <button type="submit" class="btn btn-save">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </article>
  );
};

export default EditCategory;
