import { useDispatch } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import DeleteModal from "../../../components/deleteModal";
import { toast } from "react-toastify";
import {
  deleteProduct,
  productList,
  storeFilters,
  toggleProduct,
  updateProduct,
} from "./productSlice";
import { categoryList } from "../ManageCategory/categorySlice";
import DateFilterModal from "../../../components/dateFilter";
import { subCategoryList } from "../ManageCategory/subCategorySlice";
import LightBoxImage from "../../../components/lightbox";
import { debounce } from "lodash";
const ProductList = () => {
  const dispatch = useDispatch();
  const History = useHistory();
  const [state, setstate] = useState([]);

  const [modal, setModal] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [id, setDeleteId] = useState("");
  const filter = useSelector((state) => state.product.filters);
  console.log(filter, "Reduzx filter");
  const [filters, setFilters] = useState(filter);
  const [isPriceClicked, setIsPriceClicked] = useState(false);
  const [isUnitsClicked, setIsUnitsClicked] = useState(false);
  const [unit, setUnit] = useState();
  const [price, setPrice] = useState();
  const [priceIndex, setPriceIndex] = useState([]);
  const [unitIndex, setUnitIndex] = useState([]);

  const data = useSelector((state) => state.product?.productList?.data);
  const total = useSelector((state) => state.product?.productList?.total);
  const catList = useSelector((state) => state.category?.categoryList?.data);
  const subCatList = useSelector(
    (state) => state.subCategory?.subCategoryList?.data
  );

  useEffect(() => {
    if (filter) {
      setFilters(filter);
    }
  }, [filter]);

  useEffect(() => {
    dispatch(categoryList({ page: "1", limit: "1000", search: "" }));
  }, [dispatch]);

  useEffect(() => {
    if (filters.category) {
      dispatch(
        subCategoryList({
          page: "1",
          limit: "1000",
          search: "",
          category: filters?.category,
        })
      );
    }
  }, [dispatch, filters]);
  // const debounce = (func) => {
  //   let timer;
  //   return function (...args) {
  //     const context = this;
  //     if (timer) clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       timer = null;
  //       func.apply(context, args);
  //     }, 1000);
  //   };
  // };
  const getProductListing = useCallback(() => {
    dispatch(productList(filters));
  }, [dispatch, filters]);

  useEffect(() => {
    getProductListing();
  }, [getProductListing]);

  //  dispatch(
  //         updateProduct({

  const handlePageChange = (pageNumber) => {
    setFilters({
      ...filters,
      page: pageNumber,
    });
  };

  const handleSearch = debounce((e) => {
    setFilters({
      ...filters,
      search: e.target.value,
    });
  }, 300);

  const handleChange = ({ name, e }) => {
    (async () => {
      await localStorage.setItem(
        "filters",
        JSON.stringify({
          ...filters,
          [name]: e.target.value,
        })
      );
    })();

    dispatch(
      storeFilters({
        ...filters,
        [name]: e.target.value,
      })
    );
    setFilters({
      ...filters,
      [name]: e.target.value,
    });
  };

  const toggle = () => {
    setModal(!modal);
  };

  const deleteCat = () => {
    dispatch(deleteProduct({ filters, id })).then((data) => {
      if (data?.payload?.response_code === 200) {
        toggle();
        toast.success("Success");
      } else {
        toast.error(data?.payload?.message);
      }
    });
  };

  const toggleTab = (val) => {
    setFilters({
      ...filters,
      active: val,
      page: 1,
    });
  };

  const handleToggle = (id) => {
    dispatch(
      toggleProduct(
        {
          filters,
          id,
        },
        { dispatch }
      )
    ).then((data) => {
      if (data && data.payload.response_code === 200) {
        toast.success(data.payload.message);
      } else {
        toast.error(data.payload.message);
      }
    });
  };

  const dateHandler = (object) => {
    const { startDate, endDate } = object;
    setFilters({
      ...filters,
      startDate,
      endDate,
    });
  };

  useEffect(() => {
    const escFunction = (event) => {
      if (event.key === "Escape") {
        //Do whatever when esc is pressed
        setPriceIndex([]);
        setUnitIndex([]);
        setIsPriceClicked(false);
        setIsUnitsClicked(false);
      }
    };
    window.addEventListener("keydown", escFunction, false);
  }, []);

  const updateProductHandler = () => {
    let arr1 = [...priceIndex];
    let arr2 = [...unitIndex];
    let finalArr = [];
    console.log(arr1, arr2);

    if (
      (arr1?.length === 1 && arr2?.length === 0) ||
      (arr1?.length === 0 && arr2?.length === 1)
    ) {
      finalArr.push({
        id: arr1[0]?.id || arr2[0]?.id,
        price: arr1[0]?.price || "",
        unit: arr2[0]?.unit || "",
      });
    } else if (arr1?.length > 1 && arr2?.length > 0) {
      for (let i = 0; i <= arr1.length; i++) {
        for (let y = 0; y < arr2.length; y++) {
          if (arr1[y]?.id === arr1[i]?.id) {
            finalArr.push({
              id: arr1[i]?.id || arr2[i]?.id,
              price: arr1[i]?.price || "",
              unit: arr2[y].unit || "",
            });
          }
          continue;
        }
      }
    }

    let promises = finalArr.map((ele) => {
      return dispatch(updateProduct(ele));
    });

    Promise.all(promises).then(function (results) {
      console.log(results);
      toast.success(results?.[0]?.payload?.message);
      setPriceIndex([]);
      setUnitIndex([]);
      setIsPriceClicked(false);
      setIsUnitsClicked(false);
    });
    console.log(finalArr, "Final array");
  };

  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="link-active">
              <img src="assets/images/Users-selected.png" alt="" />
              <span>
                <p>{total && total}</p>
                <p>Total Products</p>
              </span>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="d-flex align-items-center lg-justify-content-end">
              <form class="has-search">
                <span class="form-search-icon form-control-feedback"></span>
                <input
                  type="search"
                  class="form-control"
                  placeholder="Search Product by name"
                  name="search"
                  onChange={(e) => handleSearch(e)}
                />
              </form>
              <Link to="manage-products/add" class="add-new">
                + Add New
              </Link>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-sm-12">
            <div class="col-sm-12">
              <div class="row align-items-center">
                <div class="col-sm-12 col-md-6 col-lg-5 mb-3">
                  <ul class="nav nav-tabs group-nav" role="tablist">
                    <li class="nav-item">
                      <Link
                        className={
                          `nav-link ` + (filters.active ? "active" : "")
                        }
                        onClick={() => toggleTab(1)}
                        data-toggle="tab"
                        to="#"
                      >
                        active
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        className={
                          `nav-link ` + (filters.active ? "" : "active")
                        }
                        onClick={() => toggleTab(0)}
                        data-toggle="tab"
                        to="#"
                      >
                        inactive
                      </Link>
                    </li>
                  </ul>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-7 mb-3">
                  <div class="d-flex filter-flex align-items-center lg-justify-content-end">
                    <span>Filter</span>
                    <span class="ml-3">
                      <select
                        onChange={(e) =>
                          handleChange({
                            name: "category",
                            e,
                          })
                        }
                        value={filters?.category}
                        class="form-control h-auto"
                      >
                        <option value={""}>Select Category</option>
                        {catList &&
                          catList.map((data) => {
                            return <option value={data.id}>{data.name}</option>;
                          })}
                      </select>
                    </span>
                    <span class="ml-3">
                      <select
                        onChange={(e) =>
                          handleChange({
                            name: "subCategory",
                            e,
                          })
                        }
                        value={filters?.subCategory}
                        class="form-control h-auto"
                      >
                        <option value={""}>Select Sub-Category</option>
                        {subCatList &&
                          subCatList.map((data) => {
                            return <option value={data.id}>{data.name}</option>;
                          })}
                      </select>
                    </span>
                    <DateFilterModal dateHandler={dateHandler} />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="tab-content mt-4">
                <div id="inactive-product" class="tab-pane active">
                  <div class="table-responsive text-center">
                    <table class="table storetable">
                      <thead class="text-center">
                        <tr>
                          <th scope="col">product image</th>
                          <th scope="col">SKU ID</th>
                          <th scope="col">product Name</th>
                          <th scope="col">price </th>
                          <th scope="col">units </th>
                          <th scope="col">sub category</th>
                          <th scope="col">category</th>
                          <th scope="col">discount</th>
                          <th scope="col">rank</th>
                          <th scope="col">action</th>
                        </tr>
                        <tr class="border-0 bg-none invisible">
                          <td class="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        {data && data.length > 0 ? (
                          data.map((dataElem) => {
                            return (
                              <tr key={dataElem.id}>
                                <td>
                                  <div
                                    style={{
                                      height: "80px",
                                      width: "80px",
                                      margin: "0 auto",
                                      borderRadius: "10px",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <img
                                      onClick={() => {
                                        setPhotoIndex(0);
                                        setstate(
                                          dataElem.images.map((data) => {
                                            return {
                                              url: data.name,
                                              title: "Banner Images",
                                            };
                                          })
                                        );
                                      }}
                                      src={
                                        dataElem.images[0].name ||
                                        "assets/images/employe.png"
                                      }
                                      class="object-fit-cover"
                                      alt=""
                                    />
                                  </div>
                                </td>
                                <td>{dataElem.sku_id}</td>
                                <td>{dataElem.name}</td>
                                <td>
                                  {isPriceClicked &&
                                  priceIndex?.findIndex(
                                    (it) => it?.id === dataElem?.id
                                  ) !== -1 ? (
                                    <>
                                      <input
                                        type="number"
                                        className="form-control productEditInput"
                                        onChange={(e) => {
                                          let arr = [...priceIndex];
                                          let { value } = e.target;
                                          let index = arr.findIndex(
                                            (it) => it?.id === dataElem?.id
                                          );

                                          if (index === -1) {
                                            arr.push({
                                              price: value,
                                              id: dataElem?.id,
                                            });
                                          } else {
                                            arr[index] = {
                                              price: value,
                                              id: dataElem?.id,
                                            };
                                          }
                                          setPriceIndex(arr);
                                        }}
                                        onKeyDown={(e) => {
                                          let { key } = e;
                                          console.log(key, "Emter key");
                                          if (key === "Enter") {
                                            updateProductHandler();
                                          }
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <span
                                      className="cursor-pointer"
                                      onClick={() => {
                                        let arr = [...priceIndex];
                                        let isAdded =
                                          arr?.findIndex(
                                            (it) => it?.id === dataElem.id
                                          ) !== -1;
                                        if (!isAdded) {
                                          arr.push({ id: dataElem.id });
                                          setPriceIndex(arr);
                                          setIsPriceClicked(true);
                                        }
                                      }}
                                    >
                                      <i
                                        class="fa fa-inr"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {dataElem.price}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {isUnitsClicked &&
                                  unitIndex?.findIndex(
                                    (it) => it?.id === dataElem?.id
                                  ) !== -1 ? (
                                    <>
                                      <input
                                        type="number"
                                        className="form-control productEditInput"
                                        onChange={(e) => {
                                          let arr = [...unitIndex];
                                          let { value } = e.target;
                                          let index = arr.findIndex(
                                            (it) => it?.id === dataElem?.id
                                          );

                                          if (index === -1) {
                                            arr.push({
                                              unit: value,
                                              id: dataElem?.id,
                                            });
                                          } else {
                                            arr[index] = {
                                              unit: value,
                                              id: dataElem?.id,
                                            };
                                          }
                                          setUnitIndex(arr);
                                        }}
                                        onKeyDown={(e) => {
                                          let { key } = e;
                                          if (key === "Enter") {
                                            updateProductHandler();
                                          }
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <span
                                      className="cursor-pointer"
                                      onClick={() => {
                                        setUnitIndex(dataElem.id);
                                        setIsUnitsClicked(true);

                                        let arr = [...unitIndex];
                                        let isAdded =
                                          arr?.findIndex(
                                            (it) => it?.id === dataElem.id
                                          ) !== -1;
                                        if (!isAdded) {
                                          arr.push({ id: dataElem.id });
                                          setUnitIndex(arr);
                                          setIsUnitsClicked(true);
                                        }
                                      }}
                                    >
                                      {dataElem.unit}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {dataElem?.subcategory
                                    ?.map(
                                      (it) => it?.product_sub_category?.name
                                    )
                                    ?.join(", ") || "N/A"}
                                </td>
                                <td>
                                  {dataElem.category
                                    ?.map((it) => it?.product_category?.name)
                                    ?.join(", ") || "N/A"}
                                </td>
                                <td>{dataElem.discount + "%" || "N/A"}</td>
                                <td>{dataElem.rank}</td>
                                <td>
                                  <div class="d-flex justify-content-center action">
                                    <span
                                      onClick={() => {
                                        History.push(
                                          `/admin/manage-products/details/${dataElem.id}`
                                        );

                                        (async () => {
                                          await localStorage.setItem(
                                            "filters",
                                            JSON.stringify(filters)
                                          );
                                        })();

                                        dispatch(storeFilters(filters));
                                      }}
                                      class="btn"
                                    >
                                      <img
                                        alt=""
                                        src="assets/images/view.png"
                                      />
                                    </span>
                                    <button
                                      onClick={() => {
                                        History.push(
                                          `/admin/manage-products/edit/${dataElem.id}`
                                        );
                                        (async () => {
                                          await localStorage.setItem(
                                            "filters",
                                            JSON.stringify(filters)
                                          );
                                        })();

                                        dispatch(storeFilters(filters));
                                      }}
                                      class="btn"
                                    >
                                      <img
                                        alt=""
                                        src="assets/images/edit.png"
                                      />
                                    </button>
                                    <button class="btn">
                                      <input
                                        checked={dataElem.active}
                                        onChange={() =>
                                          handleToggle(dataElem.id)
                                        }
                                        type="checkbox"
                                        id={dataElem.id}
                                        class="toggle"
                                        alt=""
                                      />
                                    </button>
                                    <button
                                      onClick={() => {
                                        setDeleteId(dataElem.id);
                                        toggle();
                                      }}
                                      class="btn"
                                    >
                                      <img
                                        src="assets/images/delete.png"
                                        alt=""
                                      />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={10}>No Data Found.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {state.length > 0 && (
          <LightBoxImage
            mainSrc={state[photoIndex]}
            onClose={() => setstate([])}
            images={state}
            nextSrc={state[(photoIndex + 1) % state.length]}
            prevSrc={state[(photoIndex + state.length - 1) % state.length]}
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + state.length + 1) % state.length)
            }
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + state.length - 1) % state.length)
            }
          />
        )}
        {total && total > 10 ? (
          <div>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.limit}
              totalItemsCount={total}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <DeleteModal deleteCategory={deleteCat} modal={modal} toggle={toggle} />
    </article>
  );
};
export default ProductList;
