import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { postFormData } from "../../services/httphandler";
const OTP = () => {
  const History = useHistory();
  const { id } = useParams();
  console.log(id, "id");
  const [params, setParams] = useState({
    id: "",
    otp: "",
  });
  const [time, setTime] = useState(60);

  useEffect(() => {
    if (time > 0) {
      setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    }
  }, [time]);

  const handleResendOtp = () => {
    setTime(60);
  };

  const submitOtp = async (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("token", id);
    formdata.append("otp", params.otp);
    let response = postFormData("auth/verifyOtp", formdata);
    response.then((data) => {
      console.log(data, "data");
      if (data && data?.response_code === 200) {
        toast.success("Success");
        History.push(`/auth/reset-password/${id}`);
      } else {
        toast.error(data?.message);
      }
    });
    // if (response && response.response_code === 200) {
    //     // History.push(`/auth/otp-input/${response.data.token}`);
    //     toast.success('Success')
    // }
    // else {
    //     response.then(response => {
    //         toast.error(response.message)
    //     })
    //     History.push(`/auth/reset-password/${id}`);

    // }
  };

  return (
    <section class="">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-sm-12 col-md-12 col-lg-6 text-center">
            <div class="logo-bg">
              <div class="mb-5">
                <img
                  src="assets/images/logo.png"
                  class="img-fluid logo-admin"
                  alt="logo"
                />
              </div>
              <div class="mt-2">
                <img
                  src="assets/images/activity.png"
                  class="img-fluid lft-img"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 mr-0 pr-0">
            <div class="form-bg">
              <form onSubmit={submitOtp} class="row form-style">
                <div class="col-sm-12 text-center">
                  <img alt="" src="assets/images/otp.png" class="img-fluid" />
                  <h2 class="mdl-ttl">OTP Verification</h2>
                  <p class="mdl-sbttl">
                    We have send OTP on your email address
                  </p>
                </div>
                <div
                  className="opt_time mb-3 display-block text-center"
                  style={{ width: "100%" }}
                >
                  <span className="o_timing">00: {time}</span>
                </div>
                <div class="form-group col-sm-12">
                  <div class="passcode-wrapper">
                    <OtpInput
                      containerStyle={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                      inputStyle=""
                      value={params.otp}
                      onChange={(otp) =>
                        setParams({
                          ...params,
                          otp,
                        })
                      }
                      numInputs={4}
                    />
                  </div>
                </div>

                <div class="col-sm-12 mt-4">
                  <button type="submit" class="btn btn-save w-100">
                    Submit
                  </button>
                </div>
                <div class="col-sm-12 text-center mt-4">
                  <div>
                    Didn't get OTP?{" "}
                    <Link
                      to="#"
                      className="forgot-pass p-0"
                      style={{
                        color: "#b4195b",
                        cursor: `${time > 0 ? "no-drop" : "pointer"}`,
                      }}
                      onClick={
                        time === 0
                          ? handleResendOtp
                          : () => {
                              return false;
                            }
                      }
                    >
                      {" "}
                      Resend
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OTP;
