import { Link, useHistory } from "react-router-dom";
import FormInputPassword from "../../components/FormInputPassword";
import FormInputText from "../../components/FormInputText";
import { useFormik } from "formik";
import * as Yup from "yup";
import { postFormData } from "../../services/httphandler";
import { toast } from "react-toastify";

const Login = () => {
  const History = useHistory();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("*Invalid email").required("*Email is Required"),
    password: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("*Password is Required"),
  });

  const { values, handleSubmit, touched, errors, handleChange } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async ({ email, password }) => {
      let formdata = new FormData();
      formdata.append("email", email);
      formdata.append("password", password);
      let response = await postFormData("auth/login", formdata);
      if (response && response?.response_code === 200 && response?.success) {
        localStorage.setItem(
          "bookyourgift-admin",
          JSON.stringify(response?.data?.user_data)
        );
        History.push("/admin/dashboard");
        toast.success("Success");
      } else {
        toast.error(response?.message);
      }
    },
  });

  return (
    <section class="">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-sm-5 col-md-5 col-lg-6 text-center">
            <div class="logo-bg">
              <div class="mb-5">
                <img
                  src="assets/images/logo.png"
                  class="img-fluid logo-admin"
                  alt="logo"
                />
              </div>
              <div class="mt-2">
                <img
                  src="assets/images/activity.png"
                  class="img-fluid lft-img"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div class="col-sm-7 col-md-7 col-lg-6 mr-0 pr-0">
            <div class="form-bg">
              <form onSubmit={handleSubmit} class="form-style">
                <div class="row">
                  <div class="col-sm-12 col-md-12 text-center mb-5">
                    <h2 class="mdl-ttl">Login</h2>
                  </div>
                  <div class="col-sm-12 col-md-12 mb-4">
                    <FormInputText
                      onChange={handleChange}
                      name="email"
                      value={values.email}
                      placeholder="Email Address"
                    />
                    <span className="errorMessage">
                      {touched["email"] && errors["email"]}
                    </span>
                  </div>
                  <div class="col-sm-12 col-md-12 mb-4">
                    <FormInputPassword
                      onChange={handleChange}
                      name="password"
                      value={values.password}
                      placeholder="Password"
                    />
                    <span className="errorMessage">
                      {touched["password"] && errors["password"]}
                    </span>
                  </div>
                  <div class="col-sm-12 col-md-12 text-center mb-4">
                    <Link to="/auth/forget-password" class="forgot-pass">
                      Forgot Password?
                    </Link>
                  </div>
                  <div class="col-sm-12 col-md-12 text-center">
                    <button type="submit" class="btn btn-save w-100">
                      Log In
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
