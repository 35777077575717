import FormInputPassword from "../../components/FormInputPassword";
import { useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { postFormData } from "../../services/httphandler";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const History = useHistory();
  const { id } = useParams();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Too Short!")
      .max(50, "Too Long!")
      .required("*Password Is Required"),
    newpassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "*Passwords must match")
      .required("*Password Is Required"),
  });

  const { values, handleSubmit, touched, errors, handleChange } = useFormik({
    initialValues: {
      password: "",
      newpassword: "",
    },
    validationSchema,
    onSubmit: async ({ password, newpassword }) => {
      let formdata = new FormData();
      formdata.append("token", id);
      formdata.append("new_password", password);
      formdata.append("confirm_new_password", newpassword);

      let response = postFormData("auth/resetPassword", formdata);
      response.then((data) => {
        if (data && data?.response_code === 200) {
          toast.success(data?.message);
          History.push(`/auth/`);
        } else {
          toast.error(data?.message);
        }
      });
    },
  });

  return (
    <section class="">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-sm-5 col-md-5 col-lg-6 text-center">
            <div class="logo-bg">
              <div class="mb-5">
                <img
                  src="assets/images/logo.png"
                  class="img-fluid"
                  alt="logo"
                />
              </div>
              <div class="mt-2">
                <img
                  src="assets/images/activity.png"
                  class="img-fluid"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div class="col-sm-7 col-md-7 col-lg-6 mr-0 pr-0">
            <div class="form-bg">
              <form onSubmit={handleSubmit} class="form-style">
                <div class="row">
                  <div class="col-sm-12 col-md-12 text-center mb-4">
                    <img
                      alt=""
                      src="assets/images/reset.png"
                      class="img-fluid"
                    />
                    <h2 class="mdl-ttl">Reset Password</h2>
                    <p class="mdl-sbttl">Please reset your password</p>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <FormInputPassword
                      placeholder="Enter New Password"
                      onChange={handleChange}
                      name="password"
                      value={values.password}
                    />
                    <span className="errorMessage">
                      {touched["password"] && errors["password"]}
                    </span>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <FormInputPassword
                      placeholder="Enter confirm new password"
                      onChange={handleChange}
                      name="newpassword"
                      value={values.newpassword}
                    />
                    <span className="errorMessage">
                      {touched["newpassword"] && errors["newpassword"]}
                    </span>
                  </div>
                  <div class="col-sm-12 col-md-12 text-center">
                    <button type="submit" class="btn btn-save w-100">
                      Done
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
