// import ExpenseChart from "../../../components/expenseChart";
// import IncomeChart from "../../../components/incomeChart";
// import OrderChart from "../../../components/orderChart";
// import UserChart from "../../../components/userChart";
// import BarChart from "../../../components/barChart";
import ProductModal from "../../../components/productsModal";
import "./dashboard.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { dashboards, getDashboard } from "./dashboardSlice";
import moment from "moment";
import { useLocation } from "react-router-dom";
import SoldProducts from "../../../components/soldProduct";
import LineChart from "../../../components/charts/LineChart";
import BarChart from "../../../components/charts/BarChart";
import { getYearList } from "../../../services/helper";
import { useCallback } from "react";
import { getData } from "../../../services/httphandler";
import GridCard from "../../../components/gridCard";

const DashboardsSecond = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  console.log(pathname, "pathname");
  const [filters, setFilters] = useState({
    year: moment(new Date()).format("YYYY"),
    month: moment(new Date()).format("MM"),
  });

  const [orderFilters, setOrderFilters] = useState({
    year: moment(new Date()).format("YYYY"),
    month: moment(new Date()).format("MM"),
  });

  const [period, setPeriod] = useState("week");
  const [type, setType] = useState("income");
  const [labels, setLabels] = useState([]);
  const [datas, setDatas] = useState([]);
  const [orderData, setOrderData] = useState(null);

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    console.log(navigator.userAgent, "User AGENT");
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      setIsMobile(true);
    }
    // window.addEventListener("resize", (e) => {
    //   if (window.innerWidth <= 800 && window.innerHeight <= 600) {
    //     setIsMobile(true);
    //   }
    // });

    // return window.removeEventListener("resize", () => {
    //   if (window.innerWidth <= 800 && window.innerHeight <= 600) {
    //     setIsMobile(true);
    //   }
    // });
  }, []);
  console.log(isMobile, "IsMobile");

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFilters({
      ...filters,
      [id]: value,
    });
  };
  const handleOrderChange = (e) => {
    const { id, value } = e.target;
    setOrderFilters({
      ...filters,
      [id]: value,
    });
  };

  useEffect(() => {
    dispatch(getDashboard(filters)).then((res) => {
      console.log(res, "Dashboard res");
    });
  }, [dispatch, filters]);

  useEffect(() => {
    (async () => {
      let response = await getData("dashboard", orderFilters);
      setOrderData(response?.data);
    })();
  }, [dispatch, orderFilters]);

  const data = useSelector(dashboards);
  const years = getYearList();

  const graphSetting = useCallback(() => {
    if (data) {
      const obj = data?.type_graph;
      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          const element = obj[key];
          console.log(element, key, "Key and element ");
          if (type === key) {
            for (const innerKye in element) {
              if (Object.hasOwnProperty.call(element, innerKye)) {
                const element2 = element[innerKye];
                console.log(element2, "Element 2");
                let lbl = [];
                let dta = [];
                if (period === innerKye) {
                  if (period === "week") {
                    lbl = element2?.map((it) => it?.date);
                    dta = element2?.map((it) => it[`${key}`]);
                  } else {
                    lbl = element2?.map((it) => it[`${innerKye}`]);
                    dta = element2?.map((it) => it[`${key}`]);
                  }
                  setLabels(lbl);
                  setDatas(dta);
                }
                continue;
              }
            }
          }
          continue;
        }
      }
    }
  }, [data, type, period]);

  useEffect(() => {
    graphSetting();
  }, [graphSetting]);
  console.log(labels, datas, "Labels and datas");

  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy charts">
        <div>
          <div className="filter-header pl-4">
            <h1>Total</h1>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-3 mb-3">
              <div className="wrapper">
                <label className="icon">
                  <img src="assets/images/order-img.png" alt="" />
                </label>
                <div className="mb-4 px-3 pt-4 pb-2 text-left">
                  <p>Total orders</p>
                  <h2>{data && data?.total_order_graph?.total_new_order}</h2>
                </div>
                <div className="chart">
                  {data && (
                    <LineChart
                      labels={data?.total_order_graph?.total_orders.map(
                        (data) => data?.year
                      )}
                      data={data?.total_order_graph?.total_orders.map(
                        (data) => data?.order
                      )}
                      label="Total Order"
                    />
                  )}
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-3 mb-3">
              <div className="wrapper green">
                <label className="icon">
                  <img src="assets/images/income.png" alt="" />
                </label>
                <div className="mb-4 px-3 pt-4 pb-2 text-left">
                  <p>Total Income</p>
                  <h2>{data && data?.total_income_graph?.total_income}</h2>
                </div>
                <div className="chart">
                  <BarChart
                    labels={
                      data &&
                      data?.total_income_graph?.income?.map(
                        (data) => data?.year
                      )
                    }
                    data={
                      data &&
                      data?.total_income_graph?.income?.map(
                        (data) => data?.income
                      )
                    }
                    label="Total Income"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-3 mb-3">
              <div className="wrapper blue">
                <label className="icon">
                  <img src="assets/images/expense.png" alt="" />
                </label>
                <div className="mb-4 px-3 pt-4 pb-2 text-left">
                  <p>Total Viewer</p>
                  <h2>{data && data?.total_viewer_graph?.total_viewer}</h2>
                </div>
                <div className="chart">
                  <LineChart
                    labels={
                      data &&
                      data?.total_viewer_graph?.viewer.map((data) => data?.year)
                    }
                    data={
                      data &&
                      data?.total_viewer_graph.viewer?.map(
                        (data) => data?.viewer
                      )
                    }
                    label="Total Viewer"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-3 mb-3">
              <div className="wrapper yellow">
                <label className="icon">
                  <img src="assets/images/user.png" alt="" />
                </label>
                <div className="mb-4 px-3 pt-4 pb-2 text-left">
                  <p>Total Users</p>
                  <h2>{data && data?.total_user_graph?.total_user}</h2>
                </div>
                <div className="chart">
                  <BarChart
                    labels={
                      data &&
                      data?.total_viewer_graph.viewer?.map((data) => data?.year)
                    }
                    data={
                      data &&
                      data?.total_viewer_graph.viewer?.map(
                        (data) => data?.viewer
                      )
                    }
                    label="Total Users"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row m-0 filter-header">
          <div className="col-sm-6">
            <h1>Monthly </h1>
          </div>
          <div class="col-sm-6">
            <div class="d-flex align-items-center lg-justify-content-end">
              <span>Filter</span>
              <span class="ml-3">
                <select
                  id="month"
                  value={filters.month}
                  onChange={handleChange}
                  class="form-control h-auto"
                >
                  <option value={""}>Select Month</option>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </span>
              <span class="ml-3">
                <select
                  id="year"
                  value={filters.year}
                  onChange={handleChange}
                  class="form-control h-auto"
                >
                  <option value={""}>Select Year</option>
                  {years?.map((it, i) => {
                    return <option value={it}>{it}</option>;
                  })}
                </select>
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6 col-md-4 col-lg-3 mb-3 h-100">
            <div className="wrapper">
              <label className="icon">
                <img src="assets/images/order-img.png" alt="" />
              </label>
              <div className="mb-4 px-3 pt-4 pb-2 text-left">
                <p>Orders</p>
                <h2>{data && data?.order_graph?.new_order}</h2>
                <span className="fss-6">
                  {data &&
                    (Math.sign(data?.order_graph?.percentage_diff) === -1
                      ? "decreased by "
                      : "increased by ") +
                      Math.abs(data?.order_graph?.percentage_diff?.toFixed(0))}
                  % (3 months)
                </span>
              </div>
              <div className="chart">
                <LineChart
                  labels={
                    data && data?.order_graph?.orders.map((data) => data?.month)
                  }
                  data={
                    data &&
                    data?.order_graph?.orders.map((data) => data?.t_oder)
                  }
                  label="Orders"
                />
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-3 mb-3 h-100">
            <div className="wrapper green">
              <label className="icon">
                <img src="assets/images/income.png" alt="" />
              </label>
              <div className="mb-4 px-3 pt-4 pb-2 text-left">
                <p>Income</p>
                <h2>{data && data?.income_graph?.total_income}</h2>
                <span className="fss-6">
                  {data &&
                    (Math.sign(data?.income_graph?.percentage_diff) === -1
                      ? "decreased by "
                      : "increased by ") +
                      Math.abs(data?.income_graph?.percentage_diff?.toFixed(0))}
                  % (3 months)
                </span>
              </div>
              <div className="chart">
                <BarChart
                  labels={
                    data &&
                    data?.income_graph.income?.map((data) => data?.month)
                  }
                  data={
                    data &&
                    data?.income_graph.income?.map((data) => data?.t_income)
                  }
                  label="Income"
                />
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-3 mb-3 h-100">
            <div className="wrapper blue">
              <label className="icon">
                <img src="assets/images/expense.png" alt="" />
              </label>
              <div className="mb-4 px-3 pt-4 pb-2 text-left">
                <p>Viewer</p>
                <h2>{data && data?.viewer_graph?.total_viewer}</h2>
                <span className="fss-6">
                  {data &&
                    (Math.sign(data?.viewer_graph?.percentage_diff) === -1
                      ? "decreased by "
                      : "increased by ") +
                      Math.abs(data?.viewer_graph?.percentage_diff?.toFixed(0))}
                  % (3 months)
                </span>
              </div>
              <div className="chart">
                <LineChart
                  labels={
                    data &&
                    data?.viewer_graph.viewer?.map((data) => data?.month)
                  }
                  data={
                    data &&
                    data?.viewer_graph.viewer?.map((data) => data?.t_viwer)
                  }
                  label="Viewer"
                />
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-3 mb-3 h-100">
            <div className="wrapper yellow">
              <label className="icon">
                <img src="assets/images/user.png" alt="" />
              </label>
              <div className="mb-4 px-3 pt-4 pb-2 text-left">
                <p>Users</p>
                <h2>{data && data?.user_graph?.total_user}</h2>
                <span className="fss-6">
                  {data &&
                    (Math.sign(data?.user_graph?.percentage_diff) === -1
                      ? "decreased by "
                      : "increased by ") +
                      Math.abs(data?.user_graph?.percentage_diff.toFixed(0))}
                  % (3 months)
                </span>
              </div>
              <div className="chart">
                <BarChart
                  labels={
                    data && data?.user_graph.user?.map((data) => data?.month)
                  }
                  data={
                    data && data?.user_graph.user?.map((data) => data?.t_usr)
                  }
                  label="Users"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row filter-header pl-4 mt-2">
          <div className="col-sm-6">
            <h1>Orders</h1>
          </div>
          <div className="col-sm-6">
            <div class="d-flex align-items-center lg-justify-content-end">
              <span>Filter</span>
              <span class="ml-3">
                <select
                  id="month"
                  value={orderFilters.month}
                  onChange={handleOrderChange}
                  class="form-control h-auto"
                >
                  <option value={""}>Select Month</option>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </span>
              <span class="ml-3">
                <select
                  id="year"
                  value={orderFilters.year}
                  onChange={handleOrderChange}
                  class="form-control h-auto"
                >
                  <option value={""}>Select Year</option>
                  {years?.map((it, i) => {
                    return <option value={it}>{it}</option>;
                  })}
                </select>
              </span>
            </div>
          </div>
        </div>
        <div className="orderGrid charts p-0">
          <div class=" mb-3">
            <div className="wrapper">
              <div className="mb-4 px-4 pt-2 pb-2 text-center orderGridWidth">
                <p>Total Orders</p>
                <p>
                  {orderData?.total_orders?.total_order &&
                    orderData?.total_orders?.total_order}
                </p>
              </div>
            </div>
          </div>
          <div class=" mb-3">
            <div className="wrapper green">
              <div className="mb-4 px-4 pt-2 pb-2 text-center orderGridWidth">
                <p>Total Pending</p>
                <p>
                  {orderData?.total_orders?.total_pending &&
                    orderData?.total_orders?.total_pending}
                </p>
              </div>
            </div>
          </div>
          <div class=" mb-3">
            <div className="wrapper blue">
              <div className="mb-4 px-4 pt-2 pb-2 text-center orderGridWidth">
                <p>Total Cancel</p>
                <p>
                  {orderData?.total_orders?.total_cancel &&
                    orderData?.total_orders?.total_cancel}
                </p>
              </div>
            </div>
          </div>
          <div class=" mb-3">
            <div className="wrapper yellow">
              <div className="mb-4 px-4 pt-2 pb-2 text-center orderGridWidth">
                <p>Total Returned</p>
                <p>
                  {orderData?.total_orders?.total_returned &&
                    orderData?.total_orders?.total_returned}
                </p>
              </div>
            </div>
          </div>
          <div class=" mb-3">
            <div className="wrapper">
              <div className="mb-4 px-4 pt-2 pb-2 text-center orderGridWidth">
                <p>Total Delivered</p>
                <p>
                  {orderData?.total_orders?.total_delivered &&
                    orderData?.total_orders?.total_delivered}
                </p>
              </div>
            </div>
          </div>

          <div class=" mb-3">
            <div className="wrapper green">
              <div className="mb-4 px-4 pt-2 pb-2 text-center orderGridWidth">
                <p>Total In Progress</p>
                <p>
                  {orderData?.total_orders?.total_inprogress &&
                    orderData?.total_orders?.total_inprogress}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row m-0 filter-header">
          <div className="col-sm-6 mb-3">
            <h1>Income and Products</h1>
          </div>
          <div className="col-sm-6">
            <div className="d-flex align-items-baseline period-wrapper">
              <div
                className={
                  period === "week" ? "periodsCard current" : "periodsCard"
                }
                onClick={() => {
                  setPeriod("week");
                }}
              >
                Weekly
              </div>
              <div
                className={
                  period === "month" ? "periodsCard current" : "periodsCard"
                }
                onClick={() => {
                  setPeriod("month");
                }}
              >
                Monthly
              </div>
              <div
                className={
                  period === "year" ? "periodsCard current" : "periodsCard"
                }
                onClick={() => {
                  setPeriod("year");
                }}
              >
                Yearly
              </div>
            </div>
          </div>

          <div className="col-sm-12 mt-4 mb-3">
            <div className="incomePeriodWrapper">
              <div
                className={
                  type === "income" ? "periodsCard active" : "periodsCard "
                }
                onClick={() => {
                  setType("income");
                }}
              >
                Income
              </div>
              <div
                className={
                  type === "product_sold"
                    ? "periodsCard active"
                    : "periodsCard "
                }
                onClick={() => {
                  setType("product_sold");
                }}
              >
                Product
              </div>
            </div>
          </div>

          {labels && datas && (
            <BarChart
              labels={labels?.length && labels}
              data={datas?.length && datas}
              label={type}
              full={true}
              isMobile={isMobile}
            />
          )}
        </div>

        <div className="row my-3">
          <div className="col-md-12">
            <div className="card-box">
              <div className="text d-flex justify-content-between">
                Top Selling Products
                {data && data?.top_sold_products && (
                  <ProductModal
                    text="Top Selling Products"
                    data={data?.top_sold_products}
                  />
                )}
              </div>
              <ul className="row products">
                {data &&
                  data?.top_sold_products &&
                  data?.top_sold_products?.slice(0, 3)?.map((data) => {
                    return (
                      <div
                        key={data?.id}
                        className="col-sm-6 col-md-6 col-lg-4 mb-4"
                      >
                        {/* <div className="image">
                          <img
                            src={
                              data?.images?.[0].name ||
                              "assets/images/upload-img.png"
                            }
                            alt=""
                          />
                        </div>

                        <div className="data">
                          <h2>{data?.name || "N/A"}</h2>
                          <p>
                            {data?.sizes && data?.sizes.length > 0
                              ? data?.sizes.map((data) => data?.size + ",") ||
                                "N/A"
                              : "N/A"}
                          </p>
                          <span>
                            <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                            {data?.price}{" "}
                          </span>
                        </div> */}
                        <GridCard data={data} />
                      </div>
                    );
                  })}
              </ul>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card-box">
              <div className="text d-flex justify-content-between">
                Recent Products
                {data && data?.recent_products && (
                  <ProductModal
                    text="Recent Products"
                    data={data?.recent_products}
                  />
                )}
              </div>
              <ul className="row products">
                {data &&
                  data?.recent_products &&
                  data?.recent_products?.slice(0, 3)?.map((data) => {
                    return (
                      <div
                        key={data?.id}
                        className="col-sm-6 col-md-6 col-lg-4 mb-4"
                      >
                        {/* <div className="image">
                          <img
                            src={
                              data?.images?.[0].name ||
                              "assets/images/upload-img.png"
                            }
                            alt=""
                          />
                        </div>

                        <div className="data">
                          <h2>{data?.name || "N/A"}</h2>
                          <p>
                            {data?.sizes && data?.sizes.length > 0
                              ? data?.sizes.map((data) => data?.size + ",") ||
                                "N/A"
                              : "N/A"}
                          </p>
                          <span>
                            <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                            {data?.price}{" "}
                          </span>
                        </div> */}
                        <GridCard data={data} type="recent" />
                      </div>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div className="card-box">
              <div className="text d-flex justify-content-between">
                Sold Products
                {data && data?.sold_products && (
                  <SoldProducts data={data?.sold_products} />
                )}
              </div>
              <div class="table-responsive text-center">
                <table class="table storetable table-sold">
                  <thead class="text-center">
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Revenue</th>
                      <th scope="col">Sold</th>
                      <th scope="col">Discount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.sold_products?.length > 0 ? (
                      data?.sold_products?.slice(0, 4)?.map((dataElem, i) => {
                        return (
                          <tr key={dataElem.id}>
                            <td>{dataElem.name}</td>
                            <td>
                              <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                              {dataElem.price}
                            </td>
                            <td>{dataElem.total_sold}</td>
                            <td>{dataElem.discount + "%"}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={4}>No Data Found.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default DashboardsSecond;
