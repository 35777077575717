import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteData,
  getData,
  postFormDataWithToken,
  putEncodedData,
} from "../../../services/httphandler";

const initialState = {
  isLoading: false,
  categoryList: {},
  categoryDetails: "",
};

export const addCategory = createAsyncThunk(
  "addCategory",
  async (object, { dispatch }) => {
    let response = await postFormDataWithToken("category/add", object);
    if (response && response.response_code === 200) {
      dispatch(categoryList());
      return response;
    }
  }
);

export const updateCategory = createAsyncThunk(
  "updateCategory",
  async (object, { dispatch }) => {
    let response = await putEncodedData("category/update", object);
    if (response && response.response_code === 200) {
      dispatch(categoryList());
      return response;
    }
  }
);

export const toggleCategory = createAsyncThunk(
  "toggleCategory",
  async (object, { dispatch }) => {
    let response = await putEncodedData("category/toggle", object);
    if (response && response.response_code === 200) {
      dispatch(categoryList());
      return response;
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "category/delete",
  async (object, { dispatch }) => {
    let response = await deleteData("category/delete", object);
    if (response && response.response_code === 200) {
      dispatch(categoryList());
      return response;
    }
  }
);

export const categoryDetails = createAsyncThunk(
  "category/id",
  async (object, { dispatch }) => {
    let response = await getData("category/detail", object);
    if (response && response.response_code === 200) {
      return response.data;
    }
  }
);

export const categoryList = createAsyncThunk(
  "categoryList",
  async (object, { dispatch }) => {
    let response = await getData("category/get", object);
    if (response && response.response_code === 200) {
      return response;
    }
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState,
  extraReducers: {
    [addCategory.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [categoryList.fulfilled]: (state, action) => {
      state.categoryList = action?.payload?.data?.categories;
      state.isLoading = false;
    },
    [categoryDetails.fulfilled]: (state, action) => {
      state.categoryDetails = action?.payload;
      state.isLoading = false;
    },
  },
});

export default categorySlice.reducer;
