import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { editGroup, groupDetails } from "./groupSlice";

const EditGroup = () => {
  const [optionsDta, setoptionsDta] = useState(null);
  const [labelDta, setlabelDta] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const initialValues = {
    label: labelDta || "",
    options:
      optionsDta?.length > 0
        ? optionsDta?.map((item) => {
            return item;
          })
        : [{ value: "" }],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      label: Yup.string().required("Required"),
    }),
    enableReinitialize: true,
    onSubmit: (values, { isSubmitting, resetForm }) => {
      const payload = {
        label: values.label,
        option: JSON.stringify(values.options),
        id: id,
      };

      dispatch(editGroup(payload)).then((data) => {
        if (data.payload.response_code === 200) {
          toast.success(data.payload.message);
          history.push("/admin/manage-group");
        } else toast.success(data.payload.message);
      });
    },
  });
  useEffect(() => {
    dispatch(groupDetails({ id })).then((data) => {
      //   console.log(data);
      setlabelDta(data.payload.labelGroup.label);
      setoptionsDta(data.payload.labelGroup.options);
    });
  }, []);

  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <h2 class="head-title">Edit Group</h2>
          </div>
        </div>
        <div class="row">
          <form class="form-style" onSubmit={formik.handleSubmit}>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group product-type py-2" id="color">
                  <label>Enter Label</label>
                  <div class="input-container">
                    <input
                      class="form-control pl-5"
                      placeholder="Enter Label"
                      {...formik.getFieldProps("label")}
                    />
                    <img
                      alt=""
                      src={`assets/images/small-cate.png`}
                      class="input-img"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="form-group p-2 ">
                  <div className="d-flex justify-content-between align-items-center">
                    <label>SelectBox Items</label>
                    <div
                      onClick={() => {
                        let arr = [...formik.values.options];
                        arr.push({
                          value: "",
                        });
                        formik.setFieldValue("options", arr);
                      }}
                      class="AddMore"
                      title="Add More"
                    >
                      +
                    </div>
                  </div>
                  <hr />
                  <div className="w-100 mb-3"></div>
                  <div className="">
                    {formik?.values?.options?.map((item, i) => (
                      <div className="d-flex gap-3" key={i}>
                        <input
                          value={item?.value || ""}
                          {...formik.getFieldProps(`options.${i}.value`)}
                          className="form-control border rounded mb-2"
                          placeholder="Attachment values"
                        />
                        <span
                          onClick={() => {
                            let arr = [...formik.values.options];
                            arr.splice(i, 1);
                            formik.setFieldValue("options", arr);
                          }}
                          class="AddMore"
                          title="Delete"
                        >
                          x
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 mt-5">
                <div class="text-center">
                  <button type="submit" class="btn btn-save">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </article>
  );
};

export default EditGroup;
