import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import FormInputText from '../../../components/FormInputText';
import Upload from '../../../components/uploadFile.js'
import { bannerDetails, updateBanner } from "./bannerSlice";

const EditBanner = () => {

    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [image, setImage] = useState({
        desktop: '',
    })

    const [mobileImage, setMobileImage] = useState({
        mobile: '',
    })
    const [ipadImage, setIpadImage] = useState({
        ipad: ''
    })
    const [isSubmitting, setIssubmitting] = useState(false);

    const [params, setparams] = useState({
        from_date: "",
        to_date: "",
        title: "",
        id: "",
    });


    useEffect(() => {
        dispatch(bannerDetails({ id })).then(data => {
            setparams({
                from_date: data.payload.banner.from_date,
                to_date: data.payload.banner.to_date,
                title: data.payload.banner.name,
                id: data.payload.banner.id,
            })
            setImage({
                desktop: data.payload.banner.web_images.map(data => {
                    return data.name
                }).toString(),
                mobile: data.payload.banner.mobile_image.map(data => {
                    return data.name
                }).toString(),
                ipad: data.payload.banner.ipad_image.map(data => {
                    return data.name
                }).toString()
            }

            )
            setMobileImage({
                mobile: data.payload.banner.mobile_image.map(data => {
                    return data.name
                }).toString(),

            }

            )
            setIpadImage({
                ipad: data.payload.banner.ipad_image.map(data => {
                    return data.name
                }).toString()
            }

            )
        })
    }, [dispatch, id]);





    const validationSchema = Yup.object().shape({
        title: Yup.string().required('*Title is Required'),
        from_date: Yup.string().required('*From Date is Required'),
        to_date: Yup.string().required('*To Date is Required'),
    });



    const { values, handleSubmit, touched, errors, handleChange, } = useFormik({
        initialValues: params,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async () => {
            if (image.desktop && image.ipad && image.mobile) {
                setIssubmitting(true);
                dispatch(updateBanner({ ...values, images: image.desktop, ipad_image: ipadImage.ipad, mobile_image: mobileImage.mobile })).then(data => {
                    if (data?.payload?.response_code === 200) {
                        history.push('/admin/banner');
                        toast.success('Success');
                    }
                    else {
                        toast.error(data?.payload?.message);
                        setIssubmitting(false);
                    }
                })
            }
            else {
                toast.error('*Please upload images.')
                setIssubmitting(false);
            }

        },
    })






    const dataHandler = (data, key) => {
        setImage({
            ...image,
            [key]: data.location
        })
    }


    return (

        <article class="col-lg-9">
            <div class="article-body store-bd-gy">
                <div class="row align-items-center col-rever">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <h2 class="head-title">Edit Banner</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <form onSubmit={handleSubmit} class="form-style">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 text-center multiple-banner">

                                    <Upload text="Image" imager={image.desktop} dataHandler={(data) => dataHandler(data, 'desktop')} />
                                    <Upload text="mobile image" imager={image.mobile} dataHandler={(data) => dataHandler(data, 'mobile')} />
                                    <Upload text="ipad image" imager={image.ipad} dataHandler={(data) => dataHandler(data, 'ipad')} />


                                </div>
                                <div class="col-sm-12">

                                    <FormInputText
                                        placeholder="Enter Banner Title"
                                        type="text"
                                        image="product.png"
                                        onChange={handleChange}
                                        name="title"
                                        value={values.title}
                                        title="Title"
                                    />

                                    <span className="errorMessage">
                                        {touched["title"] && errors["title"]}
                                    </span>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-6">


                                    <FormInputText
                                        placeholder="Enter Price"
                                        type="date"
                                        image="product.png"
                                        onChange={handleChange}
                                        name="from_date"
                                        value={values.from_date}
                                        title="From"
                                    />

                                    <span className="errorMessage">
                                        {touched["from_date"] && errors["from_date"]}
                                    </span>
                                </div>


                                <div class="col-sm-6 col-md-6 col-lg-6">


                                    <FormInputText
                                        placeholder="date"
                                        type="date"
                                        image="small-cate.png"
                                        onChange={handleChange}
                                        name="to_date"
                                        value={values.to_date}
                                        title="To"
                                    />

                                    <span className="errorMessage">
                                        {touched["to_date"] && errors["to_date"]}
                                    </span>
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-12 mt-5">
                                    <div class="text-center">
                                        <button disabled={isSubmitting} type="submit" class="btn btn-save">Save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </article>
    )
}

export default EditBanner;