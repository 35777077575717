import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData } from "../../../services/httphandler";

const initialState = {
    isLoading: false,
    dashboard: null
}

export const dashboards = state => state.dashboard.dashboard;
export const getDashboard = createAsyncThunk(
    'dashboard',
    async (object) => {
        let response = await getData('dashboard', object);
        return response.data;
    }
)


const CustomerSlice = createSlice({
    name: 'dashboard',
    initialState,
    extraReducers: {
        [getDashboard.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.dashboard = action.payload;
        }
    }

})

export default CustomerSlice.reducer;