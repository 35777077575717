import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { useRef, useState, useMemo } from "react";
import * as Yup from "yup";
import FormInputText from "../../../components/FormInputText";
import { useDispatch } from "react-redux";
import { addCategory } from "./categorySlice";
import { toast } from "react-toastify";
import Upload from "../../../components/uploadFile.js";
import Select from "react-select";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import S3 from "react-aws-s3";
import { v4 as uuidv4 } from "uuid";
import ImageResize from "quill-image-resize-module-react";

Quill.register("modules/imageResize", ImageResize);

var Size = Quill.import("attributors/style/size");
Size.whitelist = [
  "10px",
  "11px",
  "12px",
  "13px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
];
Quill.register(Size, true);

const reactSelectMultipleOptionStyle = {
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: 0,
  }),
};
const AddCategory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [image, setImage] = useState({
    imagePreview: "",
    image: "",
  });
  const [descrp, setDescrp] = useState("");
  const paymentModeOptions = [
    {
      label: "Online Pyament",
      value: 1,
    },
    {
      label: "COD",
      value: 2,
    },
  ];
  const validationSchema = Yup.object().shape({
    category: Yup.string().required("*Category Name is Required"),
    // description: Yup.string().required("*Description is Required"),
    rank: Yup.number()
      .positive("Kindly enter a positive number")
      ?.integer("Kindly enter a valid number")
      ?.required("*Rank is Required"),
  });

  const { values, handleSubmit, touched, errors, handleChange, setFieldValue } =
    useFormik({
      initialValues: {
        category: "",
        description: "",
        rank: "",
        payment_method: [],
      },
      validationSchema,
      onSubmit: async ({ category, description, rank, payment_method }) => {
        if (image && image.image) {
          let formdata = new FormData();
          formdata.append("image", image.image);
          formdata.append("category_name", category);
          formdata.append("description", descrp);
          formdata.append("rank", rank);
          formdata.append(
            "payment_method",
            payment_method?.map((it) => it?.value)?.join(",")
          );
          dispatch(addCategory(formdata, { dispatch })).then((data) => {
            if (data?.payload?.response_code === 200) {
              history.push("/admin/category");
              toast.success("Success");
            } else {
              toast.error(data?.payload?.message);
            }
          });
        } else {
          toast.error("*Please add image.");
        }
      },
    });

  const dataHandler = (data) => {
    setImage({
      ...image,
      image: data.location,
    });
  };

  const handlePaymentChange = (val) => {
    setFieldValue("payment_method", val);
  };

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    s3Url: `https://${process.env.REACT_APP_BUCKET_NAME}.s3.amazonaws.com` /* without the suffix zone added */,
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      console.log(percentCompleted);
    },
  };

  const quillRef = useRef();

  const QID = uuidv4();

  const ReactS3Client = new S3(config);

  const newFileName = QID;

  const ImageUpload = (formData) => {
    var url = ReactS3Client.uploadFile(formData, newFileName).then((data) => {
      return data.location;
    });
    return url;
  };

  const imageHandler = (e) => {
    const editor = quillRef.current.getEditor();
    // console.log(editor);
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        // console.log(file);

        const res = await ImageUpload(input.files[0]); // upload data into server or aws or cloudinary

        // console.log(res, "res");
        editor.insertEmbed(editor.getSelection(), "image", res);
      }
    };
  };

  const [showHtml, setShowHtml] = useState(false);

  const handleHtml = () => {
    setShowHtml(!showHtml);
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],

          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["image", "link"],
          // ["blockquote", "code-block"],
          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ size: Size.whitelist }],
          [{ align: [] }],
        ],

        handlers: {
          image: imageHandler,
        },
      },
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
      },
    }),
    []
  );

  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <h2 class="head-title">Add New Category</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <form onSubmit={handleSubmit} class="form-style">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                  <Upload dataHandler={dataHandler} />
                </div>
                <div class="col-sm-12 col-md-2 col-lg-2"></div>
                <div class="col-sm-12 col-md-8 col-lg-8">
                  <FormInputText
                    placeholder="Enter Category Name"
                    image="small-cate.png"
                    onChange={handleChange}
                    name="category"
                    type="text"
                    value={values.category}
                    title="Category Name"
                  />

                  <span className="errorMessage">
                    {touched["category"] && errors["category"]}
                  </span>
                </div>
                <div class="col-sm-12 col-md-2 col-lg-2"></div>
                <div class="col-sm-12 col-md-2 col-lg-2"></div>
                <div class="col-sm-12 col-md-8 col-lg-8">
                  <div class="form-group">
                    <label className="d-flex justify-content-between pr-3">
                      Description
                      {/* <span
                        onClick={() => {
                          handleHtml();
                        }}
                      >
                        <i class="fa fa-code" aria-hidden="true"></i>
                      </span> */}
                    </label>
                    <div class="input-container">
                      {showHtml ? (
                        <textarea
                          onChange={(e) => {
                            setDescrp(e.target.value);
                          }}
                          value={descrp}
                          name="description"
                          class="textarea-control"
                          rows="7"
                        ></textarea>
                      ) : (
                        <ReactQuill
                          theme="snow"
                          value={descrp}
                          name="description"
                          onChange={(content, delta, source, editor) => {
                            // console.log(editor.getHTML(), "content");
                            setDescrp(content);
                          }}
                          ref={quillRef}
                          modules={modules}
                          style={{
                            minHeight: "50vh",
                          }}
                        />
                      )}

                      {/* <textarea
                        onChange={handleChange}
                        value={values.description}
                        name="description"
                        class="textarea-control"
                        rows="7"
                      ></textarea> */}
                    </div>
                  </div>
                  <span className="errorMessage">
                    {touched["description"] && errors["description"]}
                  </span>
                </div>
                <div class="col-sm-12 col-md-2 col-lg-2"></div>
                <div class="col-sm-12 col-md-2 col-lg-2"></div>
                <div class="col-sm-12 col-md-8 col-lg-8">
                  <FormInputText
                    placeholder="Rank"
                    image="small-cate.png"
                    onChange={handleChange}
                    name="rank"
                    type="number"
                    value={values.rank}
                    title="Rank"
                  />

                  <span className="errorMessage">
                    {touched["rank"] && errors["rank"]}
                  </span>
                </div>
                <div class="col-sm-12 col-md-2 col-lg-2"></div>
                <div class="col-sm-12 col-md-2 col-lg-2"></div>
                <div class="col-sm-12 col-md-8 col-lg-8">
                  <div class="form-group">
                    <label>Accepted Payment Mode</label>
                    <div class="input-container">
                      <Select
                        onChange={handlePaymentChange}
                        isMulti={true}
                        placeholder="Select Accepted Payment Mode"
                        options={paymentModeOptions}
                        styles={reactSelectMultipleOptionStyle}
                      />

                      <img
                        src="assets/images/small-cate.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <button type="submit" class="btn btn-save">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </article>
  );
};

export default AddCategory;
