import { configureStore } from "@reduxjs/toolkit";
import dashboardSlice from "../views/folder/Dashboard/dashboardSlice";
import cityReducer from "../views/folder/DeliveryCities/citySlice";
import bannerSlice from "../views/folder/ManageBanner/bannerSlice";
import categoryReducer from "../views/folder/ManageCategory/categorySlice";
import subCategoryReducer from "../views/folder/ManageCategory/subCategorySlice";
import categoryReportSlice from "../views/folder/ManageCategoryReport/categoryReportSlice";
import cmsSlice from "../views/folder/ManageCms/cmsSlice";
import couponReducer from "../views/folder/ManageCoupons/couponSlice";
import customerSlice from "../views/folder/ManageCustomer/customerSlice";
import faqSlice from "../views/folder/ManageFaqs/faqSlice";
import groupSlice from "../views/folder/ManageGroup/groupSlice";
import occasionSlice from "../views/folder/ManageOccasion/occasionSlice";
import orderSlice from "../views/folder/ManageOrders/orderSlice";
import productReducer from "../views/folder/ManageProduct/productSlice";
import relationSlice from "../views/folder/ManageRelation/relationSlice";
import salesSlice from "../views/folder/ManageSalesReport/salesSlice";
import CartListing from "../views/folder/ManageCart/CartListingSlice";
import otherOrderSlice from "../views/folder/ManageOtherOrders/OtherOrders";

export const store = configureStore({
  reducer: {
    category: categoryReducer,
    subCategory: subCategoryReducer,
    product: productReducer,
    coupon: couponReducer,
    city: cityReducer,
    relation: relationSlice,
    occasion: occasionSlice,
    banner: bannerSlice,
    customer: customerSlice,
    dashboard: dashboardSlice,
    order: orderSlice,
    faq: faqSlice,
    cms: cmsSlice,
    sales: salesSlice,
    categoryReport: categoryReportSlice,
    group: groupSlice,
    cart: CartListing,
    otherOrder: otherOrderSlice,
  },
});
