import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import FormInputText from '../../../components/FormInputText';
import { toast } from 'react-toastify';
import { faqDetails, UpdateFaq } from './faqSlice';

const EditFaqs = () => {

    const [state, setstate] = useState({
        id: '',
        question: '',
        answer: ''
    })
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const validationSchema = Yup.object().shape({
        question: Yup.string().required('*Question is Required'),
        answer: Yup.string().required('*Answer is Required'),
    });

    const {
        values,
        handleSubmit,
        touched,
        errors,
        handleChange,
    } = useFormik({
        initialValues: state,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async () => {
            dispatch(UpdateFaq(values, { dispatch })).then(data => {
                if (data?.payload?.response_code === 200) {
                    history.push('/admin/faqs');
                    toast.success('Success');
                }
                else {
                    toast.error(data?.payload?.message);
                }
            })


        },
    });

    useEffect(() => {
        dispatch(faqDetails({ id })).then(data => {
            setstate({
                id: data.payload.id,
                question: data.payload.question,
                answer: data.payload?.answer
            })
        })
    }, [dispatch, id]);


    return (
        <article class="col-lg-9">
            <div class="article-body store-bd-gy">
                <div class="row align-items-center col-rever">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <h2 class="head-title">Edit Faqs</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <form onSubmit={handleSubmit} class="form-style">
                            <div class="row">

                                <div class="col-sm-12 col-md-2 col-lg-2"></div>
                                <div class="col-sm-12 col-md-8 col-lg-8">
                                    <FormInputText
                                        placeholder="Enter Question"
                                        image="small-cate.png"
                                        onChange={handleChange}
                                        name="question"
                                        type="text"
                                        value={values.question}
                                        title="Question"
                                    />


                                    <span className="errorMessage">
                                        {touched["question"] && errors["question"]}
                                    </span>

                                </div>
                                <div class="col-sm-12 col-md-2 col-lg-2"></div>
                                <div class="col-sm-12 col-md-2 col-lg-2"></div>
                                <div class="col-sm-12 col-md-8 col-lg-8">
                                    <div class="form-group">
                                        <label>Answer</label>
                                        <div class="input-container">
                                            <textarea
                                                onChange={handleChange}
                                                value={values.answer}
                                                name="answer"
                                                class="textarea-control" rows="7"></textarea>
                                        </div>
                                    </div>
                                    <span className="errorMessage">
                                        {touched["answer"] && errors["answer"]}
                                    </span>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="text-center">
                                        <button type="submit" class="btn btn-save">Save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </article>
    )
}

export default EditFaqs;