import FormInputText from "../../components/FormInputText";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { postFormData } from "../../services/httphandler";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const History = useHistory();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("*Invalid email").required("*Email is Required"),
  });

  const { values, handleSubmit, touched, errors, handleChange } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async ({ email }) => {
      let formdata = new FormData();
      formdata.append("email", email);
      let response = await postFormData("auth/forgotPassword", formdata);
      if (response && response?.response_code === 200 && response?.success) {
        History.push(`/auth/otp-input/${response?.data?.token}`);
        toast.success("Success");
      } else {
        toast.error(response?.message);
      }
    },
  });
  return (
    <section class="">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-sm-5 col-md-5 col-lg-6 text-center">
            <div class="logo-bg">
              <div class="mb-5">
                <img
                  src="assets/images/logo.png"
                  class="img-fluid logo-admin"
                  alt="logo"
                />
              </div>
              <div class="mt-2">
                <img
                  src="assets/images/activity.png"
                  class="img-fluid lft-img"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div class="col-sm-7 col-md-7 col-lg-6 mr-0 pr-0">
            <div class="form-bg">
              <form onSubmit={handleSubmit} class="form-style">
                <div class="row">
                  <div class="col-sm-12 col-md-12 text-center mb-4">
                    <img
                      alt=""
                      src="assets/images/forgot-password.png"
                      class="img-fluid"
                    />
                    <h2 class="mdl-ttl">Forgot Password</h2>
                    <p class="mdl-sbttl">
                      Please enter your email address in order to retrieve your
                      password
                    </p>
                  </div>
                  <div class="col-sm-12 col-md-12 mb-4">
                    <FormInputText
                      onChange={handleChange}
                      name="email"
                      value={values.email}
                      placeholder="Email Address"
                    />
                    <span className="errorMessage">
                      {touched["email"] && errors["email"]}
                    </span>
                  </div>
                  <div class="col-sm-12 col-md-12 text-center">
                    <button type="submit" class="btn btn-save w-100">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
