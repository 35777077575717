import { Link } from "react-router-dom";
import { router } from "../../routes";
import { useLocation, useHistory } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  productList,
  storeFilters,
} from "../../views/folder/ManageProduct/productSlice";
const Sidebar = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const History = useHistory();
  const [activeIndex, setActiveIndex] = useState("");
  const [topNav, setTopNav] = useState(false);
  const toggle = () => {
    setTopNav(!topNav);
  };
  return (
    <div className="col-lg-3 store-sidebar admin-sidebar">
      <div className="shadow-part">
        <a
          className="navbar-brand bg-none px-3 mx-lg-auto d-lg-block"
          href="index.html"
        >
          <img alt="" src="assets/images/logo.png" className="img-fluid" />
        </a>
        <button
          onClick={toggle}
          className="btn btn-blue float-right menu-toggle d-lg-none"
          data-target="#sidebar"
          data-toggle="collapse"
        >
          {" "}
          <i className="fa fa-bars"></i>
        </button>
        <nav
          class={" collapse d-lg-block " + (topNav ? "show" : "")}
          id="sidebar"
        >
          <ul className="list-unstyled mb-0">
            {router.map((prop, i) => {
              if (prop.hidden) {
                return false;
              } else if (prop.collapse) {
                return (
                  <li key={i}>
                    <Link
                      className={activeIndex === prop.state ? "active" : ""}
                      to="#"
                      onClick={() => {
                        setActiveIndex(prop.state);
                      }}
                    >
                      <div>
                        <img
                          src={prop.img1}
                          alt=""
                          className="img-fluid sidebar-icon inactive-icon"
                        />
                        <img
                          src={prop.img2}
                          alt=""
                          className="img-fluid sidebar-icon active-icon"
                        />
                      </div>
                      <span>{prop.name} </span>
                    </Link>
                    <div
                      class={
                        "collapse-border " +
                        (activeIndex === prop.state ? "" : "collapse")
                      }
                      id="category"
                    >
                      {prop.child.map((propChild, i) => {
                        if (propChild.hidden) {
                          return false;
                        } else if (propChild.name === "Logout") {
                          return (
                            <Link
                              onClick={() => {
                                localStorage.removeItem("bookyourgift-admin");
                                History.push("/auth");
                              }}
                              to={propChild.path}
                              key={i}
                            >
                              {propChild.name}
                            </Link>
                          );
                        } else
                          return (
                            <Link
                              className={
                                pathname.startsWith(propChild.path)
                                  ? "active"
                                  : ""
                              }
                              onClick={toggle}
                              to={propChild.path}
                            >
                              {propChild.name}
                            </Link>
                          );
                      })}
                    </div>
                  </li>
                );
              } else {
                return (
                  <li key={i}>
                    <Link
                      onClick={() => {
                        setActiveIndex("");
                        toggle();
                        if (prop.name.toLowerCase() === "manage products") {
                          (async () => {
                            await localStorage.setItem(
                              "filters",
                              JSON.stringify({
                                page: 1,
                                limit: 10,
                                active: 1,
                                search: "",
                                category: "",
                                subCategory: "",
                              })
                            );
                          })();

                          dispatch(
                            storeFilters({
                              page: 1,
                              limit: 10,
                              active: 1,
                              search: "",
                              category: "",
                              subCategory: "",
                            })
                          );
                        }
                      }}
                      className={pathname.startsWith(prop.path) ? "active" : ""}
                      to={prop.path}
                    >
                      <div>
                        <img
                          src={prop.img1}
                          alt=""
                          className="img-fluid sidebar-icon inactive-icon"
                        />
                        <img
                          src={prop.img2}
                          alt=""
                          className="img-fluid sidebar-icon active-icon"
                        />
                      </div>
                      <span>{prop.name} </span>
                    </Link>
                  </li>
                );
              }
            })}
            {/* <li><a href="dashboard.html" className="active">
                            <div>
                                <img alt="" src="assets/images/dashboard.png" className="img-fluid sidebar-icon inactive-icon" />
                                <img alt="" src="assets/images/dashboard-selected.png" className="img-fluid sidebar-icon active-icon" />
                            </div>
                            <span>dashboard </span></a></li>
                        <li><a href="customers.html">
                            <div>
                                <img alt="" src="assets/images/customers.png" className="img-fluid sidebar-icon inactive-icon" />
                                <img alt="" src="assets/images/customers-selected.png" className="img-fluid sidebar-icon active-icon" />
                            </div>
                            <span>manage customers </span></a></li>

                        <li><a href="manage_orders.html">
                            <div>
                                <img alt="" src="assets/images/orders.png" className="img-fluid sidebar-icon inactive-icon" />
                                <img alt="" src="assets/images/orders-selected.png" className="img-fluid sidebar-icon active-icon" />
                            </div>
                            <span>manage orders </span></a></li>

                        <li className="">
                            <a href="#" className="show-collape-border collapsed" data-target="#category" data-toggle="collapse">
                                <div>
                                    <img alt="" src="assets/images/categories.png" className="img-fluid sidebar-icon inactive-icon" />
                                    <img alt="" src="assets/images/categories-selected.png" className="img-fluid sidebar-icon active-icon" />
                                </div>
                                <span>manage categories </span></a>
                            <div className="collapse collapse-border" id="category">
                                <a className="dropdown-item" href="categories.html"><span>categories</span></a>
                                <a className="dropdown-item" href="sub-categories.html"><span>sub-categories</span></a>
                            </div>
                        </li>

                        <li><a href="manage_products.html">
                            <div>
                                <img alt="" src="assets/images/products.png" className="img-fluid sidebar-icon inactive-icon" />
                                <img alt="" src="assets/images/products-selected.png" className="img-fluid sidebar-icon active-icon" />
                            </div>
                            <span> manage products </span></a></li>

                        <li><a href="manage_coupons.html">
                            <div>
                                <img alt="" src="assets/images/coupons.png" className="img-fluid sidebar-icon inactive-icon" />
                                <img alt="" src="assets/images/coupons-selected.png" className="img-fluid sidebar-icon active-icon" />
                            </div>
                            <span> manage coupons </span></a></li>

                        <li><a href="delivery_cities.html">
                            <div>
                                <img alt="" src="assets/images/delivery-citie.png" className="img-fluid sidebar-icon inactive-icon" />
                                <img alt="" src="assets/images/delivery-citie-selected.png" className="img-fluid sidebar-icon active-icon" />
                            </div>
                            <span> delivery cities </span></a></li>

                        <li className="">
                            <a href="#" className="show-collape-border collapsed" data-target="#setting" data-toggle="collapse">
                                <div>
                                    <img alt="" src="assets/images/Settings.png" className="img-fluid sidebar-icon inactive-icon" />
                                    <img alt="" src="assets/images/Settings-selected.png" className="img-fluid sidebar-icon active-icon" />
                                </div>
                                <span>setting </span></a>
                            <div className="collapse collapse-border" id="setting">
                                <a className="dropdown-item" href="change-password.html"><span>change password</span></a>
                                <a className="dropdown-item" href="#"><span>logout <i className="fa fa-sign-out" aria-hidden="true"></i></span></a>
                            </div>
                        </li> */}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
