import React from "react";

function AddressCard({ data }) {
  return (
    <div className="shadow card p-3 w-100">
      <p>
        <label className="">
          <b>Address:</b>
        </label>{" "}
        <span>{data?.address}</span>
      </p>
      <p>
        <label>
          <b>City:</b>
        </label>{" "}
        <span>{data?.city}</span>
      </p>
      <p>
        <label>
          <b>State:</b>
        </label>{" "}
        <span>{data?.state}</span>
      </p>
      <p>
        <label>
          <b>Country:</b>
        </label>{" "}
        <span>{data?.country}</span>
      </p>
      <p>
        <label>
          <b>Zipcode:</b>
        </label>{" "}
        <span>{data?.zipcode}</span>
      </p>
      <p>
        <label>
          <b>Resident Type:</b>
        </label>{" "}
        <span>{data?.resident_type}</span>
      </p>
    </div>
  );
}

export default AddressCard;
