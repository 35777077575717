import ForgotPassword from "../views/auth/forgetPassword";
import Login from "../views/auth/login";
import OTP from "../views/auth/otp";
import ResetPassword from "../views/auth/resetPassword";
import Dashboard from "../views/folder/Dashboard";
import AddCategory from "../views/folder/ManageCategory/addCategory";
import AddSubCategory from "../views/folder/ManageCategory/addSubCategory";
import CategoryList from "../views/folder/ManageCategory/categoryList";
import SubCategoryList from "../views/folder/ManageCategory/subCategoryList";
import CustomerList from "../views/folder/ManageCustomer/CustomerList";
import ViewCustomer from "../views/folder/ManageCustomer/viewCustomer";
import OrdersList from "../views/folder/ManageOrders/orderList";
import ViewOrder from "../views/folder/ManageOrders/viewOrder";
import AddProduct from "../views/folder/ManageProduct/addProduct";
import ProductList from "../views/folder/ManageProduct/productList";
import AddCoupon from "../views/folder/ManageCoupons/addCoupon";
import CouponList from "../views/folder/ManageCoupons/couponList";
import DeliveryCityList from "../views/folder/DeliveryCities/deliveryCityList";
import AddCities from "../views/folder/DeliveryCities/addCities";
import ChangePassword from "../views/folder/Setting/changePassword";
import EditCategory from "../views/folder/ManageCategory/editCategory";
import EditSubCategory from "../views/folder/ManageCategory/editSubCategory";
import EditProduct from "../views/folder/ManageProduct/editProduct";
import EditCoupon from "../views/folder/ManageCoupons/editCoupon";
import EditCity from "../views/folder/DeliveryCities/editCity";
import RelationList from "../views/folder/ManageRelation/relationList";
import AddRelation from "../views/folder/ManageRelation/addRelation";
import EditRelation from "../views/folder/ManageRelation/editRelation";
import OccasionList from "../views/folder/ManageOccasion/occasionList";
import AddOccasion from "../views/folder/ManageOccasion/addOccasion";
import EditOccasion from "../views/folder/ManageOccasion/editOccasion";
import BannerList from "../views/folder/ManageBanner/bannerList";
import EditBanner from "../views/folder/ManageBanner/editBanner";
import AddBanner from "../views/folder/ManageBanner/addBanner";
import FaqList from "../views/folder/ManageFaqs/faqList";
import AddFaq from "../views/folder/ManageFaqs/addfaq";
import UpdateTnc from "../views/folder/ManageCms/updateTnc";
import UpdateRefundPolicy from "../views/folder/ManageCms/updateRefundpolicy";
import UpdateCancellationPolicy from "../views/folder/ManageCms/updateCancellationPolicy";
import UpdatePrivacy from "../views/folder/ManageCms/updatePrivacy";
import UpdateAbout from "../views/folder/ManageCms/updateAbout";
import EditFaqs from "../views/folder/ManageFaqs/updateFaqs";
import UpdateContactUS from "../views/folder/ManageCms/updateContactUs";
import UpdateContactNumber from "../views/folder/ManageCms/UpdateContactNumber";
import SalesList from "../views/folder/ManageSalesReport/salesReport";
import CategoryReportList from "../views/folder/ManageCategoryReport/categoryReportList";
import ProductDetails from "../views/folder/ManageProduct/productDetails";
import DashboardsSecond from "../views/folder/Dashboard/dashboard2";
import InventoryList from "../views/folder/ManageInventory/inventoryList";
import AddGroup from "../views/folder/ManageGroup/addGroup";
import GroupList from "../views/folder/ManageGroup/groupList";
import EditGroup from "../views/folder/ManageGroup/editGroup";
import GroupDetails from "../views/folder/ManageGroup/groupDetails";
import CartListing from "../views/folder/ManageCart/CartListing";
import CartDetails from "../views/folder/ManageCart/CartDetails";
import ManageCod from "../views/folder/Setting/ManageCod";
import ManageShipping from "../views/folder/Setting/ManageShipping";
import List from "../views/folder/ManageOtherOrders/list";
import Detail from "../views/folder/ManageOtherOrders/detail";
import BlogList from "../views/folder/ManageBlog/BlogList";
import AddEditBlog from "../views/folder/ManageBlog/AddEditBlog";

export const authRoutes = [
  {
    path: "/auth/login",
    element: Login,
  },
  {
    path: "/auth/forget-password",
    element: ForgotPassword,
  },
  {
    path: "/auth/otp-input/:id",
    element: OTP,
  },
  {
    path: "/auth/reset-password/:id",
    element: ResetPassword,
  },
];

export const router = [
  {
    name: "Dashboard",
    path: "/admin/dashboard",
    component: DashboardsSecond,
    hidden: false,
    img1: "assets/images/dashboard.png",
    img2: "assets/images/dashboard-selected.png",
  },
  {
    name: "Manage Customers",
    path: "/admin/manage-customer/view/:id",
    component: ViewCustomer,
    hidden: true,
  },
  {
    name: "Manage Customers",
    path: "/admin/manage-customer",
    component: CustomerList,
    hidden: false,
    img1: "assets/images/customers.png",
    img2: "assets/images/customers-selected.png",
  },
  {
    name: "Manage Orders",
    path: "/admin/manage-order/view/:id",
    component: ViewOrder,
    hidden: true,
  },

  {
    name: "Manage Orders",
    path: "/admin/manage-order",
    component: OrdersList,
    hidden: false,
    img1: "assets/images/orders.png",
    img2: "assets/images/orders-selected.png",
  },
  {
    name: "Manage Orders",
    path: "/admin/manage-other-order/view/:id",
    component: Detail,
    hidden: true,
  },
  {
    name: "Manage Other Orders",
    path: "/admin/manage-other-order",
    component: List,
    hidden: false,
    img1: "assets/images/orders.png",
    img2: "assets/images/orders-selected.png",
  },
  {
    name: "Manage Category",
    path: "/admin/manage-category",
    collapse: true,
    hidden: false,
    img1: "assets/images/category.png",
    img2: "assets/images/categories-selected.png",
    state: "category",
    child: [
      {
        name: "Categories",
        path: "/admin/category/add",
        component: AddCategory,
        hidden: true,
      },
      {
        name: "Categories",
        path: "/admin/category/edit/:id",
        component: EditCategory,
        hidden: true,
      },
      {
        name: "Categories",
        path: "/admin/category",
        component: CategoryList,
        hidden: false,
      },
      {
        name: "Sub-Categories",
        path: "/admin/sub-category/add",
        component: AddSubCategory,
        hidden: true,
      },
      {
        name: "Sub-Categories",
        path: "/admin/sub-category/edit/:id",
        component: EditSubCategory,
        hidden: true,
      },
      {
        name: "Sub-Categories",
        path: "/admin/sub-category",
        component: SubCategoryList,
        hidden: false,
      },
    ],
  },

  {
    name: "Manage Products",
    path: "/admin/manage-products/add",
    component: AddProduct,
    hidden: true,
  },
  {
    name: "Manage Products",
    path: "/admin/manage-products/edit/:id",
    component: EditProduct,
    hidden: true,
  },
  {
    name: "Manage Products",
    path: "/admin/manage-products/details/:id",
    component: ProductDetails,
    hidden: true,
  },
  {
    name: "Manage Products",
    path: "/admin/manage-products",
    component: ProductList,
    hidden: false,
    img1: "assets/images/products.png",
    img2: "assets/images/products-selected.png",
  },

  {
    name: "Manage Groups",
    path: "/admin/manage-group/add",
    component: AddGroup,
    hidden: true,
    img1: "assets/images/products.png",
    img2: "assets/images/products-selected.png",
  },
  {
    name: "Manage Groups",
    path: "/admin/manage-group/edit/:id",
    component: EditGroup,
    hidden: true,
    img1: "assets/images/products.png",
    img2: "assets/images/products-selected.png",
  },
  {
    name: "Manage Groups",
    path: "/admin/manage-group/details/:id",
    component: GroupDetails,
    hidden: true,
    img1: "assets/images/products.png",
    img2: "assets/images/products-selected.png",
  },
  {
    name: "Manage Groups",
    path: "/admin/manage-group",
    component: GroupList,
    hidden: false,
    img1: "assets/images/products.png",
    img2: "assets/images/products-selected.png",
  },
  {
    name: "Manage Inventory",
    path: "/admin/manage-inventory",
    component: InventoryList,
    hidden: false,
    img1: "assets/images/products.png",
    img2: "assets/images/products-selected.png",
  },
  {
    name: "Manage cart",
    path: "/admin/manage-cart/view/:key",
    component: CartDetails,
    hidden: true,
  },
  {
    name: "Manage cart",
    path: "/admin/manage-cart",
    component: CartListing,
    hidden: false,
    img1: "assets/images/products.png",
    img2: "assets/images/products-selected.png",
  },

  {
    name: "Manage Coupons",
    path: "/admin/manage-coupons/add",
    component: AddCoupon,
    hidden: true,
  },
  {
    name: "Manage Coupons",
    path: "/admin/manage-coupons/edit/:id",
    component: EditCoupon,
    hidden: true,
  },
  {
    name: "Manage Coupons",
    path: "/admin/manage-coupons",
    component: CouponList,
    hidden: false,
    img1: "assets/images/coupons.png",
    img2: "assets/images/coupons-selected.png",
  },
  {
    name: "Manage Relation",
    path: "/admin/manage-relation/add",
    component: AddRelation,
    hidden: true,
  },
  {
    name: "Manage Relation",
    path: "/admin/manage-relation/edit/:id",
    component: EditRelation,
    hidden: true,
  },
  {
    name: "Manage Relation",
    path: "/admin/manage-relation",
    component: RelationList,
    hidden: false,
    img1: "assets/images/relation.png",
    img2: "assets/images/relation-selected.png",
  },
  {
    name: "Manage Occasion",
    path: "/admin/occasion/edit/:id",
    component: EditOccasion,
    hidden: true,
  },

  {
    name: "Manage Occasion",
    path: "/admin/occasion/add",
    component: AddOccasion,
    hidden: true,
  },
  {
    name: "Manage Occasion",
    path: "/admin/occasion",
    component: OccasionList,
    hidden: false,
    img1: "assets/images/occasion.png",
    img2: "assets/images/occasion-selected.png",
  },
  {
    name: "Manage Banners",
    path: "/admin/banner/edit/:id",
    component: EditBanner,
    hidden: true,
  },
  {
    name: "Manage Banners",
    path: "/admin/banner/add",
    component: AddBanner,
    hidden: true,
  },

  {
    name: "Manage Banners",
    path: "/admin/banner",
    component: BannerList,
    hidden: false,
    img1: "assets/images/banner.png",
    img2: "assets/images/banner-selected.png",
  },
  {
    name: "Delivery Cities",
    path: "/admin/delivery-cities/add",
    component: AddCities,
    hidden: true,
  },
  {
    name: "Delivery Cities",
    path: "/admin/delivery-cities/edit/:id",
    component: EditCity,
    hidden: true,
  },
  {
    name: "Delivery Cities",
    path: "/admin/delivery-cities",
    component: DeliveryCityList,
    hidden: false,
    img1: "assets/images/delivery-citie.png",
    img2: "assets/images/delivery-citie-selected.png",
  },
  {
    name: "Manage Faqs",
    path: "/admin/faqs/edit/:id",
    component: EditFaqs,
    hidden: true,
  },
  {
    name: "Manage Faqs",
    path: "/admin/faqs/add",
    component: AddFaq,
    hidden: true,
  },
  {
    name: "Manage Faqs",
    path: "/admin/faqs",
    component: FaqList,
    hidden: false,
    img1: "assets/images/Manage-Faqs.png",
    img2: "assets/images/Manage-Faqs-selected.png",
  },
  {
    name: "Manage Blog",
    path: "/admin/blog/edit/:id",
    component: AddEditBlog,
    hidden: true,
  },
  {
    name: "Manage Blog",
    path: "/admin/blog/add",
    component: AddEditBlog,
    hidden: true,
  },
  {
    name: "Manage Blog",
    path: "/admin/blog",
    component: BlogList,
    hidden: false,
    img1: "assets/images/Manage-Blog.png",
    img2: "assets/images/Manage-Blog-selected.png",
  },
  {
    name: "Sales Report",
    path: "/admin/sales",
    component: SalesList,
    hidden: false,
    img1: "assets/images/customers.png",
    img2: "assets/images/customers-selected.png",
  },
  {
    name: "Category Report",
    path: "/admin/cat-report",
    component: CategoryReportList,
    hidden: false,
    img1: "assets/images/report.png",
    img2: "assets/images/report-selected.png",
  },
  {
    name: "Manage Cms",
    path: "/admin/manage-category",
    collapse: true,
    hidden: false,
    img1: "assets/images/Manage-CMS.png",
    img2: "assets/images/Manage-CMS-selected.png",
    state: "cms",
    child: [
      {
        name: "Terms & Conditions",
        path: "/admin/cms/updatetnc",
        component: UpdateTnc,
        hidden: false,
        img1: "assets/images/dashboard.png",
        img2: "assets/images/dashboard-selected.png",
      },
      {
        name: "Shipping & Delivery",
        path: "/admin/cms/shipping-and-delivery",
        component: UpdateRefundPolicy,
        hidden: false,
        img1: "assets/images/dashboard.png",
        img2: "assets/images/dashboard-selected.png",
      },
      {
        name: "Cancellation Policy",
        path: "/admin/cms/updateCancellationPolicy",
        component: UpdateCancellationPolicy,
        hidden: false,
        img1: "assets/images/dashboard.png",
        img2: "assets/images/dashboard-selected.png",
      },
      {
        name: "Privacy Policy",
        path: "/admin/cms/updatePrivacy",
        component: UpdatePrivacy,
        hidden: false,
        img1: "assets/images/dashboard.png",
        img2: "assets/images/dashboard-selected.png",
      },
      {
        name: "About Us",
        path: "/admin/cms/updateAbout",
        component: UpdateAbout,
        hidden: false,
        img1: "assets/images/dashboard.png",
        img2: "assets/images/dashboard-selected.png",
      },

      {
        name: "Contact Us",
        path: "/admin/cms/updateContact",
        component: UpdateContactUS,
        hidden: false,
        img1: "assets/images/dashboard.png",
        img2: "assets/images/dashboard-selected.png",
      },
      {
        name: "Contact Number",
        path: "/admin/cms/UpdateNumber",
        component: UpdateContactNumber,
        hidden: false,
        img1: "assets/images/dashboard.png",
        img2: "assets/images/dashboard-selected.png",
      },
    ],
  },
  {
    name: "Setting",
    path: "/admin/setting",
    hidden: false,
    img1: "assets/images/Settings.png",
    img2: "assets/images/Settings-selected.png",
    state: "setting",
    collapse: true,
    child: [
      {
        name: "COD",
        path: "/admin/setting/manage-cod",
        component: ManageCod,
        hidden: false,
        img1: "assets/images/dashboard.png",
        img2: "assets/images/dashboard-selected.png",
      },
      {
        name: "Shipping",
        path: "/admin/setting/manage-shipping",
        component: ManageShipping,
        hidden: false,
        img1: "assets/images/dashboard.png",
        img2: "assets/images/dashboard-selected.png",
      },
      {
        name: "Change Password",
        path: "/admin/setting/change-password",
        component: ChangePassword,
        hidden: false,
        img1: "assets/images/dashboard.png",
        img2: "assets/images/dashboard-selected.png",
      },
      {
        name: "Logout",
        path: "#",
        hidden: false,
        img1: "assets/images/dashboard.png",
        img2: "assets/images/dashboard-selected.png",
      },
    ],
  },
];
