import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import FormInputText from "../../../components/FormInputText";
import Upload from "../../../components/uploadFile.js";
import { toast } from "react-toastify";
import { subCategoryDetails, updateSubCategory } from "./subCategorySlice";
import { categoryList } from "./categorySlice";

const EditSubCategory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [state, setstate] = useState({
    id: "",
    image: "",
    sub_category: "",
    category: "",
    rank: "",
  });

  useEffect(() => {
    dispatch(categoryList({ page: "1", limit: "1000", search: "" }));
  }, [dispatch]);

  const data = useSelector((state) => state.category.categoryList.data);

  useEffect(() => {
    dispatch(subCategoryDetails({ id })).then((data) => {
      setstate({
        id: data.payload.sub_category?.id,
        image: data.payload.sub_category?.image,
        sub_category: data.payload.sub_category.name,
        rank: data.payload.sub_category.rank,
        category: data.payload.sub_category.category.id || "",
      });
    });
  }, [dispatch, id]);

  const validationSchema = Yup.object().shape({
    sub_category: Yup.string().required("*Sub Category Name is Required"),
    category: Yup.string().required("*Category Name is Required"),
    rank: Yup.number()
      .positive("Kindly enter a positive number")
      ?.integer("Kindly enter a valid number")
      ?.required("*Rank is Required"),
  });

  const { values, handleSubmit, touched, errors, handleChange } = useFormik({
    initialValues: state,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async () => {
      if (state && state.image) {
        dispatch(updateSubCategory(values, { dispatch })).then((data) => {
          if (data?.payload?.response_code === 200) {
            history.push("/admin/sub-category");
            toast.success("Success");
          } else {
            toast.error(data?.payload?.message);
          }
        });
      } else {
        toast.error("* Please add image.");
      }
    },
  });

  const dataHandler = (data) => {
    setstate({
      ...state,
      image: data.location,
    });
  };
  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <h2 class="head-title">Edit Sub-Category</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <form onSubmit={handleSubmit} class="form-style">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                  <Upload dataHandler={dataHandler} imager={state.image} />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <FormInputText
                    placeholder="Enter Sub-Category Name"
                    type="text"
                    image="small-cate.png"
                    onChange={handleChange}
                    name="sub_category"
                    value={values.sub_category}
                    title="Sub-Category Name"
                  />

                  <span className="errorMessage">
                    {touched["sub_category"] && errors["sub_category"]}
                  </span>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Category</label>
                    <div class="input-container">
                      <select
                        onChange={handleChange}
                        name="category"
                        value={values.category}
                        class="form-control pl-5"
                      >
                        <option value="">Select Category</option>
                        {data &&
                          data.map((data) => {
                            return <option value={data.id}>{data.name}</option>;
                          })}
                      </select>
                      <img
                        src="assets/images/small-cate.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  <span className="errorMessage">
                    {touched["category"] && errors["category"]}
                  </span>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <FormInputText
                    placeholder="Rank"
                    image="small-cate.png"
                    onChange={handleChange}
                    name="rank"
                    type="number"
                    value={values.rank}
                    title="Rank"
                  />

                  <span className="errorMessage">
                    {touched["rank"] && errors["rank"]}
                  </span>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 mt-5">
                  <div class="text-center">
                    <button type="submit" class="btn btn-save">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </article>
  );
};

export default EditSubCategory;
