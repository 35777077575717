import moment from "moment";

export const authHeader = () => {
  const Token = JSON.parse(localStorage.getItem("bookyourgift-admin"));
  if (Token) {
    return {
      Authorization: `Bearer ${Token.token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
};

export const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 500);
  };
};

export const authHeaderUrlEncoded = () => {
  const Token = JSON.parse(localStorage.getItem("bookyourgift-admin"));
  if (Token) {
    return {
      Authorization: `Bearer ${Token.token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    };
  }
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
};

export const getPaymentMode = (value) => {
  switch (value) {
    case "CREDIT_CARD":
      return "Credit Card";

    case "NET_BANKING":
      return "Net Banking";

    default:
      return value || "N/A";
  }
};

export const getYearList = () => {
  let arr = [];
  let currentYear = moment().format("YYYY");
  let previousYear = "2021";

  for (let index = previousYear; index <= currentYear; index++) {
    arr.push(index);
  }
  return arr.sort((a, b) => b - a);
};
