import { Modal } from "reactstrap";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { categoryDetails } from "../../views/folder/ManageCategory/categorySlice";

const CategoryViewModal = ({ id }) => {
  const [modal, setModal] = React.useState(false);
  const dispatch = useDispatch();
  const [state, setstate] = useState({
    id: "",
    image: "",
    category_name: "",
    subCategory: [],
  });

  useEffect(() => {
    dispatch(categoryDetails({ id })).then((data) => {
      setstate({
        id: data?.payload?.category?.id,
        image: data?.payload?.category?.image,
        category_name: data?.payload?.category?.name,
        subCategory: data?.payload.category?.subcategory || [],
        payment_method: data?.payload.category?.category_payment_method || [],
      });
    });
  }, [dispatch, id]);

  // Toggle for Modal
  const toggle = () => setModal(!modal);
  return (
    <>
      <button class="btn" onClick={toggle}>
        <img alt="" src="assets/images/view.png" />
      </button>
      <Modal isOpen={modal} toggle={toggle}>
        <div class="modal-content">
          <div class="modal-body text-capitalize">
            <button
              onClick={toggle}
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="text-center mt-2">
              <img alt="" src="assets/images/small-cate.png" />
              {/* <p class="mb-0">{state.category_name}</p> */}
              <h5>{state.category_name}</h5>
              <div class="cate-img">
                <img
                  alt=""
                  src={state.image || "assets/images/employe.png"}
                  class="img-fluid"
                />
              </div>
            </div>
            <hr />
            <div class="modal-dec">
              <img
                alt=""
                src="assets/images/small-cate.png"
                class="modal-dec-img"
              />
              <h5>Sub-Category</h5>
              <div
                className={
                  state?.subCategory?.length > 0 ? "modal-sub-cate" : ""
                }
              >
                {(state?.subCategory &&
                  state?.subCategory?.length > 0 &&
                  state?.subCategory?.map((data) => {
                    return (
                      <div key={data?.id}>
                        <div
                          style={{
                            height: "80px",
                            width: "80px",
                            margin: "0 auto",
                            borderRadius: "10px",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            alt=""
                            className="object-fit-cover"
                            src={data?.image || "assets/images/employe.png"}
                          />
                        </div>
                        <p>{data?.name}</p>
                      </div>
                    );
                  })) || <div>No Data Found</div>}
              </div>
            </div>
            <div class="modal-dec mt-2">
              <img
                alt=""
                src="assets/images/small-cate.png"
                class="modal-dec-img"
              />
              <h5>Payment Method</h5>
              <div
                className={
                  state?.payment_method?.length > 0 ? "modal-sub-cate" : ""
                }
              >
                {(state?.payment_method &&
                  state?.payment_method?.length > 0 &&
                  state?.payment_method?.map((data) => {
                    return (
                      <div key={data?.id}>
                        <p>
                          {data?.payment_mode === 1
                            ? "Online Payment"
                            : data?.payment_mode === 2
                            ? "COD"
                            : ""}
                        </p>
                      </div>
                    );
                  })) || <div>No Data Found</div>}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CategoryViewModal;
