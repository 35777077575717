import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteData,
  getData,
  postFormDataNew,
  postFormDataWithToken,
  putEncodedData,
} from "../../../services/httphandler";

const initialState = {
  isLoading: false,
  groupList: "",
  groupDetails: "",
};

export const groups = (state) => state.group.groupList.data;
export const totals = (state) => state.group.groupList.total;

export const addGroup = createAsyncThunk(
  "lebel/add",
  async (params, { dispatch }) => {
    let response = await postFormDataWithToken("label/add", params);
    if (response && response.response_code === 200) {
      dispatch(groupList());
    }
    return response;
  }
);
export const editGroup = createAsyncThunk(
  "lebel/edit",
  async (params, { dispatch }) => {
    let response = await postFormDataNew("label/edit", params);
    if (response && response.response_code === 200) {
      dispatch(groupList());
    }
    return response;
  }
);

export const deleteGroup = createAsyncThunk(
  "group/delete",
  async (object, { dispatch }) => {
    const { id, filters } = object;
    let response = await deleteData("label/delete", { id });
    if (response && response.response_code === 200) {
      dispatch(groupList(filters));
      return response;
    }
  }
);

export const groupDetails = createAsyncThunk(
  "label/id",
  async (object, { dispatch }) => {
    let response = await getData("label/detail", object);
    if (response && response.response_code === 200) {
      // dispatch(groupList());
      return response.data;
    }
  }
);

export const groupList = createAsyncThunk(
  "groupList",
  async (object, { dispatch }) => {
    let response = await getData("label/get", object);
    if (response && response.response_code === 200) {
      return response.data;
    }
  }
);

const GroupSlice = createSlice({
  name: "label",
  initialState,
  extraReducers: {
    [addGroup.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [editGroup.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [groupList.fulfilled]: (state, action) => {
      state.groupList = action.payload.labelGroups;
      state.isLoading = false;
    },
    [groupDetails.fulfilled]: (state, action) => {
      state.groupDetails = action.payload;
      state.isLoading = false;
    },
  },
});

export default GroupSlice.reducer;
