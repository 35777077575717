import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { groupDetails } from './groupSlice';

const GroupDetails = () => {

    const [selecboxarr, setselecboxarr] = useState([]);
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        dispatch(groupDetails({ id })).then(data => {
            console.log(data)
            setselecboxarr([{
                label: data.payload.labelGroup.label,
                option: data.payload.labelGroup.options
            }])
        })
    }, [])


    // console.log(selecboxarr)



    // console.log(selecboxarr)
    return (
        <article class="col-lg-9">
            <div class="article-body store-bd-gy">
                <div class="row align-items-center col-rever">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <h2 class="head-title">Group Details</h2>
                    </div>
                </div>

                <form class="form-style">
                    <div class="row">

                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="form-group p-2">
                                <label>SelectBox Attachments</label>
                                <hr />
                                <div class="">

                                    <label className="m-0 mt-2 mb-2">SelectBox  Attachments</label>
                                    <div className="selectbox-wrapper">
                                        {
                                            selecboxarr && selecboxarr.length > 0 ? selecboxarr.map((data, index) => {
                                                return (
                                                    <div class="position-relative ">
                                                        {/* <img alt="" src={data.image} class="thumb-img" /> */}
                                                        <p>{data.label}</p>
                                                        <span style={{ fontWeight: 'bold' }}>Options :</span>
                                                        {
                                                            data.option.map(elem => {
                                                                return <div>{elem.value}</div>
                                                            })
                                                        }
                                                        {/* <Link to="#" onClick={() => handleRemove(index, "select")} class="remove-thum-img shadow"><img alt="" src="assets/images/reject.png" /></Link> */}
                                                    </div>
                                                )
                                            })
                                                :
                                                <div className="">No SelectBox Added</div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </form>

            </div>
        </article>
    )
}

export default GroupDetails;