import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteData, getData, postFormDataWithToken, putEncodedData } from "../../../services/httphandler";
const initialState = {
    isLoading: false,
    cityList: "",
    cityDetails: ""
}

export const city = state => state.city.cityList.data;
export const totalCity = state => state.city.cityList.total;

export const addCity = createAsyncThunk(
    'addCity',
    async (object, { dispatch }) => {
        let response = await postFormDataWithToken('state/add', object);
        if (response && response.response_code === 200) {
            dispatch(cityList());
            return response;
        }

    }
)

export const cityList = createAsyncThunk(
    'city/list',
    async (object, { dispatch }) => {
        let response = await getData('state/get', object);
        if (response && response.response_code === 200) {
            return response.data
        }
    }
)

export const updateCity = createAsyncThunk(
    'updateCity',
    async (object, { dispatch }) => {
        let response = await putEncodedData('state/update', object);
        if (response && response.response_code === 200) {
            dispatch(cityList());
            return response;
        }

    }
)

export const cityDetails = createAsyncThunk(
    'city/id',
    async (object, { dispatch }) => {
        let response = await getData('state/detail', object);
        if (response && response.response_code === 200) {
            // dispatch(categoryList());
            return response.data;
        }

    }
)

export const deleteCity = createAsyncThunk(
    'City/delete',
    async (object, { dispatch }) => {
        const { id } = object;
        let response = await deleteData('state/delete', { id });
        if (response && response.response_code === 200) {
            dispatch(cityList());
            return response;
        }

    }
)

const citySlice = createSlice({
    name: 'city',
    initialState,
    extraReducers: {
        [cityList.fulfilled]: (state, action) => {
            state.cityList = action.payload.states
            state.isLoading = false
        },
    }

})


export default citySlice.reducer;