import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GetCategoryReport, sales } from "./categoryReportSlice";
import Pagination from "react-js-pagination";

const CategoryReportList = () => {
  const dispatch = useDispatch();
  const History = useHistory();
  const [modal, setModal] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    search: "",
  });

  const data = useSelector(sales);

  useEffect(() => {
    dispatch(GetCategoryReport(filters));
  }, [dispatch, filters]);

  const handleChange = ({ name, e }) => {
    setFilters({
      ...filters,
      [name]: e.target.value,
    });
  };

  const handlePagination = (pageNum) => {
    setFilters({
      ...filters,
      page: pageNum,
    });
  };

  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row">
          <div class="col-sm-12">
            <div class="col-sm-12">
              <div class="row align-items-center col-rever">
                <div class="col-sm-12 col-md-8 col-lg-6 mb-3">
                  {/* <div class="link-active">
                            <img src="assets/images/Users-selected.png" alt="" />
                            <span>
                                <p>{total && total}</p>
                                <p>Total Products</p>
                            </span>
                        </div> */}
                </div>
                <div class="col-sm-12 col-md-4 col-lg-5 mb-3">
                  <div class="d-flex align-items-center lg-justify-content-end">
                    <form class="has-search">
                      <span class="form-search-icon form-control-feedback"></span>
                      <input
                        type="search"
                        class="form-control"
                        placeholder="Search  by category"
                        onChange={(e) =>
                          handleChange({
                            name: "search",
                            e,
                          })
                        }
                      />
                    </form>
                    {/* <Link to="manage-products/add" class="add-new" >+ Add New</Link> */}
                  </div>
                </div>
                <div className="col-lg-1 mb-3">
                  {" "}
                  <span
                    class="date-filter d-block"
                    onClick={() => {
                      window.open(
                        `http://3.132.76.65/bookyourgift-api/export/category?search=${filters.search}`,
                        "_blank"
                      );
                    }}
                    title="Date"
                  >
                    <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
              <hr />
            </div>
            <div class="col-sm-12">
              <div class="tab-content mt-4">
                <div id="inactive-product" class="tab-pane active">
                  <div class="table-responsive text-center">
                    <table class="table storetable">
                      <thead class="text-center">
                        <tr>
                          <th scope="col">product Category</th>
                          <th scope="col">Total Sold</th>
                          <th scope="col">Unit Left</th>
                        </tr>
                        <tr class="border-0 bg-none invisible">
                          <td class="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        {data && data?.data?.length > 0 ? (
                          data?.data?.map((dataElem) => {
                            return (
                              <tr key={dataElem.id}>
                                <td>{dataElem.product_category}</td>
                                <td> {dataElem.total_sold}</td>
                                <td>{dataElem.left_unit || "0"}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={7}>No Data Found.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {data?.total && data?.total > 10 ? (
                      <div>
                        <Pagination
                          activePage={filters.page}
                          itemsCountPerPage={filters.limit}
                          totalItemsCount={data?.total}
                          pageRangeDisplayed={5}
                          onChange={handlePagination}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};
export default CategoryReportList;
