import React, { useState, useEffect, forwardRef } from "react";
// import { uploadFile } from "react-s3";
import { toast } from "react-toastify";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import S3 from "react-aws-s3";
import { v4 as uuidv4 } from "uuid";
import { Buffer } from "buffer";

// @ts-ignore
window.Buffer = Buffer;
const Upload = forwardRef(function (
  { dataHandler, imager = "", multiple = false, multipleImager, text },
  ref
) {
  const [imge, setImge] = useState();
  const [time, settime] = useState(0);
  const [isActive, setisactive] = useState(false);

  useEffect(() => {
    var interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        settime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const [multipleImage, setMultipleImage] = useState([]);
  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    s3Url: `https://${process.env.REACT_APP_BUCKET_NAME}.s3.amazonaws.com` /* without the suffix zone added */,
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      console.log(percentCompleted, "percentCompleted");
    },
  };

  useEffect(() => {
    setMultipleImage(multipleImager || []);
  }, [multipleImager]);

  const handleClick = async (event) => {
    const ReactS3Client = new S3(config);
    const newFileName = uuidv4();
    setisactive(true);
    settime(0);
    if (event.target.multiple) {
      let files = event.target.files;
      if (
        Object.values(files).length <= 5 &&
        multipleImage.length + Object.values(files).length <= 5
      ) {
        let filePaths = await Promise.all(
          Object.values(files).map(async (element) => {
            return ReactS3Client.uploadFile(element, newFileName)
              .then(async (response) => {
                return response.location;
              })
              .catch((err) => {
                console.log(err, "Error");
              });
          })
        );

        setMultipleImage([...multipleImage, ...filePaths]);
        dataHandler(filePaths);
        settime(100);
        setisactive(false);
      } else {
        toast.error("*You can upload mamimux 5 image.");
      }
    } else
      ReactS3Client.uploadFile(event.target.files[0], newFileName)
        .then((data) => {
          dataHandler(data);
          settime(100);
          setisactive(false);
          setImge(data.location);
        })
        .catch((err) => {
          console.log(err, "error");
        });
  };

  const handleRemoveItems = (index) => {
    setMultipleImage(multipleImage.filter((item, i) => i !== index));
    dataHandler(
      multipleImage.filter((item, i) => i !== index),
      "remove"
    );
  };

  return (
    <>
      <div
        class="upload-btn-wrapper upload-subcate-img"
        hidden={ref ? true : false}
      >
        <div class="upload-btn-">
          <button class="btn">
            <img
              alt=""
              height="70%"
              width="100%"
              // onClick={() => {
              //     console.log(fileInput.current, "Current");
              // }}
              src={
                imager ? imager : imge ? imge : "assets/images/upload-img.png"
              }
            />
            <p>Upload {text || "Image"} here</p>
          </button>
          <div>
            <input
              style={{ marginBottom: "20px" }}
              type="file"
              name="myfile"
              height="100%"
              width="100%"
              onChange={handleClick}
              multiple={multiple}
              ref={ref}
            />
            {time > 0 && isActive && (
              <Progress style={{ marginTop: "10px" }} percent={time} />
            )}
          </div>
        </div>
      </div>
      <div class="product-thum-img">
        {multipleImage &&
          multipleImage.map((data, i) => {
            return (
              <span key={i} class="position-relative">
                <img src={data} class="thumb-img" alt="" />
                <button
                  type="button"
                  onClick={() => handleRemoveItems(i)}
                  href="#"
                  class="remove-thum-img"
                >
                  <img src="assets/images/reject.png" alt="" />
                </button>
              </span>
            );
          })}
      </div>
    </>
  );
});

export default Upload;
