import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import * as Yup from 'yup';
import FormInputText from '../../../components/FormInputText';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Upload from '../../../components/uploadFile.js'
import { addOccasion } from './occasionSlice';

const AddOccasion = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [image, setImage] = useState({
        imagePreview: '',
        image: ''
    })
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('*Occasion Name is Required'),
    });

    const {
        values,
        handleSubmit,
        touched,
        errors,
        handleChange,
    } = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema,
        onSubmit: async ({ name }) => {
            if (image && image.image) {
                let formdata = new FormData();
                formdata.append('image', image.image);
                formdata.append('name', name);
                dispatch(addOccasion(formdata, { dispatch })).then(data => {
                    if (data?.payload?.response_code === 200) {
                        history.push('/admin/occasion');
                        toast.success('Success');
                    }
                    else {
                        console.log(data, 'message')
                        toast.error(data?.payload?.message);
                    }
                })
            }
            else {
                toast.error('* Please add image.')
            }

        },
    });






    const dataHandler = (data) => {
        setImage({
            ...image,
            image: data.location
        })
    }
    return (
        <article class="col-lg-9">
            <div class="article-body store-bd-gy">
                <div class="row align-items-center col-rever">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <h2 class="head-title">Add New Occasion</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <form onSubmit={handleSubmit} class="form-style">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 text-center">


                                    <Upload dataHandler={dataHandler} />

                                </div>
                                <div class="col-sm-12 col-md-2 col-lg-2"></div>
                                <div class="col-sm-12 col-md-8 col-lg-8">


                                    <FormInputText
                                        placeholder="Enter Occasion Name"
                                        image="product.png"
                                        onChange={handleChange}
                                        name="name"
                                        type="text"
                                        value={values.name}
                                        title="Occasion Name"
                                    />


                                    <span className="errorMessage">
                                        {touched["name"] && errors["name"]}
                                    </span>

                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="text-center">
                                        <button type="submit" class="btn btn-save">Save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </article>
    )
}

export default AddOccasion;