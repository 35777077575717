import { Modal } from "reactstrap";
import React from 'react';

const SoldProducts = ({ data, text = "Sold Products" }) => {

    console.log(data, 'datats')
    const [modal, setModal] = React.useState(false);
    const toggle = () => setModal(!modal);
    return (
        <>
            <span style={{
                color: "blue",
                fontSize: "16px",
                cursor: 'pointer'
            }}
                onClick={toggle}
            >see all
            </span>
            <Modal className="modal-xl" isOpen={modal} toggle={toggle}>
                <div class="modal-content product-view-modal">
                    <div className="modal-header"
                        style={{
                            fontSize: '25px',
                            fontWeight: 'bold'
                        }}
                    >
                        {text}
                    </div>
                    <div class="modal-body text-capitalize">
                        <div class="table-responsive text-center">
                            <table class="table storetable table-sold">
                                <thead class="text-center">
                                    <tr>
                                        <th scope="col">Product</th>
                                        <th scope="col">Revenue</th>
                                        <th scope="col">Sold</th>
                                        <th scope="col">Discount</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    {data && data.length > 0 ? data.map((dataElem) => {

                                        return (
                                            <tr key={dataElem.id}>
                                                <td>{dataElem.name}</td>
                                                <td>
                                                    <i class="fa fa-inr" aria-hidden="true"></i> {dataElem.price}
                                                </td>
                                                <td>{dataElem.total_sold}</td>
                                                <td>{dataElem.discount + '%'}</td>

                                            </tr>
                                        )
                                    })

                                        :
                                        <tr>
                                            <td colSpan={4}>No Data Found.</td>
                                        </tr>
                                    }


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default SoldProducts;