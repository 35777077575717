import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { putEncodedData } from "../../../services/httphandler";
import { detailOrder, getOrder, orderDetails } from "./orderSlice";
import AddonModal from "../../../components/AddonModal";

const ViewOrder = () => {
  const [visible, setVisible] = useState(false);
  const [image, setImage] = useState("");
  const [addonData, setAddonData] = useState(null);
  const [toggleAddon, setToggleAddon] = useState(false);

  const History = useHistory();
  const dispatch = useDispatch();
  const [params, setparams] = useState({
    id: "",
    status: "",
    delivery_date: "",
  });
  const details = useSelector(detailOrder);
  const { id } = useParams();
  useEffect(() => {
    dispatch(orderDetails({ id })).then((data) => {
      if (data && data.payload) {
        setparams({
          id: data.payload.id,
          status: data.payload.status,
          delivery_date: data.payload.delivery_date,
        });
      }
    });
  }, [dispatch, id]);

  const handleFullScreen = (e, i) => {
    document.fullscreenEnabled =
      document.fullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.documentElement.webkitRequestFullScreen;

    function requestFullscreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    }

    if (document.fullscreenEnabled) {
      const element = document.getElementById(`${i}image`);

      requestFullscreen(element);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setparams({
      ...params,
      [name]: value,
    });
  };

  const handleChangeStatus = async (e) => {
    e.preventDefault();
    if (params.status) {
      let response = await putEncodedData("order/updateStatus", params);
      if (response && response.response_code === 200) {
        dispatch(getOrder());
        toast.success(response.message);
        History.push("/admin/manage-order");
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error("Please fill the status.");
    }
  };

  const addonHandler = (i, ans, imgs) => {
    setAddonData({
      answers: ans,
      images: imgs,
    });
    setToggleAddon(true);
  };
  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <h2 class="head-title">Order Detail</h2>
            <div class="user-id">
              <span>
                Customer ID:{" "}
                <span style={{ color: "#e50142" }}>
                  {details && details?.customer?.bog_id}
                </span>
              </span>
            </div>
          </div>
        </div>
        <form>
          <div class="row">
            <div class="col-sm-12">
              <div class="bg-white px-2 pb-4 pt-2 mt-4">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                    <div class="order-date-status">
                      <span class="position-relative pl-4">
                        <i
                          class="fa fa-calendar-o date-icon"
                          aria-hidden="true"
                        ></i>
                        <h5>{details && details?.created_at} </h5>
                        <p>Order ID: {details && details?.bog_order_id}</p>
                      </span>
                      <span class="position-relative pl-4">
                        <i
                          class="fa fa-calendar-o date-icon"
                          aria-hidden="true"
                        ></i>
                        <h5>delivery date</h5>
                        <input
                          onChange={handleChange}
                          name="delivery_date"
                          min={moment(new Date()).format("YYYY-MM-DD")}
                          value={params.delivery_date}
                          type="date"
                          class="mb-3"
                        />
                      </span>
                      <span>
                        <h5 class="mr-3">Status</h5>
                        <span>
                          <select
                            value={params.status}
                            onChange={handleChange}
                            name="status"
                            class="mb-3"
                          >
                            <option value="pending">Pending</option>
                            <option value="in progress">In Progress</option>
                            <option value="delivered">Delivered</option>
                            <option value="returned">Returned</option>
                            <option value="cancel">Cancel</option>
                          </select>
                        </span>
                      </span>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <hr />
                  </div>
                  <div class="col-sm-12 col-md-4 col-lg-4 mb-4">
                    <div class="Order-Summary">
                      <i class="fa fa-user fa-icon-bg" aria-hidden="true"></i>
                      <h5 class="mb-0">Customer</h5>
                      <p class="mb-0">
                        {details
                          ? details?.customer?.first_name +
                              " " +
                              details?.customer?.last_name || "N/A"
                          : "N/A"}
                      </p>
                      <p class="mb-0 text-lowercase">
                        {details && (details?.customer?.email || "N/A")}
                      </p>
                      <p class="mb-0">
                        {details && (details?.customer?.mobile_number || "N/A")}
                      </p>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-4 col-lg-4 mb-4">
                    <div class="Order-Summary">
                      <i class="fa fa-truck fa-icon-bg" aria-hidden="true"></i>
                      <h5 class="mb-0">shipping </h5>
                      <p class="mb-0">
                        address:{details && details?.order_address?.address}
                      </p>
                      <p class="mb-0">
                        city: {details && details?.order_address?.city}
                      </p>
                      <p class="mb-0">
                        state: {details && details?.order_address?.state}
                      </p>
                      <p class="mb-0">
                        Country: {details && details?.order_address?.country}
                      </p>
                      <p class="mb-0">
                        Pincode: {details && details?.order_address?.zipcode}
                      </p>
                    </div>
                  </div>
                  {details?.status?.toLowerCase() === "delivered" && (
                    <div class="col-sm-12 col-md-4 col-lg-4 mb-4">
                      <div class="Order-Summary">
                        <i
                          class="fa fa-map-marker fa-icon-bg"
                          aria-hidden="true"
                        ></i>
                        <h5 class="mb-0">delivery </h5>
                        <p class="mb-0">
                          address:{details && details?.order_address?.address}
                        </p>
                        <p class="mb-0">
                          city: {details && details?.order_address?.city}
                        </p>
                        <p class="mb-0">
                          state: {details && details?.order_address?.state}
                        </p>
                        <p class="mb-0">
                          Country: {details && details?.order_address?.country}
                        </p>
                        <p class="mb-0">
                          Pincode: {details && details?.order_address?.zipcode}
                        </p>
                      </div>
                    </div>
                  )}
                  <div class="col-lg-12">
                    <div class="checkout-style-1 shadow">
                      <div class="table-responsive text-center">
                        <table class="table order-table">
                          <thead>
                            <tr>
                              <th>SKU_ID</th>
                              <th>Image</th>
                              <th>Product Name</th>
                              <th>Quantity</th>
                              <th>Attachments</th>
                              <th>Price</th>
                              <th>Addons</th>
                              {/* <th>action</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {details &&
                              details?.order_product &&
                              details?.order_product?.map((data, i) => {
                                return (
                                  <tr key={data.id}>
                                    <td>{data?.product_sku_id || "-"}</td>
                                    <td>
                                      {/* <FullScreen handle={handle}>
                                        <img
                                          src={data?.images?.[0]?.name}
                                          alt="Product"
                                          class={
                                            handle.active
                                              ? "fullScreenImage"
                                              : "table-img cursor-pointer"
                                          }
                                          onClick={handle.enter}
                                          // onClick={() => {
                                          //   console.log("clicked");
                                          //   setVisible(true);
                                          //   setImage(data?.images?.[0]?.name);
                                          // }}
                                        />
                                      </FullScreen> */}
                                      <img
                                        src={data?.images?.[0]?.name}
                                        alt="Product"
                                        id={`${i}image`}
                                        class={"table-img cursor-pointer"}
                                        onClick={(e) => {
                                          handleFullScreen(e, i);
                                        }}
                                      />
                                    </td>
                                    <td>{data?.product_name}</td>
                                    <td>{data?.quantity}</td>
                                    <td>
                                      {data?.orderProductAnswers?.length > 0
                                        ? data?.orderProductAnswers?.map(
                                            (data) => {
                                              return (
                                                <div>
                                                  <label
                                                    style={{
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {data?.product_label
                                                      ?.label || "N/A"}
                                                  </label>

                                                  <span>: {data?.answer}</span>
                                                </div>
                                              );
                                            }
                                          )
                                        : "N/A"}
                                    </td>
                                    <td>
                                      <i
                                        class="fa fa-inr"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {data?.product_price}
                                    </td>
                                    <td>
                                      <span
                                        className={
                                          data?.orderProductAnswers?.length >
                                            0 ||
                                          data?.orderProductAddonImages
                                            ?.length > 0
                                            ? "text-danger cursor-pointer"
                                            : "cursor-pointer"
                                        }
                                        onClick={() => {
                                          addonHandler(
                                            i,
                                            data?.orderProductAnswers,
                                            data?.orderProductAddonImages
                                          );
                                        }}
                                      >
                                        {data?.orderProductAnswers?.length >
                                          0 ||
                                        data?.orderProductAddonImages?.length >
                                          0
                                          ? "View Addons"
                                          : "NA"}
                                      </span>
                                    </td>
                                    {/* <td>
                                                                            <div class="d-flex justify-content-center action">
                                                                                <button class="btn"><a href="#admin"><img alt="" src="assets/images/delete.png" /></a></button>
                                                                            </div>
                                                                        </td> */}
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                      <div class="checkout-coupon-total p-2 checkout-coupon-total-2 d-lg-flex justify-content-between">
                        <div class="checkout-coupon"></div>
                        <div class="checkout-total pl-2">
                          <div class="single-total d-flex justify-content-between">
                            <p class="value">Subtotal Price:</p>
                            <p class="price">
                              <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                              {details &&
                                details?.total_discount +
                                  details?.total_payable}
                            </p>
                          </div>
                          {details && details?.cod_price && (
                            <div class="single-total d-flex justify-content-between shipping">
                              <p class="value">COD Charges (+):</p>
                              <p class="price">
                                <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                                {details && details?.cod_price}
                              </p>
                            </div>
                          )}
                          {details && details?.shipping_price > 0 && (
                            <div class="single-total d-flex justify-content-between shipping">
                              <p class="value">Shipping Charges (+):</p>
                              <p class="price">
                                <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                                {details && details?.shipping_price}
                              </p>
                            </div>
                          )}
                          <div class="single-total d-flex justify-content-between discount">
                            <p class="value">Discount (-):</p>
                            <p class="price">
                              <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                              {details && details?.total_discount}
                            </p>
                          </div>
                          <div class="single-total total-payable d-flex justify-content-between">
                            <p class="value">Total Payable:</p>
                            <p class="price">
                              <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                              {details && details?.total_payable}
                            </p>
                          </div>
                          <div class="payment-mode">
                            Payment: {details && details?.payment_mode}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="view-btns mt-5">
                <button class="btn btn-save" onClick={handleChangeStatus}>
                  Update
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <AddonModal
        data={addonData}
        show={toggleAddon}
        handleShow={(val) => {
          if (val === "close") {
            setAddonData(null);
          }
          setToggleAddon(!toggleAddon);
        }}
      />
    </article>
  );
};

export default ViewOrder;
