import { Modal } from "reactstrap";
import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { subCategoryDetails } from "../../views/folder/ManageCategory/subCategorySlice";

const SubCategoryViewModal = ({ id }) => {
    const [modal, setModal] = React.useState(false);
    const dispatch = useDispatch();
    const [state, setstate] = useState({
        id: '',
        image: '',
        sub_category: '',
        category: {}
    })


    useEffect(() => {
        dispatch(subCategoryDetails({ id })).then(data => {
            setstate({
                id: data.payload.sub_category?.id,
                image: data.payload.sub_category?.image,
                sub_category: data.payload.sub_category.name,
                category: data.payload.sub_category.category || {}
            })
        })
    }, [dispatch, id]);

    // Toggle for Modal
    const toggle = () => setModal(!modal);
    return (
        <>
            <button class="btn" onClick={toggle}><img alt="" src="assets/images/view.png" /></button>
            <Modal isOpen={modal} toggle={toggle}>
                <div class="modal-content">
                    <div class="modal-body text-capitalize">
                        <button onClick={toggle} type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="text-center mt-2">
                            <img alt="" src="assets/images/small-cate.png" />
                            <p class="mb-0">Sub-Category</p>
                            <h5>{state.sub_category}</h5>
                            <div class="sub-cate-img">
                                <img alt="" src={state.image && state.image || "assets/images/employe.png"} class="img-fluid" />
                            </div>
                        </div>
                        <hr />
                        <div class="text-center mt-2">
                            <img alt="" src="assets/images/small-cate.png" />
                            <p class="mb-0">Category</p>
                            <h5>{state.category && state.category?.name}</h5>
                            <div class="cate-img">
                                <img alt="" src={state.category && state.category.image || "assets/images/employe.png"} class="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default SubCategoryViewModal;