import React from "react";
import "./grid.css";
import { Link } from "react-router-dom";

function GridCard({ data, type }) {
  return (
    <div class="product-style-1 home-page-img position-relative h-100">
      <span class="badge-topright text-center forDesktop">
        <span style={{ textDecoration: "line-through" }}>
          <i class="fa fa-inr" aria-hidden="true"></i> {data?.price}{" "}
        </span>{" "}
        <span style={{ color: "red" }}>
          {" "}
          <i class="fa fa-inr" aria-hidden="true"></i>{" "}
          {(data?.price * data?.discount) / 100}
        </span>
        <p class="discountBadge">
          {data?.discount ? `${data?.discount} off` : ""}
        </p>
      </span>
      <div class="product-image" style={{ overflow: "hidden" }}>
        <div class="product-active">
          <img
            src={
              data?.images?.[0]?.name?.includes("http")
                ? data?.images?.[0]?.name
                : "assets/images/upload-img.png"
            }
            alt="productImage"
            class="cardImg  "
          />
        </div>
      </div>
      <div class="product-content text-center">
        {/* <p class="wishlist ">
          <i class="fa fa-heart-o"></i> Wishlist
        </p> */}
        <h4 class="title cardTitle mb-0">
          <Link
            class="text-primary mb-0"
            to={`/admin/manage-products/details/${data?.id}`}
          >
            {data?.name}
          </Link>
        </h4>
        <p></p>
        {type !== "recent" && (
          <p className="text-primary">Unit Sold: {data?.total_purchase || 0}</p>
        )}
        <p class=" text-center forMobile">
          <span style={{ textDecoration: "line-through" }}>
            <i class="fa fa-inr" aria-hidden="true"></i>{" "}
            {(data?.price * data?.discount) / 100}
          </span>{" "}
          <span style={{ color: "red" }}>
            {" "}
            <i class="fa fa-inr" aria-hidden="true"></i> {data?.discount}
          </span>{" "}
          <span class="discountBadge">
            {data?.discount ? `${data?.discount} off` : ""}
          </span>
        </p>
        {/* <p class="text-capitalize text-white">
          <button class="btn primary-btn">Add to cart</button>
        </p> */}
      </div>
    </div>
  );
}

export default GridCard;
