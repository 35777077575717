import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteData,
  getData,
  postFormDataWithToken,
  putEncodedData,
} from "../../../services/httphandler";

const initialState = {
  isLoading: false,
  subCategoryList: {},
  subCategoryDetails: "",
};

export const addSubCategory = createAsyncThunk(
  "addCategory",
  async (object, { dispatch }) => {
    let response = await postFormDataWithToken("subcategory/add", object);
    if (response && response.response_code === 200) {
      dispatch(subCategoryList());
      return response;
    }
  }
);

export const updateSubCategory = createAsyncThunk(
  "updateSubCategory",
  async (object, { dispatch }) => {
    let response = await putEncodedData("subcategory/update", object);
    if (response && response.response_code === 200) {
      return response;
    }
  }
);

export const toggleSubCategory = createAsyncThunk(
  "toggleSubCategory",
  async (object, { dispatch }) => {
    let response = await putEncodedData("subcategory/toggle", object);
    if (response && response.response_code === 200) {
      return response;
    }
  }
);

export const deleteSubCategory = createAsyncThunk(
  "category/delete",
  async (object, { dispatch }) => {
    let response = await deleteData("subcategory/delete", object);
    if (response && response.response_code === 200) {
      dispatch(subCategoryList());
      return response;
    }
  }
);

export const subCategoryDetails = createAsyncThunk(
  "category/id",
  async (object, { dispatch }) => {
    let response = await getData("subcategory/detail", object);
    if (response && response.response_code === 200) {
      // dispatch(subCategoryList());
      return response.data;
    }
  }
);

export const subCategoryList = createAsyncThunk(
  "subCategoryList",
  async (object, { dispatch }) => {
    let response = await getData("subcategory/get", object);
    if (response && response.response_code === 200) {
      return response;
    }
  }
);

const subCategorySlice = createSlice({
  name: "subCategory",
  initialState,
  extraReducers: {
    [addSubCategory.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [subCategoryList.fulfilled]: (state, action) => {
      state.subCategoryList = action?.payload?.data?.sub_category;
      state.isLoading = false;
    },
    [subCategoryDetails.fulfilled]: (state, action) => {
      state.subCategoryDetails = action?.payload;
      state.isLoading = false;
    },
  },
});

export default subCategorySlice.reducer;
