import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import FormInputText from '../../../components/FormInputText';
import Upload from '../../../components/uploadFile.js'
import { addBanner } from "./bannerSlice";

const AddBanner = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    // const [state, setstate] = useState([])
    const [image, setImage] = useState({
        desktop: '',
    })

    const [mobileImage, setMobileImage] = useState({
        mobile: '',
    })
    const [ipadImage, setIpadImage] = useState({
        ipad: ''
    })

    const [isSubmitting, setIssubmitting] = useState(false);

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('*Title is Required'),
        from_date: Yup.string().required('*From Date is Required'),
        to_date: Yup.string().required('*To Date is Required'),
    });



    const { values, handleSubmit, touched, errors, handleChange, } = useFormik({
        initialValues: {
            from_date: "",
            to_date: "",
            title: "",
        },
        validationSchema,
        onSubmit: async ({ title, from_date, to_date }) => {
            if (image.desktop && ipadImage.ipad && mobileImage.mobile) {
                setIssubmitting(true);
                let formData = new FormData();
                formData.append('title', title);
                formData.append('from_date', from_date);
                formData.append('to_date', to_date);
                formData.append('images', image.desktop);
                formData.append('ipad_image', ipadImage.ipad);
                formData.append('mobile_image', mobileImage.mobile);
                dispatch(addBanner(formData)).then(data => {
                    if (data?.payload?.response_code === 200) {
                        history.push('/admin/banner');
                        toast.success('Success');
                    }
                    else {
                        toast.error(data?.payload?.message);
                        setIssubmitting(false)
                    }
                })
            }
            else {
                toast.error('*Please upload images.');
                setIssubmitting(false)
            }

        },
    })



    const dataHandler = (data, key) => {
        setImage({
            ...image,
            desktop: data.location
        })
    }
    const dataHandlerMobile = (data, key) => {
        setMobileImage({
            ...mobileImage,
            mobile: data.location
        })
    }
    const dataHandlerIpad = (data, key) => {
        setIpadImage({
            ...ipadImage,
            ipad: data.location
        })
    }


    return (

        <article class="col-lg-9">
            <div class="article-body store-bd-gy">
                <div class="row align-items-center col-rever">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <h2 class="head-title">Add New Banner</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <form onSubmit={handleSubmit} class="form-style">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 text-center multiple-banner">
                                    <Upload text="Image" dataHandler={(data) => dataHandler(data, 'desktop')} />
                                    <Upload text="mobile image" dataHandler={(data) => dataHandlerMobile(data, 'mobile')} />
                                    <Upload text="ipad image" dataHandler={(data) => dataHandlerIpad(data, 'ipad')} />
                                </div>
                                <div class="col-sm-12">

                                    <FormInputText
                                        placeholder="Enter Banner Title"
                                        type="text"
                                        image="product.png"
                                        onChange={handleChange}
                                        name="title"
                                        value={values.title}
                                        title="Title"
                                    />

                                    <span className="errorMessage">
                                        {touched["title"] && errors["title"]}
                                    </span>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-6">


                                    <FormInputText
                                        placeholder="Enter Price"
                                        type="date"
                                        image="product.png"
                                        onChange={handleChange}
                                        name="from_date"
                                        value={values.from_date}
                                        title="From"
                                    />

                                    <span className="errorMessage">
                                        {touched["from_date"] && errors["from_date"]}
                                    </span>
                                </div>


                                <div class="col-sm-6 col-md-6 col-lg-6">


                                    <FormInputText
                                        placeholder="date"
                                        type="date"
                                        image="small-cate.png"
                                        min={values.from_date}
                                        onChange={handleChange}
                                        name="to_date"
                                        value={values.to_date}
                                        title="To"
                                    />

                                    <span className="errorMessage">
                                        {touched["to_date"] && errors["to_date"]}
                                    </span>
                                </div>



                                <div class="col-sm-12 col-md-12 col-lg-12 mt-5">
                                    <div class="text-center">
                                        <button disabled={isSubmitting} type="submit" class="btn btn-save">Save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </article>
    )
}

export default AddBanner;