import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteData, getData, postFormDataWithToken, putEncodedData } from "../../../services/httphandler";

const initialState = {
    isLoading: false,
    relationList: {},
    relationDetails: "",
}

export const relations = state => state.relation.relationList?.data;
export const totalRelation = state => state.relation.relationList?.total;


export const addRelation = createAsyncThunk(
    'addRelation',
    async (object, { dispatch }) => {
        let response = await postFormDataWithToken('relation/add', object);
        if (response && response.response_code === 200) {
            dispatch(relationList());
        }

        return response;

    }
)

export const updateRelation = createAsyncThunk(
    'updateRelation',
    async (object, { dispatch }) => {
        let response = await putEncodedData('relation/update', object);
        if (response && response.response_code === 200) {
            dispatch(relationList());

        }
        return response;

    }
)


export const deleteRelation = createAsyncThunk(
    'relation/delete',
    async (object, { dispatch }) => {
        let response = await deleteData('relation/delete', object);
        if (response && response.response_code === 200) {
            dispatch(relationList());
            return response;
        }

    }
)

export const relationDetails = createAsyncThunk(
    'relation/id',
    async (object, { dispatch }) => {
        let response = await getData('relation/detail', object);
        if (response && response.response_code === 200) {
            return response.data;
        }

    }
)


export const relationList = createAsyncThunk(
    'relationList',
    async (object, { dispatch }) => {
        let response = await getData('relation/get', object);
        if (response && response.response_code === 200) {
            return response
        }
    }
)

const RelationSLice = createSlice({
    name: 'relation',
    initialState,
    extraReducers: {
        [relationList.fulfilled]: (state, action) => {
            state.relationList = action.payload?.data?.relations
            state.isLoading = false
        },
        [relationDetails.fulfilled]: (state, action) => {
            state.categoryDetails = action.payload
            state.isLoading = false
        }
    }
})


export default RelationSLice.reducer;


