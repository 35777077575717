import React from "react";

import { CSVLink, CSVDownload } from "react-csv";

function ExportToCsv({
  type = "button",
  data = [],
  label = "Export",
  fileName = "my-file.csv",
  className = "",
}) {
  if (type === "button") {
    return (
      <CSVLink data={data} filename={fileName} className={className}>
        {label}
      </CSVLink>
    ); // or
  }
  return (
    <>
      <CSVDownload data={data} target="_blank" />
    </>
  );
}

export default ExportToCsv;
