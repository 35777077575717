import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteData, getData, postFormDataWithToken, putEncodedData } from "../../../services/httphandler";

const initialState = {
    isLoading: false,
    bannerList: [],
    bannerDetails: '',
}

export const banners = state => state.banner.bannerList?.banners?.data;
export const totalBanners = state => state.banner.bannerList?.banners?.total;
export const addBanner = createAsyncThunk(
    'banner/add',
    async (params, { dispatch }) => {
        let response = await postFormDataWithToken('banner/add', params);
        if (response && response.response_code === 200) {
            dispatch(bannerList())
        }
        return response
    }
)

export const updateBanner = createAsyncThunk(
    'updateBanner',
    async (object, { dispatch }) => {
        let response = await putEncodedData('banner/update', object);
        if (response && response.response_code === 200) {
            dispatch(bannerList());
        }
        return response;


    }
)

export const toggleBanner = createAsyncThunk(
    'toggleBanner',
    async (object, { dispatch }) => {
        const { id, filters } = object;
        console.log(object, 'object')
        let response = await putEncodedData('banner/toggle', { id });
        if (response && response.response_code === 200) {
            dispatch(bannerList(filters));
            return response;
        }

    }
)


export const deleteBanner = createAsyncThunk(
    'Banner/delete',
    async (object, { dispatch }) => {
        const { id, filters } = object;
        let response = await deleteData('banner/delete', { id });
        if (response && response.response_code === 200) {
            dispatch(bannerList(filters));
        }

        return response;

    }
)

export const bannerDetails = createAsyncThunk(
    'banner/id',
    async (object, { dispatch }) => {
        let response = await getData('banner/detail', object);
        if (response && response.response_code === 200) {
            return response.data;
        }

    }
)


export const bannerList = createAsyncThunk(
    'bannerList',
    async (object, { dispatch }) => {
        let response = await getData('banner/get', object);
        if (response && response.response_code === 200) {
            return response.data
        }
    }
)


const bannerSlice = createSlice({
    name: 'banner',
    initialState,
    extraReducers: {
        // [addbanner.fulfilled]: (state, action) => {
        //     state.isLoading = false
        // },
        [bannerList.fulfilled]: (state, action) => {
            state.bannerList = action.payload
            state.isLoading = false
        },
        // [productDetails.fulfilled]: (state, action) => {
        //     state.productDetails = action.payload
        //     state.isLoading = false
        // }
    }

})

export default bannerSlice.reducer;