import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteData,
  getData,
  postFormDataWithToken,
  putEncodedData,
} from "../../../services/httphandler";

const initialState = {
  isLoading: false,
  productList: "",
  productDetails: "",
  filters: localStorage.getItem("filters")
    ? JSON.parse(localStorage.getItem("filters"))
    : {
        page: 1,
        limit: 10,
        active: 1,
        search: "",
        category: "",
        subCategory: "",
      },
};

export const addProduct = createAsyncThunk(
  "category/add",
  async (params, { dispatch }) => {
    let response = await postFormDataWithToken("product/add", params);
    if (response && response.response_code === 200) {
      dispatch(productList());
      return response;
    }
  }
);

export const updateProduct = createAsyncThunk(
  "updateProduct",
  async (object, { dispatch }) => {
    let response = await putEncodedData("product/update", object);
    if (response && response.response_code === 200) {
      dispatch(productList());
      return response;
    }
  }
);

export const toggleProduct = createAsyncThunk(
  "toggleProduct",
  async (object, { dispatch }) => {
    const { id, filters } = object;
    console.log(object, "object");
    let response = await putEncodedData("product/toggle", { id });
    if (response && response.response_code === 200) {
      dispatch(productList(filters));
      return response;
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "Product/delete",
  async (object, { dispatch }) => {
    const { id, filters } = object;
    let response = await deleteData("product/delete", { id });
    if (response && response.response_code === 200) {
      dispatch(productList(filters));
      return response;
    }
  }
);

export const productDetails = createAsyncThunk(
  "product/id",
  async (object, { dispatch }) => {
    let response = await getData("product/detail", object);
    if (response && response.response_code === 200) {
      // dispatch(productList());
      return response.data;
    }
  }
);

export const productList = createAsyncThunk(
  "productList",
  async (object, { dispatch }) => {
    let response = await getData("product/get", object);
    if (response && response.response_code === 200) {
      return response.data;
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    storeFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
  extraReducers: {
    [addProduct.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [productList.fulfilled]: (state, action) => {
      state.productList = action.payload.products;
      state.isLoading = false;
    },
    [productDetails.fulfilled]: (state, action) => {
      state.productDetails = action.payload;
      state.isLoading = false;
    },
  },
});
export const { storeFilters } = productSlice.actions;

export default productSlice.reducer;
