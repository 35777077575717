import { Redirect, Route, Switch } from "react-router"
import { authRoutes } from "../routes";

const BlankLayout = () => {
    return (
        <Switch>
            {
                authRoutes.map(prop => {
                    return (
                        <Route path={prop.path} component={prop.element} />
                    )
                })
            }
            <Redirect to="/auth/login" />
        </Switch>
    )
}

export default BlankLayout;