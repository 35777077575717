import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { cityDetails, updateCity } from './citySlice';
import Upload from '../../../components/uploadFile.js'



const EditCity = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [state, setstate] = useState({
        id: '',
        image: '',
        state: ''
    })

    const validationSchema = Yup.object().shape({
        state: Yup.string().required('*State Name is Required'),
    });

    const { values, handleSubmit, touched, errors, handleChange, } = useFormik(
        {
            initialValues: state,
            enableReinitialize: true,
            validationSchema,
            onSubmit: async () => {
                if (state && state.image) {
                    dispatch(updateCity(values, { dispatch })).then(data => {
                        if (data?.payload?.response_code === 200) {
                            history.push('/admin/delivery-cities');
                            toast.success('Success');
                        }
                        else {
                            toast.error(data?.payload?.message);
                        }
                    })
                }
                else {
                    toast.error('* Please add image.')
                }

            },
        });

    useEffect(() => {
        dispatch(cityDetails({ id })).then(data => {
            setstate({
                id: data.payload.state.id,
                image: data.payload.state.image,
                state: data.payload.state.state
            })
        })
    }, [dispatch, id]);

    const dataHandler = (data) => {
        setstate({
            ...state,
            image: data.location
        })
    }


    return (
        <article class="col-lg-9">
            <div class="article-body store-bd-gy">
                <div class="row align-items-center col-rever">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <h2 class="head-title">Edit State</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <form onSubmit={handleSubmit} class="form-style">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                                    {/* <div class="upload-btn-wrapper upload-subcate-img">
                                        <button class="btn"><img src="assets/images/upload-img.png" alt="" />
                                            <p>Upload Image here</p></button>
                                        <input type="file" name="myfile" />
                                    </div> */}

                                    <Upload dataHandler={dataHandler} imager={state.image} />
                                </div>
                                <div class="col-sm-12 col-md-2 col-lg-2"></div>
                                <div class="col-sm-12 col-md-8 col-lg-8">
                                    <div class="form-group">
                                        <label>State</label>
                                        <div class="input-container">
                                            <select
                                                onChange={handleChange}
                                                name="state"
                                                value={values.state}
                                                class="form-control pl-5"
                                            >
                                                <option value="">Select State</option>
                                                <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                <option value="Assam">Assam</option>
                                                <option value="Bihar">Bihar</option>
                                                <option value="Chandigarh">Chandigarh</option>
                                                <option value="Chhattisgarh">Chhattisgarh</option>
                                                <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                                                <option value="Daman and Diu">Daman and Diu</option>
                                                <option value="Delhi">Delhi</option>
                                                <option value="Lakshadweep">Lakshadweep</option>
                                                <option value="Puducherry">Puducherry</option>
                                                <option value="Goa">Goa</option>
                                                <option value="Gujarat">Gujarat</option>
                                                <option value="Haryana">Haryana</option>
                                                <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                                <option value="Jharkhand">Jharkhand</option>
                                                <option value="Karnataka">Karnataka</option>
                                                <option value="Kerala">Kerala</option>
                                                <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                <option value="Maharashtra">Maharashtra</option>
                                                <option value="Manipur">Manipur</option>
                                                <option value="Meghalaya">Meghalaya</option>
                                                <option value="Mizoram">Mizoram</option>
                                                <option value="Nagaland">Nagaland</option>
                                                <option value="Odisha">Odisha</option>
                                                <option value="Punjab">Punjab</option>
                                                <option value="Rajasthan">Rajasthan</option>
                                                <option value="Sikkim">Sikkim</option>
                                                <option value="Tamil Nadu">Tamil Nadu</option>
                                                <option value="Telangana">Telangana</option>
                                                <option value="Tripura">Tripura</option>
                                                <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                <option value="Uttarakhand">Uttarakhand</option>
                                                <option value="West Bengal">West Bengal</option>
                                            </select>
                                            <img src="assets/images/small-cate.png" class="input-img" alt="" />
                                        </div>
                                    </div>

                                    <span className="errorMessage">
                                        {touched["state"] && errors["state"]}
                                    </span>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="text-center">
                                        <button class="btn btn-save">Save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </article>
    )
}

export default EditCity;