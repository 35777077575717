import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteData, getData } from "../../../services/httphandler";

const initialState = {
  isLoading: false,
  list: [],
};

export const cartList = (state) => state.cart.list;

export const getCartListing = createAsyncThunk(
  "cartListing",
  async (object) => {
    let response = await getData("cart/get", object);
    console.log(response, "Response from art");
    return response.data;
  }
);

const CartListing = createSlice({
  name: "cartListing",
  initialState,
  extraReducers: {
    [getCartListing.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
    },
    [getCartListing.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
    },
  },
});

export default CartListing.reducer;
