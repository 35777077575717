import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteData,
  getData,
  postFormDataWithToken,
  putEncodedData,
} from "../../../services/httphandler";

const initialState = {
  isLoading: false,
  couponList: "",
  couponDetails: "",
};

export const addCoupon = createAsyncThunk(
  "coupon/add",
  async (params, { dispatch }) => {
    let response = await postFormDataWithToken("coupon/add", params);
    if (response && response.response_code === 200) {
      dispatch(couponList());
    }
    return response;
  }
);

export const updateCoupon = createAsyncThunk(
  "updateCoupon",
  async (object, { dispatch }) => {
    let response = await putEncodedData("coupon/update", object);
    if (response && response.response_code === 200) {
      dispatch(couponList());
    }
    return response;
  }
);

export const deleteCoupon = createAsyncThunk(
  "Coupon/delete",
  async (object, { dispatch }) => {
    const { id, filters } = object;
    let response = await deleteData("coupon/delete", { id });
    if (response && response.response_code === 200) {
      dispatch(couponList(filters));
      return response;
    }
  }
);

export const couponDetail = (state) => state.coupon.couponDetails;

export const couponDetails = createAsyncThunk(
  "coupon/id",
  async (object, { dispatch }) => {
    let response = await getData("coupon/detail", object);
    if (response && response.response_code === 200) {
      return response.data;
    }
  }
);

export const couponList = createAsyncThunk(
  "couponList",
  async (object, { dispatch }) => {
    let response = await getData("coupon/get", object);
    if (response && response.response_code === 200) {
      return response.data;
    }
  }
);

const couponSlice = createSlice({
  name: "coupon",
  initialState,
  extraReducers: {
    [couponList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.couponList = action.payload;
    },
    [couponDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.couponDetails = action.payload;
    },
  },
});

export default couponSlice.reducer;
