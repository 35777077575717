import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteData, getData } from "../../../services/httphandler";

const initialState = {
    isLoading: false,
    orderList: [],
    orderDetails: ''
}

export const orders = state => state.order.orderList?.orders?.data;
export const detailOrder = state => state.order.orderDetails;
export const totalOrders = state => state.order.orderList?.orders?.total;
export const cancel = state => state.order.orderList?.total_cancel;
export const delivered = state => state.order.orderList?.total_delivered;
export const pending = state => state.order.orderList?.total_pending;
export const progress = state => state.order.orderList?.total_progress;
export const returned = state => state.order.orderList?.total_returned;



export const getOrder = createAsyncThunk(
    'order',
    async (object = { page: 1, limit: 10 }) => {
        let response = await getData('order/get', object);
        return response.data;
    }
)

export const orderDetails = createAsyncThunk(
    'order/details',
    async (object) => {
        let response = await getData('order/detail', object);
        return response.data
    }
)

export const deleteOrder = createAsyncThunk(
    'order/delete',
    async (object, { dispatch }) => {
        const { id, filters } = object;
        let response = await deleteData('order/delete', { id });
        if (response && response.response_code === 200) {
            dispatch(getOrder(filters));
        }
        return response;

    }
)


const OrderSlice = createSlice({
    name: 'order',
    initialState,
    extraReducers: {
        [getOrder.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.orderList = action.payload;
        },
        [orderDetails.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.orderDetails = action.payload
        }
    }

})

export default OrderSlice.reducer;