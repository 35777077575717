import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData } from "../../../services/httphandler";


const initialState = {
    isLoader: false,
    salesList: []
}

export const sales = state => state.sales.salesList


export const GetSales = createAsyncThunk(
    'sales/get',
    async (object) => {
        let response = await getData('sales', object);
        return response.data
    }
)

export const SalesExport = createAsyncThunk(
    'sales/export',
    async (object) => {
        let response = await getData('export', object);
        return response.data
    }
)

const SalesSlice = createSlice({
    name: 'sales',
    initialState,
    extraReducers: {
        [GetSales.fulfilled]: (state, action) => {
            state.salesList = action.payload
        }
    }
})

export default SalesSlice.reducer;