import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getData, putEncodedData } from "../../../services/httphandler";
import { toast } from "react-toastify";
import { updateOtherOrderStatus } from "./OtherOrders";
import moment from "moment";
import Loader from "../../../components/loader";

function Detail() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const getDetails = useCallback(
    async (toLoad) => {
      if (toLoad) {
        setLoading(true);
      }
      try {
        let response = await getData("otherOrder/detail", { id });
        console.log("response: ", response);
        if (response && response.response_code === 200) {
          // dispatch(productList());
          //   return ;
          setDetails(response.data);
        }
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
      }
    },
    [id]
  );

  useEffect(() => {
    getDetails(true);
  }, [getDetails]);

  const handleChangeStatus = async () => {
    try {
      let response = await putEncodedData("otherOrder/updateStatus", {
        id,
      });
      if (response && response.response_code === 200) {
        getDetails(false);

        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {}
  };

  function getBrowserFullscreenElementProp() {
    if (typeof document.fullscreenElement !== "undefined") {
      return "fullscreenElement";
    } else if (typeof document.mozFullScreenElement !== "undefined") {
      return "mozFullScreenElement";
    } else if (typeof document.msFullscreenElement !== "undefined") {
      return "msFullscreenElement";
    } else if (typeof document.webkitFullscreenElement !== "undefined") {
      return "webkitFullscreenElement";
    } else {
      throw new Error("fullscreenElement is not supported by this browser");
    }
  }

  const makeFullScreen = (ind) => {
    let el = document.getElementById(`fullImage${ind}`);
    if (!document.fullscreenElement) {
      el.requestFullscreen();
      return;
    } else {
      toast.error("FullscreenElement is not supported by this browser");
    }
    document.exitFullscreen();
  };

  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <h2 class="head-title">Order Detail</h2>
            <div class="user-id">
              <span>
                Order ID:{" "}
                <span style={{ color: "#e50142" }}>
                  {details && details?.id}
                </span>
              </span>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="mt-5">
            <Loader />
          </div>
        ) : (
          <form>
            <div class="row">
              <div class="col-sm-12">
                <div class="bg-white px-2 pb-4 pt-2 mt-4">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                      <div class="order-date-status">
                        <span class="position-relative pl-4">
                          <i
                            class="fa fa-calendar-o date-icon"
                            aria-hidden="true"
                          ></i>
                          <h5>
                            {details &&
                              moment(details?.created_at).format(
                                "DD MMM YYYY, hh:mm a"
                              )}{" "}
                          </h5>
                          {/* <p>Order ID: {details && details?.id}</p> */}
                        </span>

                        <span>
                          <h5 class="mr-3">Status</h5>
                          <span
                            style={{
                              color:
                                details?.status === 1
                                  ? "red"
                                  : details?.status === 2
                                  ? "green"
                                  : "",
                            }}
                          >
                            {details?.status === 1
                              ? "Pending"
                              : details?.status === 2
                              ? "Completed"
                              : ""}
                          </span>
                        </span>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <hr />
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 mb-4">
                      <div class="Order-Summary">
                        <i class="fa fa-user fa-icon-bg" aria-hidden="true"></i>
                        <h5 class="mb-0">Address</h5>
                        {/* <p class="mb-0">{details?.name}</p>
                        <p class="mb-0">
                          {details && (details?.mobile_number || "")}
                        </p> */}
                        <p class="mb-0">
                          {details && (details?.address || "N/A")}
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 mb-4">
                      <div class="Order-Summary">
                        <i
                          class="fa fa-truck fa-icon-bg"
                          aria-hidden="true"
                        ></i>
                        <h5 class="mb-0">Payment Mode </h5>
                        <p class="mb-0">
                          {details?.payment_mode === 2
                            ? "COD"
                            : details?.payment_mode === 1
                            ? "Online Payment"
                            : "NA"}
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 mb-4">
                      <div class="Order-Summary">
                        <i
                          class="fa fa-mars-double fa-icon-bg"
                          aria-hidden="true"
                        ></i>
                        <h5 class="mb-0">Category Name </h5>
                        <p class="mb-0">{details?.category_name || "NA"}</p>
                      </div>
                    </div>

                    {details?.category_name === "wallet" && (
                      <>
                        {" "}
                        <div className="col-sm-12">
                          <hr />
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                          {details?.category_name === "wallet" && (
                            <div class="Order-Summary">
                              <i
                                class="fa fa-caret-right fa-icon-bg"
                                aria-hidden="true"
                              ></i>
                              <div className="pl-2">
                                <h5>Name on Wallet</h5>
                                <p>{details?.name_on_wallet}</p>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                          {details?.category_name === "wallet" && (
                            <div class="Order-Summary">
                              <i
                                class="fa fa-caret-right fa-icon-bg"
                                aria-hidden="true"
                              ></i>
                              <div className="pl-2">
                                <h5>Items</h5>
                                <p>{details?.items}</p>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                          {details?.category_name === "wallet" && (
                            <div class="Order-Summary">
                              <i
                                class="fa fa-caret-right fa-icon-bg"
                                aria-hidden="true"
                              ></i>
                              <div className="pl-2">
                                <h5>Charms</h5>
                                <p>{details?.charms}</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}

                    {details?.payment_mode === 2 && (
                      <>
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <hr />
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                          <div class="Order-Summary">
                            <i
                              class="fa fa-caret-right fa-icon-bg"
                              aria-hidden="true"
                            ></i>
                            <div className="pl-2">
                              <h5>Advance</h5>
                              <p>
                                {details?.advanced
                                  ? `${details?.advanced}`
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                          <div class="Order-Summary">
                            <i
                              class="fa fa-caret-right fa-icon-bg"
                              aria-hidden="true"
                            ></i>
                            <div className="pl-2">
                              <h5>Cod Amount</h5>
                              <p>
                                {details?.cod_amount
                                  ? `${details?.cod_amount}`
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {details?.payment_mode === 1 && (
                      <>
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <hr />
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                          <div class="Order-Summary">
                            <i
                              class="fa fa-caret-right fa-icon-bg"
                              aria-hidden="true"
                            ></i>
                            <div className="pl-2">
                              <h5>Online Amount</h5>
                              <p>
                                {details?.online_amount
                                  ? `${details?.online_amount}`
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <hr />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div class="Order-Summary">
                        <div className="pl-2">
                          <h5>Images</h5>
                          <div class="product-thum-img">
                            {details?.images?.length > 0 ? (
                              details?.images.map((data, i) => {
                                return (
                                  <span key={i} class="position-relative">
                                    <img
                                      src={data?.name}
                                      class="thumb-img"
                                      onClick={() => {
                                        makeFullScreen(i);
                                      }}
                                      id={`fullImage${i}`}
                                      alt=""
                                    />
                                  </span>
                                );
                              })
                            ) : (
                              <div>No images added</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {details?.status === 1 && (
                <div class="col-sm-12">
                  <div class="view-btns mt-5">
                    <button class="btn btn-save" onClick={handleChangeStatus}>
                      Update
                    </button>
                  </div>
                </div>
              )}
            </div>
          </form>
        )}
      </div>
    </article>
  );
}

export default Detail;
