import { useFormik } from "formik";
import React, { useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import Upload from "../../../components/uploadFile.js";
import CreatableSelect from "react-select/creatable";
import ImageResize from "quill-image-resize-module-react";
import S3 from "react-aws-s3";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { v4 as uuidv4 } from "uuid";

Quill.register("modules/imageResize", ImageResize);

var Size = Quill.import("attributors/style/size");
Size.whitelist = [
  "10px",
  "11px",
  "12px",
  "13px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
];
Quill.register(Size, true);

function AddEditBlog() {
  const cateArr = [
    { name: "android", checked: false, child: [] },
    { name: "apple", checked: false, child: [] },
    { name: "chatGPT", checked: false, child: [] },
    {
      name: "digital marketing",
      checked: false,
      child: [
        { name: "email", checked: false },
        { name: "social", checked: false },
      ],
    },
    { name: "gaming", checked: false, child: [] },
    {
      name: "seo",
      checked: false,
      child: [
        { name: "seo", checked: false },
        { name: "seo 1", checked: false },
      ],
    },
    { name: "android", checked: false, child: [] },
    { name: "apple", checked: false, child: [] },
    { name: "chatGPT", checked: false, child: [] },
    {
      name: "digital marketing",
      checked: false,
      child: [],
    },
    { name: "gaming", checked: false, child: [] },
    { name: "seo", checked: false, child: [{ name: "seo", checked: false }] },
  ];

  const [loading, setLoading] = useState(false);
  const [CreateCategory, setCreateCategory] = useState(false);

  const initialValues = {
    CategoryName: "",
    ParentCategory: "",

    title: "",
    image: "",
    category: cateArr,
    content: "",
    tages: [],

    seoTitle: "",
    slug: "",
    metaDescription: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      title: Yup.string().required("Required"),
      category: Yup.string().required("Required"),
      // startDate: Yup.string().required("Required"),
      // endDate: Yup.string().required("Required"),
      // startTime: Yup.string().required("Required"),
      // endTime: Yup.string().required("Required"),
    }),
    enableReinitialize: true,
    onSubmit: (values, { isSubmitting, resetForm }) => {
      setLoading(true);
      const payload = {
        image: values.bannerImage,
        package_name: values.title,
        destinations: values.destinations?.map((item) => {
          return item?.label;
        }),

        // console.log(EditPayload, "jbhgfv vdjkjbvdusj");

        // if (id) {
        //   EditPackageApi(EditPayload).then((data) => {
        //     setLoading(false);
        //     if (data?.code === 1) {
        //       toast.success(data.message);
        //       push("/package");
        //     } else {
        //       setLoading(false);
        //       toast.error(data.message);
        //     }
        //   });
        // } else {
        //   AddPackageApi(payload).then((data) => {
        //     setLoading(false);
        //     if (data?.code === 1) {
        //       toast.success(data.message);
        //       push("/package");
        //     } else {
        //       setLoading(false);
        //       toast.error(data.message);
        //     }
        //   });
      };
    },
  });

  const dataHandler = (data) => {
    formik.setFieldValue("image", data.location);
    // console.log(data.location, "data.location");
  };

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    s3Url: `https://${process.env.REACT_APP_BUCKET_NAME}.s3.amazonaws.com` /* without the suffix zone added */,
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      console.log(percentCompleted);
    },
  };

  const quillRef = useRef();

  const QID = uuidv4();

  const ReactS3Client = new S3(config);

  const newFileName = QID;

  const ImageUpload = (formData) => {
    var url = ReactS3Client.uploadFile(formData, newFileName).then((data) => {
      return data.location;
    });
    return url;
  };

  const imageHandler = (e) => {
    const editor = quillRef.current.getEditor();
    // console.log(editor);
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        // console.log(file);

        const res = await ImageUpload(input.files[0]); // upload data into server or aws or cloudinary

        // console.log(res, "res");
        editor.insertEmbed(editor.getSelection(), "image", res);
      }
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],

          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["image", "link"],
          // ["blockquote", "code-block"],
          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ size: Size.whitelist }],
          [{ align: [] }],
        ],

        handlers: {
          image: imageHandler,
        },
      },
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
      },
    }),
    []
  );

  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <h2 class="head-title">Add New Blog</h2>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-sm-12 col-md-10 col-lg-8">
            <form onSubmit={formik.handleSubmit} class="form-style">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                  <Upload
                    text="Image"
                    dataHandler={(data) => dataHandler(data)}
                  />

                  {/* {formik.errors.title && formik.touched.title && (
                    <div className="text-danger"> {formik.errors.title}</div>
                  )} */}
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div class="form-group">
                    <label>Title</label>
                    <div class="input-container">
                      <input
                        type="text"
                        placeholder="Title"
                        className="form-control pl-3"
                        {...formik.getFieldProps("title")}
                      />
                    </div>
                    {formik.errors.title && formik.touched.title && (
                      <div className="text-danger"> {formik.errors.title}</div>
                    )}
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div class="form-group">
                    <label>All Category</label>
                    <div class="input-container">
                      <div className="AllCategoryListOver">
                        {formik.values.category?.map((item, i) => (
                          <div key={i}>
                            <label>
                              <input
                                type="checkbox"
                                value={item?.checked}
                                checked={item?.checked}
                                name="category"
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    `category.${i}.checked`,
                                    e.target.checked
                                  );
                                }}
                              />{" "}
                              <span>{item?.name}</span>
                            </label>
                            <div className="CategoryListChild">
                              {item?.child?.length > 0 &&
                                item?.child?.map((cld, ind) => (
                                  <label key={ind}>
                                    <input
                                      type="checkbox"
                                      checked={cld?.checked}
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          `category.${i}.child.${ind}.checked`,
                                          e.target.checked
                                        );
                                      }}
                                    />{" "}
                                    <span>{cld?.name}</span>
                                  </label>
                                ))}
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* <select
                        className="form-control form-select"
                        {...formik.getFieldProps("category")}
                      >
                        <option value={""}>Category</option>
                      </select> */}
                    </div>

                    {formik.errors.category && formik.touched.category && (
                      <div className="text-danger">
                        {formik.errors.category}
                      </div>
                    )}
                  </div>
                  <div className="text-right">
                    <span
                      className="text-danger"
                      role="button"
                      onClick={() => {
                        setCreateCategory(!CreateCategory);
                      }}
                    >
                      + Add New Category
                    </span>
                  </div>
                </div>
                {CreateCategory && (
                  <div className="border w-100 p-2 mb-4 bg-white shadow rounded-lg">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="form-group">
                        <label>New Category</label>
                        <div class="input-container">
                          <input
                            className="form-control"
                            placeholder="New Category"
                            {...formik.getFieldProps("CategoryName")}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                      <div class="form-group">
                        <label>Parent Category</label>
                        <div class="input-container">
                          <select
                            className="form-control form-select"
                            {...formik.getFieldProps("parentCategory")}
                          >
                            <option value={""}>Parent Category</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                      <span class="btn btn-save fsbtn">Save</span>
                    </div>
                  </div>
                )}
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label>Description</label>
                    <div class="input-container">
                      <ReactQuill
                        theme="snow"
                        value={formik.values.content}
                        name="content"
                        onChange={(content, delta, source, editor) => {
                          // console.log(editor.getHTML(), "content");
                          formik.setFieldValue("content", content);
                        }}
                        ref={quillRef}
                        modules={modules}
                        style={{
                          minHeight: "50vh",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group blog">
                    <label>Tages</label>
                    <div class="input-container">
                      <CreatableSelect
                        isMulti
                        onChange={(newValue) => {
                          formik.setFieldValue("tages", newValue);
                        }}
                        placeholder="Type Tage and press enter..."
                        value={formik.values.tages}
                      />
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12">
                  <h3>For SEO</h3>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div class="form-group">
                    <label>SEO Title</label>
                    <div class="input-container">
                      <input
                        type="text"
                        placeholder="Title"
                        className="form-control pl-3"
                        {...formik.getFieldProps("seoTitle")}
                      />
                    </div>
                    {formik.errors.seoTitle && formik.touched.seoTitle && (
                      <div className="text-danger">
                        {" "}
                        {formik.errors.seoTitle}
                      </div>
                    )}
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div class="form-group">
                    <label>Slug</label>
                    <div class="input-container">
                      <input
                        type="text"
                        placeholder="Slug"
                        className="form-control pl-3"
                        {...formik.getFieldProps("slug")}
                      />
                    </div>
                    {formik.errors.slug && formik.touched.slug && (
                      <div className="text-danger"> {formik.errors.slug}</div>
                    )}
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div class="form-group">
                    <label>Meta Description</label>
                    <div class="input-container">
                      <textarea
                        type="text"
                        placeholder="Meta Description"
                        rows={5}
                        className="textarea-control pl-3"
                        {...formik.getFieldProps("metaDescription")}
                      ></textarea>
                    </div>
                    {formik.errors.metaDescription &&
                      formik.touched.metaDescription && (
                        <div className="text-danger">
                          {" "}
                          {formik.errors.metaDescription}
                        </div>
                      )}
                  </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <button type="submit" class="btn btn-save">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </article>
  );
}

export default AddEditBlog;
