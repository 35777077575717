import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  otherOrderList,
  otherOrderLists,
  updateOtherOrderStatus,
} from "./OtherOrders";
import Loader from "../../../components/loader";
import Pagination from "react-js-pagination";
import ExportToCsv from "../../../components/exportToCSV/ExportToCsv";
import { toast } from "react-toastify";

export default function List() {
  const dispatch = useDispatch();
  const [category, setCategory] = useState("wallet");
  const list = useSelector(otherOrderLists);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("1");
  const [page, setPage] = useState(1);
  const [dataToExport, setDataToExport] = useState([]);
  const [imageIndex, setimageIndex] = useState("");
  const [toggle, setToggle] = useState(false);

  const getList = useCallback(() => {
    dispatch(
      otherOrderList({
        page,
        limit: 10,
        search: search,
        category_name: category,
        status,
      })
    ).then((res) => {
      console.log(res, "Res");
      if (res?.payload?.data) {
        let arr = res?.payload?.data;
        let arr2 = arr.map((item, i) => {
          let {
            images,
            country_code,
            status,
            payment_mode,
            advanced,
            cod_amount,
            charms,
            items,
            name_on_wallet,
            online_amount,
            ...rest
          } = item;
          if (category === "wallet") {
            return {
              advanced,
              cod_amount,
              online_amount,
              payment_mode:
                payment_mode?.toString() === "1"
                  ? "Online Paymennt"
                  : payment_mode?.toString() === "2"
                  ? "COD"
                  : "",
              image: images?.[0]?.name,
              ...rest,
            };
          }
          return {
            name_on_wallet,
            charms,
            items,
            payment_mode:
              payment_mode?.toString() === "1"
                ? "Online Paymennt"
                : payment_mode?.toString() === "2"
                ? "COD"
                : "",
            image: images?.[0]?.name,
            ...rest,
          };
        });
        setDataToExport(arr2);
      }
      setLoading(false);
    });
  }, [dispatch, search, category, page, status]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleUpdateOrder = (id) => {
    dispatch(
      updateOtherOrderStatus({
        id: id,
        filter: {
          page,
          limit: 10,
          search: search,
          category_name: category,
          status,
        },
      })
    );
  };

  const makeFullScreen = (ind) => {
    let el = document.getElementById(`fullImage${ind}`);
    if (!document.fullscreenElement) {
      el.requestFullscreen();
      return;
    } else {
      toast.error("FullscreenElement is not supported by this browser");
    }
    document.exitFullscreen();
  };

  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="link-active">
              <img src="assets/images/Users-selected.png" alt="" />
              <span>
                <p>{list && list?.total}</p>
                <p>Total Other Orders</p>
              </span>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div class="d-flex align-items-center lg-justify-content-end">
              <form class="has-search">
                <span class="form-search-icon form-control-feedback"></span>
                <input
                  type="search"
                  class="form-control"
                  placeholder="Search Order by name"
                  name="search"
                  onChange={(e) => handleSearch(e)}
                />
              </form>
              <div className="">
                {dataToExport?.length > 0 && (
                  <ExportToCsv
                    data={dataToExport}
                    className="add-new cursor-pointer"
                    fileName={`${
                      status === "1"
                        ? "Pending"
                        : status === "2"
                        ? "Completed"
                        : ""
                    }-${category}.csv`}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-sm-12">
            <div class="row align-items-center">
              <div class="col-sm-12 col-md-6 col-lg-5 mb-3">
                <ul class="nav nav-tabs group-nav" role="tablist">
                  <li class="nav-item">
                    <Link
                      className={
                        `nav-link ` + (category === "wallet" ? "active" : "")
                      }
                      onClick={() => setCategory("wallet")}
                      data-toggle="tab"
                      to="#"
                    >
                      Wallet
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      className={
                        `nav-link ` +
                        (category === "wall_hanging" ? "active" : "")
                      }
                      onClick={() => setCategory("wall_hanging")}
                      data-toggle="tab"
                      to="#"
                    >
                      Wall Hanging
                    </Link>
                  </li>
                </ul>
              </div>
              <div class="col-sm-12 col-md-7 col-lg-7 mb-3">
                <div class="d-flex filter-flex align-items-center lg-justify-content-end">
                  <span>Filter</span>

                  <span class="ml-3">
                    <select
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                      name="status"
                      class="form-control h-auto"
                      value={status}
                    >
                      <option value="">Select Status</option>
                      <option value="1">Pending</option>

                      <option value="2">Completed</option>
                    </select>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="tab-content mt-4">
              <div id="inactive-product" class="tab-pane active">
                <div class="table-responsive text-center">
                  <table class="table storetable">
                    <thead class="text-center">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">product image</th>
                        {/* <th scope="col">Name</th>
                        <th scope="col">Mobile Number </th> */}
                        <th scope="col">Category Name </th>
                        <th scope="col">Address</th>
                        {category === "wallet" ? (
                          <>
                            {" "}
                            <th scope="col">Name on Wallet</th>
                            <th scope="col">Items</th>
                            <th scope="col">Charms</th>
                          </>
                        ) : null}
                        <th scope="col">Payment Mode</th>
                        <th scope="col">Advance Amount</th>
                        <th scope="col">COD Amount</th>
                        <th scope="col">Online Amount</th>
                        <th scope="col">Note</th>
                        <th scope="col">action</th>
                      </tr>
                      <tr class="border-0 bg-none invisible">
                        <td class="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={7}>
                            <Loader />
                          </td>
                        </tr>
                      ) : list?.data?.length > 0 ? (
                        list?.data?.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item?.id}</td>

                              <td>
                                <div
                                  style={{
                                    height: "80px",
                                    width: "80px",
                                    margin: "0 auto",
                                    borderRadius: "10px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <img
                                    src={
                                      item.images?.[0]?.name ||
                                      "assets/images/employe.png"
                                    }
                                    class="object-fit-cover"
                                    alt=""
                                    id={`fullImage${i}`}
                                    onClick={() => {
                                      makeFullScreen(i);
                                    }}
                                  />
                                </div>
                              </td>
                              {/* <td>{item?.name}</td>
                              <td>{item?.mobile_number}</td> */}
                              <td>{item?.category_name}</td>
                              <td>{item?.address}</td>

                              {category === "wallet" ? (
                                <>
                                  <td>{item?.name_on_wallet}</td>
                                  <td>{item?.items}</td>
                                  <td>{item?.charms}</td>
                                </>
                              ) : null}
                              <td>
                                {item?.payment_mode === 2
                                  ? "COD"
                                  : item?.payment_mode === 1
                                  ? "Online Payment"
                                  : "NA"}
                              </td>
                              <td>{item?.advanced || "-"}</td>
                              <td>{item?.cod_amount || "-"}</td>
                              <td>{item?.online_amount || "-"}</td>
                              <td>{item?.note || "-"}</td>

                              <td>
                                <button class="btn">
                                  <Link
                                    to={`/admin/manage-other-order/view/${item?.id}`}
                                  >
                                    <img alt="" src="assets/images/view.png" />
                                  </Link>
                                </button>
                                <Link
                                  style={{ fontSize: "12px" }}
                                  to="#"
                                  onClick={() => {
                                    handleUpdateOrder(item?.id);
                                  }}
                                  class="add-new"
                                >
                                  Complete
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <td colSpan={7}>No data found</td>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {list?.total && list?.total > 10 ? (
                <div>
                  <Pagination
                    activePage={list?.current_age}
                    itemsCountPerPage={10}
                    totalItemsCount={list?.total}
                    pageRangeDisplayed={5}
                    onChange={(val) => {
                      setPage(val);
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}
