import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function BarChart(props) {
  const labels = props.labels;
  const options = {
    responsive: true,
    plugins: {
      maintainAspectRatio: true,

      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: props.isMobile ? false : props?.full ? true : false,
          //   drawOnChartArea: props?.full ? true : false,
        },
        ticks: {
          display: props.isMobile ? false : props?.full ? true : false,
        },
      },

      y: {
        grid: {
          display: props.isMobile ? false : props?.full ? true : false,
          //   drawOnChartArea: props?.full ? true : false,
        },
        ticks: {
          beginAtZero: props.isMobile ? false : props?.full ? true : false,
          display: props.isMobile ? false : props?.full ? true : false,
        },
      },
    },
  };
  const data = {
    labels,
    datasets: [
      {
        label: props.label,
        data: props.data,
        backgroundColor: props?.full ? "#ffaf02" : "rgb(255 255 255)",
        borderColor: "#fff",
        pointRadius: 1,
        borderWidth: 1,
      },
    ],
  };
  return <Bar options={options} data={data} />;
}

export default BarChart;
