import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import FormInputText from "../../../components/FormInputText";
import { categoryList } from "../ManageCategory/categorySlice";
import { subCategoryList } from "../ManageCategory/subCategorySlice";
import { addProduct } from "./productSlice";
import Upload from "../../../components/uploadFile.js";
import ColorModal from "../../../components/colorModal";
import { occasions, occasionList } from "../ManageOccasion/occasionSlice";
import { relationList, relations } from "../ManageRelation/relationSlice";
import Editor from "../../../components/editor";
import DescriptionImageModal from "../../../components/descriptionImage";
import Select from "react-select";
import InputModal from "../../../components/inputModal";
import SelectBoxModal from "../../../components/selectBox";

const reactSelectMultipleOptionStyle = {
  valueContainer: (styles) => ({
    ...styles,
    overflow: "hidden",
    overflowY: "scroll",
    height: "30px",
  }),
};

const AddProduct = () => {
  const props = {
    width: 400,
    height: 450,
    zoomWidth: 600,
    img: "https://media-appsinvo.s3.amazonaws.com/3insta.jpg",
    zoomPosition: "original",
  };
  // const inputFile = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setstate] = useState([]);
  const [content, setcontent] = useState("");
  const [suitableFor, setSuitableFor] = useState("");
  const [descArr, setdescArr] = useState([]);
  const [inputarr, setinputarr] = useState([]);
  const [selecboxarr, setselecboxarr] = useState([]);
  const [addonImageArr, setAddOnImageArr] = useState([]);
  const [colorArr, setColorsArr] = useState([]);
  const [relation, setRelation] = useState([]);
  const [occasion, setOccasion] = useState([]);
  const [isSubmitting, setIssubmitting] = useState(false);
  // const [attachmentImage, setAttachmentImage] = useState({
  //   image: "",
  //   imagePreview: "",
  // });

  useEffect(() => {
    dispatch(categoryList({ page: "1", limit: "1000", search: "" }));
    dispatch(relationList({ page: "1", limit: "1000", search: "" }));
    dispatch(occasionList({ page: "1", limit: "1000", search: "" }));
  }, [dispatch]);

  const data = useSelector((state) => state.category.categoryList.data);
  const subCategoryData = useSelector(
    (state) => state.subCategory.subCategoryList.data
  );
  const relationlist = useSelector(relations);
  const occasionlist = useSelector(occasions);
  const validationSchema = Yup.object().shape({
    sub_category: Yup.array().min(1, "*Sub-Category Name is Required"),

    sku_id: Yup.string()
      //   .matches(/^([A-Z]{3})([-])([0-9]+)$/, "Should be in format `BYG-123`")
      .required("*SKU ID is Required"),
    category: Yup.array().min(1, "*Category Name is Required"),
    name: Yup.string().required("*Name is Required"),
    price: Yup.string().required("*Price is Required"),
    unit: Yup.string().required("*Unit is Required"),
    // type: Yup.string().required('*Type is Required'),
    description: Yup.string().required("*Description is Required"),
    discount: Yup.number().lessThan(100, "*Number should be less than 100"),
    rank: Yup.number()
      .positive("Kindly enter a positive number")
      ?.integer("Kindly enter a valid number")
      ?.required("*Rank is Required"),
    to_be_showed: Yup.boolean(),
  });

  const {
    values,
    handleSubmit,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: {
      sub_category: [],
      category: [],
      name: "",
      price: "",
      unit: "",
      type: "",
      discount: "",
      description: "",
      size: "",
      relation: "",
      occasion: "",
      sku_id: "",
      rank: "",
      to_be_showed: false,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async ({
      category,
      sub_category,
      name,
      price,
      unit,
      type,
      discount,
      description,
      size,
      sku_id,
      rank,
      to_be_showed,
    }) => {
      console.log(inputarr);
      if (state.length && state.length > 0) {
        if (content) {
          setIssubmitting(true);
          let formData = new FormData();
          formData.append(
            "category",
            category?.map((it) => it?.value)?.toString()
          );
          formData.append("sku_id", sku_id);
          formData.append(
            "sub_category",
            sub_category?.map((it) => it?.value)?.toString()
          );
          formData.append("name", name);
          formData.append("rank", rank);
          formData.append("price", price);
          formData.append("unit", unit);
          formData.append("type", "");
          formData.append("discount", discount);
          formData.append("description", description);
          formData.append("size", size);
          formData.append(
            "relation",
            relation.map((data) => data.value).join(",")
          );
          formData.append(
            "occasion",
            occasion.map((data) => data.value).join(",")
          );
          formData.append("colour", JSON.stringify(colorArr));
          formData.append("description_images", JSON.stringify(descArr));
          formData.append("features", content);
          formData.append("suitable_for", suitableFor);
          formData.append("adddon_image", JSON.stringify(addonImageArr));
          formData.append("text", JSON.stringify(inputarr));
          formData.append(
            "select",
            JSON.stringify(
              selecboxarr.map((data) => {
                return {
                  ...data,
                  option: data.option.map((elem) => {
                    return elem.value;
                  }),
                };
              })
            )
          );
          formData.append("images", state.join(","));
          formData.append("to_be_showed", to_be_showed ? 1 : 0);

          dispatch(addProduct(formData)).then((data) => {
            console.log(data, "data");
            if (data?.payload?.response_code === 200) {
              history.push("/admin/manage-products");
              toast.success("Success");
            } else {
              toast.error(data?.payload?.message);
              setIssubmitting(false);
            }
          });
        } else {
          toast.error("*Please fill the features.");
        }
      } else {
        toast.error("*Please upload images.");
        setIssubmitting(false);
      }
    },
  });
  useEffect(() => {
    dispatch(
      subCategoryList({
        page: "1",
        limit: "1000",
        search: "",
        category: values?.category?.length
          ? values?.category?.map((it) => it?.value)?.toString()
          : "",
      })
    ).then((data) => {
      values.sub_category = [];
    });
  }, [values.category]);

  const handleRemove = (ind, name) => {
    if (name === "label") {
      const newArr = inputarr.filter((data, i) => i !== ind);
      setinputarr([...newArr]);
    } else if (name === "select") {
      const newOpt = selecboxarr.filter((data, i) => i !== ind);
      setselecboxarr([...newOpt]);
    } else if (name === "image") {
      const newOpt = addonImageArr.filter((data, i) => i !== ind);
      setAddOnImageArr([...newOpt]);
    }
  };
  const dataHandler = (data, rm) => {
    if (rm) {
      setstate([...data]);
    } else {
      setstate([...state, ...data]);
    }
  };
  // const attachmentDataHandler = (data) => {
  //   setAttachmentImage({
  //     image: data?.location,
  //     imagePreview: data?.location,
  //   });
  // };
  const handleChangeInput = (selectedOption) => {
    setRelation(selectedOption);
  };

  const handleChangeInputTwo = (selectedOption) => {
    setOccasion(selectedOption);
  };

  const handleUpload = (val) => {
    let arr = colorArr;
    if (arr.findIndex((data) => data.code === val.code) <= -1) {
      setColorsArr([...colorArr, val]);
    } else {
      toast.error("*Please change color.");
    }
  };

  const handleRemoveColor = (i) => {
    setColorsArr(colorArr.filter((data, index) => index !== i));
  };

  const handleUploadDescriptionImage = (val) => {
    let arr = descArr;
    if (arr.findIndex((data) => data.name === val.name) <= -1) {
      setdescArr([...descArr, val]);
    } else {
      toast.error("*Please change Name.");
    }
  };

  const handleRemoveDescriptionImage = (i) => {
    setdescArr(descArr.filter((data, index) => index !== i));
  };

  const handleUploadInputModal = (val) => {
    let arr = inputarr;
    if (arr.findIndex((data) => data.label === val.label) <= -1) {
      setinputarr([...inputarr, val]);
    } else {
      toast.error("*Please change Name.");
    }
  };

  const handleUploadImageModal = (val) => {
    let arr = addonImageArr;
    if (arr.findIndex((data) => data.label === val.label) <= -1) {
      setAddOnImageArr([...addonImageArr, val]);
    } else {
      toast.error("*Please change label.");
    }
  };

  const handleUploadSelectBoxModal = (val) => {
    // console.log(val, 'values')
    // let arr = selecboxarr;
    // if (arr.findIndex(data => data.name === val.name) <= -1) {
    setselecboxarr([...selecboxarr, val]);
    // }
    // else {
    //     toast.error('*Please change Name.')
    // }
  };

  // console.log(selecboxarr, 'selectbox array')
  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <h2 class="head-title">Add New Product</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <form onSubmit={handleSubmit} class="form-style">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                  <Upload dataHandler={dataHandler} multiple={true} />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <FormInputText
                    placeholder="Enter SKU ID"
                    type="text"
                    image="product.png"
                    onChange={handleChange}
                    name="sku_id"
                    value={values.sku_id}
                    title="SKU ID"
                    onBlur={handleBlur}
                  />

                  <span className="errorMessage">
                    {touched["sku_id"] && errors["sku_id"]}
                  </span>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <FormInputText
                    placeholder="Enter Product Name"
                    type="text"
                    image="product.png"
                    onChange={handleChange}
                    name="name"
                    value={values.name}
                    title="Product Name"
                  />

                  <span className="errorMessage">
                    {touched["name"] && errors["name"]}
                  </span>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <FormInputText
                    placeholder="Enter Price"
                    type="number"
                    image="product.png"
                    onChange={handleChange}
                    name="price"
                    value={values.price}
                    title="Price"
                  />

                  <span className="errorMessage">
                    {touched["price"] && errors["price"]}
                  </span>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <FormInputText
                    placeholder="Rank"
                    image="small-cate.png"
                    onChange={handleChange}
                    name="rank"
                    type="number"
                    value={values.rank}
                    title="Rank"
                  />

                  <span className="errorMessage">
                    {touched["rank"] && errors["rank"]}
                  </span>
                </div>

                <div class="col-sm-6 col-md-6 col-lg-6">
                  <FormInputText
                    placeholder="100"
                    type="number"
                    image="small-cate.png"
                    onChange={handleChange}
                    name="unit"
                    value={values.unit}
                    title="Unit"
                  />

                  <span className="errorMessage">
                    {touched["unit"] && errors["unit"]}
                  </span>
                </div>
                {/* <div class="col-sm-6 col-md-6 col-lg-6">


                                    <FormInputText
                                        placeholder="Enter Product type"
                                        type="text"
                                        image="small-cate.png"
                                        onChange={handleChange}
                                        name="type"
                                        value={values.type}
                                        title="Product Type"
                                    />

                                    <span className="errorMessage">
                                        {touched["type"] && errors["type"]}
                                    </span>
                                </div> */}
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <FormInputText
                    placeholder="10%"
                    type="number"
                    image="small-cate.png"
                    onChange={handleChange}
                    name="discount"
                    value={values.discount}
                    title="Discount"
                  />

                  <span className="errorMessage">
                    {touched["discount"] && errors["discount"]}
                  </span>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Relation</label>
                    <div class="input-container">
                      <Select
                        closeMenuOnSelect={false}
                        onChange={handleChangeInput}
                        isMulti={true}
                        placeholder="Select Relation"
                        options={
                          relationlist &&
                          relationlist.map((data) => {
                            return {
                              value: data.id,
                              label: data.name,
                            };
                          })
                        }
                      />

                      <img
                        src="assets/images/small-cate.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  <span className="errorMessage">
                    {touched["relation"] && errors["relation"]}
                  </span>
                </div>

                <div class="col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Occasion</label>
                    <div class="input-container">
                      <Select
                        closeMenuOnSelect={false}
                        onChange={handleChangeInputTwo}
                        isMulti={true}
                        placeholder="Select Occasion"
                        options={
                          occasionlist &&
                          occasionlist.map((data) => {
                            return {
                              value: data.id,
                              label: data.name,
                            };
                          })
                        }
                      />
                      {/* <select
                                                onChange={handleChange}
                                                name="occasion"
                                                value={values.occasion}
                                                class="form-control pl-5" >
                                                <option value="">Select Occastion</option>
                                                {occasionlist && occasionlist.map(data => {
                                                    return (
                                                        <option key={data.id} value={data.id}>{data.name}</option>
                                                    )
                                                })}
                                            </select> */}
                      <img
                        src="assets/images/small-cate.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  <span className="errorMessage">
                    {touched["occasion"] && errors["occasion"]}
                  </span>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label>Category</label>
                    <div class="input-container">
                      <Select
                        closeMenuOnSelect={false}
                        onChange={(val) => {
                          setFieldValue("category", val);
                        }}
                        styles={reactSelectMultipleOptionStyle}
                        isMulti={true}
                        placeholder="Select Categories"
                        options={
                          data &&
                          data.map((data) => {
                            return {
                              value: data.id,
                              label: data.name,
                            };
                          })
                        }
                      />
                      {/* <select
                        onChange={handleChange}
                        name="category"
                        value={values.category}
                        class="form-control pl-5"
                      >
                        <option value="">Select Category</option>
                        {data &&
                          data.map((data) => {
                            return (
                              <option key={data.id} value={data.id}>
                                {data.name}
                              </option>
                            );
                          })}
                      </select> */}
                      <img
                        src="assets/images/small-cate.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  <span className="errorMessage">
                    {touched["category"] && errors["category"]}
                  </span>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label>Sub-Category</label>
                    <div class="input-container">
                      <Select
                        closeMenuOnSelect={false}
                        onChange={(val) => {
                          setFieldValue("sub_category", val);
                        }}
                        styles={reactSelectMultipleOptionStyle}
                        isMulti={true}
                        placeholder="Select Sub-Categories"
                        options={
                          subCategoryData &&
                          subCategoryData.map((data) => {
                            return {
                              value: data.id,
                              label: data.name,
                            };
                          })
                        }
                      />
                      {/* <select
                        onChange={handleChange}
                        name="sub_category"
                        value={values.sub_category}
                        class="form-control pl-5"
                      >
                        <option value="">Select Sub-Category</option>
                        {subCategoryData &&
                          subCategoryData.map((data) => {
                            return (
                              <option key={data.id} value={data.id}>
                                {data.name}
                              </option>
                            );
                          })}
                      </select> */}
                      <img
                        src="assets/images/small-cate.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>

                  <span className="errorMessage">
                    {touched["sub_category"] && errors["sub_category"]}
                  </span>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group product-type py-2" id="color">
                    <label>Color</label>
                    <ColorModal handleUpload={handleUpload} />
                    <div class="input-container">
                      <input
                        readOnly
                        class="form-control pl-5"
                        placeholder="Add Color "
                      />
                      <img
                        alt=""
                        src={`assets/images/small-cate.png`}
                        class="input-img"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label>Color</label>
                    <hr />
                    <div class="product-thum-img text-center">
                      {colorArr && colorArr.length > 0 ? (
                        colorArr.map((data, index) => {
                          return (
                            <span class="position-relative">
                              <img alt="" src={data.image} class="thumb-img" />
                              <p>{data.code}</p>
                              <Link
                                to="#"
                                onClick={() => handleRemoveColor(index)}
                                class="remove-thum-img shadow"
                              >
                                <img alt="" src="assets/images/reject.png" />
                              </Link>
                            </span>
                          );
                        })
                      ) : (
                        <div>No Colors Added</div>
                      )}
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group product-type py-2" id="color">
                    <label>Description Images</label>
                    <DescriptionImageModal
                      handleUpload={handleUploadDescriptionImage}
                    />
                    <div class="input-container">
                      <input
                        readOnly
                        class="form-control pl-5"
                        placeholder="Add Description Image "
                      />
                      <img
                        alt=""
                        src={`assets/images/small-cate.png`}
                        class="input-img"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label>Description Image</label>
                    <hr />
                    <div class="product-thum-img text-center">
                      {descArr && descArr.length > 0 ? (
                        descArr.map((data, index) => {
                          return (
                            <span class="position-relative">
                              <img alt="" src={data.image} class="thumb-img" />
                              <p>{data.name}</p>
                              <Link
                                to="#"
                                onClick={() =>
                                  handleRemoveDescriptionImage(index)
                                }
                                class="remove-thum-img shadow"
                              >
                                <img alt="" src="assets/images/reject.png" />
                              </Link>
                            </span>
                          );
                        })
                      ) : (
                        <div>No Images Added</div>
                      )}
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 col-md-6 col-lg-6">
                  <FormInputText
                    placeholder="13x12, 12x12"
                    type="text"
                    image="small-cate.png"
                    onChange={handleChange}
                    name="size"
                    value={values.size}
                    title="Sizes - use comma ( , ) for multiple sizes"
                  />

                  <span className="errorMessage">
                    {touched["size"] && errors["size"]}
                  </span>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label>Description</label>
                    <div class="input-container">
                      <textarea
                        onChange={handleChange}
                        value={values.description}
                        name="description"
                        class="textarea-control"
                        rows="7"
                      ></textarea>
                    </div>
                  </div>
                  <span className="errorMessage">
                    {touched["description"] && errors["description"]}
                  </span>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label>Feature & Details</label>
                    <div class="input-container">
                      <Editor
                        value={content}
                        onBlur={(newContent) => setcontent(newContent)}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label>Suitable for</label>
                    <div class="input-container">
                      <Editor
                        value={suitableFor}
                        onBlur={(newsuitable) => setSuitableFor(newsuitable)}
                      />
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 my-3">
                  <label style={{ fontSize: "15px", fontWeight: "bold" }}>
                    Attachments
                  </label>
                </div>

                <div class="col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group product-type py-2" id="color">
                    <label>Input Modal</label>
                    <InputModal handleUpload={handleUploadInputModal} />
                    <div class="input-container">
                      <input
                        readOnly
                        class="form-control pl-5"
                        placeholder="Add Input Attachment "
                      />
                      <img
                        alt=""
                        src={`assets/images/small-cate.png`}
                        class="input-img"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group product-type py-2" id="color">
                    <label>SelectBox Input</label>
                    <SelectBoxModal
                      group={true}
                      handleUpload={handleUploadSelectBoxModal}
                    />
                    <div class="input-container">
                      <input
                        readOnly
                        class="form-control pl-5"
                        placeholder="Add Selectbox Attachment "
                      />
                      <img
                        alt=""
                        src={`assets/images/small-cate.png`}
                        class="input-img"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group product-type py-2" id="color">
                    <label>Image Upload Label</label>
                    <InputModal
                      handleUpload={handleUploadImageModal}
                      isImageUpload={true}
                    />
                    <div class="input-container">
                      <input
                        readOnly
                        class="form-control pl-5"
                        placeholder="Add Input Attachment "
                      />
                      <img
                        alt=""
                        src={`assets/images/small-cate.png`}
                        class="input-img"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group p-2">
                    <label>Input & SelectBox Attachments</label>
                    <hr />
                    <div class="">
                      <label className="m-0 mb-2">Input Attachments</label>
                      <div className="selectbox-wrapper">
                        {inputarr && inputarr.length > 0 ? (
                          inputarr.map((data, index) => {
                            return (
                              <div class="position-relative col-md-12">
                                {/* <img alt="" src={data.image} class="thumb-img" /> */}
                                <p>{data.label}</p>
                                <Link
                                  to="#"
                                  onClick={() => handleRemove(index, "label")}
                                  class="remove-thum-img shadow"
                                >
                                  <img alt="" src="assets/images/reject.png" />
                                </Link>
                              </div>
                            );
                          })
                        ) : (
                          <div className="">No Input Added</div>
                        )}
                      </div>

                      <label className="m-0 mt-2 mb-2">
                        SelectBox Attachments
                      </label>
                      <div className="selectbox-wrapper">
                        {selecboxarr && selecboxarr.length > 0 ? (
                          selecboxarr.map((data, index) => {
                            return (
                              <div class="position-relative ">
                                {/* <img alt="" src={data.image} class="thumb-img" /> */}
                                <p>{data.label}</p>
                                <span style={{ fontWeight: "bold" }}>
                                  Options :
                                </span>
                                {data.option.map((elem) => {
                                  return <div>{elem.value}</div>;
                                })}
                                <Link
                                  to="#"
                                  onClick={() => handleRemove(index, "select")}
                                  class="remove-thum-img shadow"
                                >
                                  <img alt="" src="assets/images/reject.png" />
                                </Link>
                              </div>
                            );
                          })
                        ) : (
                          <div className="">No SelectBox Added</div>
                        )}
                      </div>

                      <label className="m-0 mb-2">Image Attachments</label>
                      <div className="selectbox-wrapper">
                        {addonImageArr && addonImageArr.length > 0 ? (
                          addonImageArr.map((data, index) => {
                            return (
                              <div class="position-relative col-md-12">
                                {/* <img alt="" src={data.image} class="thumb-img" /> */}
                                <p>{data.label}</p>
                                <Link
                                  to="#"
                                  onClick={() => handleRemove(index, "image")}
                                  class="remove-thum-img shadow"
                                >
                                  <img alt="" src="assets/images/reject.png" />
                                </Link>
                              </div>
                            );
                          })
                        ) : (
                          <div className="">No Input Added</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12">
                  <label htmlFor="to_be_showed">
                    <input
                      type="checkbox"
                      value={values.to_be_showed}
                      className="mx-2"
                      name="to_be_showed"
                      onChange={handleChange}
                    />
                    Show on Dashboard
                  </label>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 mt-5">
                  <div class="text-center">
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      class="btn btn-save"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </article>
  );
};

export default AddProduct;
