import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPaymentMode } from "../../../services/helper";
import { customerDetails, detailsCustomer } from "./customerSlice";
import AddressCard from "../../../components/addressCard";

const ViewCustomer = () => {
  const dispatch = useDispatch();
  const details = useSelector(detailsCustomer);
  const { id } = useParams();
  useEffect(() => {
    dispatch(customerDetails({ id }));
  }, [dispatch, id]);
  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <h2 class="head-title">Customer Detail</h2>
            <div class="user-id">
              <span>
                Customer ID:{" "}
                <span style={{ color: "#e50142" }}>
                  {details && details.bog_id}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="view-detail">
              <div class="view-user-img">
                <img
                  alt=""
                  src={
                    details &&
                    (details?.image?.includes("http")
                      ? details.image
                      : "assets/images/employe.png")
                  }
                />
              </div>
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">
                        <img alt="" src="assets/images/Full-Name.png" />
                      </th>
                      <th scope="col">
                        <img alt="" src="assets/images/Mobile-Number.png" />
                      </th>
                      <th scope="col">
                        <img alt="" src="assets/images/Email-Address.png" />
                      </th>
                      <th scope="col">
                        <img alt="" src="assets/images/Date-of-Birth.png" />
                      </th>
                      <th scope="col">
                        <img alt="" src="assets/images/Gender.png" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>full Name</td>
                      <td>mobile number </td>
                      <td>email address</td>
                      <td>no. of orders</td>
                      <td>register date</td>
                    </tr>
                    <tr>
                      <th>
                        {details
                          ? (details.first_name && details.first_name + " ") +
                              details.last_name || "N/A"
                          : "N/A"}
                      </th>
                      <th>{details && details.mobile_number}</th>
                      <th>{details && (details.email || "N/A")}</th>
                      <th>{details && details.orderCount}</th>
                      <th>{details && details.created_at}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {details?.address?.length > 0 && (
            <div className="col-sm-12">
              <h2 class="head-title mb-4">Address</h2>
            </div>
          )}
          {details?.address?.map((item, i) => {
            return (
              <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                <AddressCard key={i} data={item} />
              </div>
            );
          })}
          <div class="col-sm-12">
            <h2 class="head-title mb-4">Order details</h2>
          </div>
          <div class="col-sm-12">
            <div class="table-responsive text-center">
              <table class="table storetable">
                <thead class="text-center">
                  <tr>
                    <th scope="col">order iD</th>
                    <th scope="col">product name</th>
                    <th scope="col">payment type</th>
                    <th scope="col">Price</th>
                    <th scope="col">order date</th>
                    <th scope="col">delivery date</th>
                    <th scope="col">Status</th>
                  </tr>
                  <tr class="border-0 bg-none invisible">
                    <td class="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {details && details.orderDetail.length > 0 ? (
                    details.orderDetail.map((data, i) => {
                      return (
                        <tr>
                          <td>{data.id}</td>
                          <td class="product-name">
                            {data.order_product.map(
                              (data) => data.product_name + ","
                            )}
                          </td>

                          <td>{getPaymentMode(data.payment_mode)}</td>
                          <td>
                            <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                            {data.amount}
                          </td>
                          <td>{data.created_at}</td>
                          <td>{data.delivery_date || "N/A"}</td>
                          <td>{data.status}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10}>No Data Found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default ViewCustomer;
