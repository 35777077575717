import { Modal } from "reactstrap";
import React, { useState } from "react";
import Upload from "../uploadFile.js";
import { toast } from "react-toastify";

const ColorModal = ({ handleUpload }) => {
  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);
  const [params, setparams] = useState({
    code: "",
    image: "",
  });

  const dataHandler = (data) => {
    setparams({
      ...params,
      image: data?.location,
    });
  };

  const handleSubmit = () => {
    const { image, code } = params;
    if (code && image) {
      handleUpload(params);
      toggle();
    } else {
      toast.error("*Please fill the form first.");
    }
  };
  return (
    <>
      <span
        class="add-more-type"
        onClick={toggle}
        title="Add More"
        data-toggle="modal"
        data-target="#colorMore"
      >
        +
      </span>
      <Modal className="modal-md" isOpen={modal} toggle={toggle}>
        <div class="modal-content">
          <div class="modal-body text-capitalize">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <form class="form-style">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 text-center mb-4">
                  <h4>Color</h4>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                  <Upload
                    text="color image of product"
                    dataHandler={dataHandler}
                  />
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label>Color Code</label>
                    <div class="input-container">
                      <input
                        type="color"
                        onChange={(e) =>
                          setparams({
                            ...params,
                            code: e.target.value,
                          })
                        }
                        class="form-control pl-5"
                        placeholder="Color Code (#000000)"
                      />
                      <img
                        alt=""
                        src="assets/images/product.png"
                        class="input-img"
                      />
                    </div>
                  </div>
                </div>
                {/* <div class="col-sm-6 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Image</label>
                                        <div class="input-container">
                                            <input type="file" class="form-control pl-5" />
                                            <img alt="" src="assets/images/product.png" class="input-img" />
                                        </div>
                                    </div>
                                </div> */}
                <div class="col-sm-12 col-md-12 col-lg-12 mt-3">
                  <div class="text-center">
                    <button
                      onClick={handleSubmit}
                      type="button"
                      class="btn btn-save"
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ColorModal;
