import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GetSales, sales, SalesExport } from "./salesSlice";

const SalesList = () => {
  const dispatch = useDispatch();
  const History = useHistory();
  const [modal, setModal] = useState(false);
  const [filters, setFilters] = useState({
    type: "year",
    year: "",
    month: "",
    date: "",
  });

  const data = useSelector(sales);

  useEffect(() => {
    dispatch(GetSales(filters));
  }, [dispatch, filters]);

  const handleChange = ({ name, e }) => {
    setFilters({
      ...filters,
      [name]: e.target.value,
    });
  };

  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row">
          <div class="col-sm-12">
            <div class="col-sm-12">
              <div class="row align-items-center">
                <div class="col-sm-12 col-md-5 col-lg-5 mb-3"></div>
                <div class="col-sm-12 mb-3">
                  <div class="d-flex filter-flex align-items-center lg-justify-content-end">
                    <span>Filter</span>
                    <span class="ml-3">
                      <select
                        value={filters.type}
                        onChange={(e) =>
                          handleChange({
                            name: "type",
                            e,
                          })
                        }
                        class="form-control h-auto"
                      >
                        <option value={""}>Select type</option>
                        <option value="date">Date</option>
                        <option value="month">Month</option>
                        <option value="year">Year</option>
                      </select>
                    </span>
                    <span class="ml-3">
                      <select
                        value={filters.date}
                        onChange={(e) =>
                          handleChange({
                            name: "date",
                            e,
                          })
                        }
                        class="form-control h-auto"
                      >
                        <option value={""}>Select Date</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                      </select>
                    </span>
                    <span class="ml-3">
                      <select
                        value={filters.month}
                        onChange={(e) =>
                          handleChange({
                            name: "month",
                            e,
                          })
                        }
                        class="form-control h-auto"
                      >
                        <option value={""}>Select Month</option>
                        <option value="0">Jan</option>
                        <option value="1">Feb</option>
                        <option value="2">Mar</option>
                        <option value="3">April</option>
                        <option value="4">May</option>
                        <option value="5">Jun</option>
                        <option value="6">July</option>
                        <option value="7">Aug</option>
                        <option value="8">Sep</option>
                        <option value="9">Oct</option>
                        <option value="10">Nov</option>
                        <option value="11">Dec</option>
                      </select>
                    </span>
                    <span class="ml-3">
                      <select
                        value={filters.year}
                        onChange={(e) =>
                          handleChange({
                            name: "year",
                            e,
                          })
                        }
                        class="form-control h-auto"
                      >
                        <option value={""}>Select Year</option>
                        <option value="2009">2009</option>
                        <option value="2010">2010</option>
                        <option value="2011">2011</option>
                        <option value="2012">2012</option>
                        <option value="2013">2013</option>
                        <option value="2014">2014</option>
                        <option value="2015">2015</option>
                        <option value="2016">2016</option>
                        <option value="2017">2017</option>
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                      </select>
                    </span>
                    <span
                      class="date-filter"
                      onClick={() => {
                        window.open(
                          `http://3.132.76.65/bookyourgift-api/export?type=${filters.type}&year=${filters.year}&month=${filters.month}&date=${filters.date}`,
                          "_blank"
                        );
                      }}
                      title="Date"
                    >
                      <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="tab-content mt-4">
                <div id="inactive-product" class="tab-pane active">
                  <div class="table-responsive text-center">
                    <table class="table storetable">
                      <thead class="text-center">
                        <tr>
                          <th scope="col">product Name</th>
                          <th scope="col">Total Sold</th>
                          <th scope="col">Unit Left</th>
                          <th scope="col">Price</th>
                          {/* <th scope="col">Description</th> */}
                        </tr>
                        <tr class="border-0 bg-none invisible">
                          <td class="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        {data && data.length > 0 ? (
                          data.map((dataElem) => {
                            return (
                              <tr key={dataElem.id}>
                                <td>{dataElem.product_name}</td>
                                <td> {dataElem.total}</td>
                                <td>{dataElem.left_unit || "N/A"}</td>
                                <td>{dataElem.price || "N/A"}</td>
                                {/* <td>
                                  <div
                                    style={{
                                      maxWidth: "200px",
                                      whiteSpace: "normal",
                                    }}
                                  >
                                    {dataElem.description || "N/A"}
                                  </div>
                                </td> */}
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={7}>No Data Found.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};
export default SalesList;
