import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData, putEncodedData } from "../../../services/httphandler";


const initialState = {
    isLoading: false,
    tnc: ''
}

export const getTnc = createAsyncThunk(
    'cms/getTnc',
    async () => {
        let response = await getData('cms/getTnc');
        return response

    }
)

export const updateTnc = createAsyncThunk(
    'cms/updateTnc',
    async (object) => {
        let response = await putEncodedData('cms/updateTnc', object);
        return response

    }
)


export const getShippingPolicy = createAsyncThunk(
    'cms/getRefundPolicy',
    async () => {
        let response = await getData('cms/getShippingPolicy');
        return response

    }
)

export const updateShippingDelivery = createAsyncThunk(
    'cms/updateRefundPolicy',
    async (object) => {
        let response = await putEncodedData('cms/updateShippingDelivery', object);
        return response

    }
)

export const getCancellationPolicy = createAsyncThunk(
    'cms/getCancellationPolicy',
    async () => {
        let response = await getData('cms/getCancellationPolicy');
        return response

    }
)

export const updateCancellationPolicy = createAsyncThunk(
    'cms/updateCancellationPolicy',
    async (object) => {
        let response = await putEncodedData('cms/updateCancellationPolicy', object);
        return response

    }
)

export const getPrivacy = createAsyncThunk(
    'cms/getPrivacy',
    async () => {
        let response = await getData('cms/getPrivacy');
        return response

    }
)

export const updatePrivacy = createAsyncThunk(
    'cms/updatePrivacy',
    async (object) => {
        let response = await putEncodedData('cms/updatePrivacy', object);
        return response

    }
)

export const getAbout = createAsyncThunk(
    'cms/getAbout',
    async () => {
        let response = await getData('cms/getAbout');
        return response

    }
)

export const updateAbout = createAsyncThunk(
    'cms/updateAbout',
    async (object) => {
        let response = await putEncodedData('cms/updateAbout', object);
        return response

    }
)

export const getContactUs = createAsyncThunk(
    'cms/getContactUs',
    async () => {
        let response = await getData('cms/getContactUs');
        return response

    }
)

export const updateContactUs = createAsyncThunk(
    'cms/updateContactUs',
    async (object) => {
        let response = await putEncodedData('cms/updateContactUs', object);
        return response

    }
)

export const getContactNumber = createAsyncThunk(
    'cms/getContactNumber',
    async () => {
        let response = await getData('cms/getContactNumber');
        return response

    }
)

export const updateContactNumber = createAsyncThunk(
    'cms/updateContactNumber',
    async (object) => {
        let response = await putEncodedData('cms/updateContactNumber', object);
        return response

    }
)



const CmsSlice = createSlice({
    name: 'cms',
    initialState,
})


export default CmsSlice.reducer;