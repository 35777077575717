import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import FormInputText from '../../../components/FormInputText';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { getCancellationPolicy, getRefundPolicy, getTnc, updateCancellationPolicy, updateRefundPolicy, updateTnc } from './cmsSlice';
import TextEditor from '../../../components/wysiwyg';

const UpdateCancellationPolicy = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [state, setstate] = useState({
        content: ''
    })

    const validationSchema = Yup.object().shape({
        content: Yup.string().required('*Content is Required'),
    });

    const {
        values,
        handleSubmit,
        touched,
        errors,
        handleChange,
        setFieldValue
    } = useFormik({
        initialValues: state,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async () => {

            dispatch(updateCancellationPolicy(values)).then(data => {
                if (data?.payload?.response_code === 200) {
                    // history.push('/admin/faqs');
                    toast.success(data.payload.message);
                }
                else {
                    toast.error(data?.payload?.message);
                }
            })
        },
    });

    useEffect(() => {
        dispatch(getCancellationPolicy()).then(data => {
            setstate({
                content: data.payload.data.cancellationPolicy.content
            })
        })
    }, [dispatch]);



    return (
        <article class="col-lg-9">
            <div class="article-body store-bd-gy">
                <div class="row align-items-center col-rever">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <h2 class="head-title">Update Cancellation Policy</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <form onSubmit={handleSubmit} class="form-style">
                            <div class="row">


                                <div class="col-sm-12 col-md-2 col-lg-2"></div>
                                <div class="col-sm-12 col-md-8 col-lg-8">
                                    <div class="form-group">
                                        {/* <label>Content</label>
                                        <div class="input-container">
                                            <textarea
                                                onChange={handleChange}
                                                value={values.content}
                                                name="content"
                                                class="textarea-control" rows="7"></textarea>
                                        </div> */}
                                        <TextEditor onChange={(val) => {
                                            // console.log(val)
                                            setFieldValue("content", val)

                                        }} text={values?.content} />
                                    </div>
                                    <span className="errorMessage">
                                        {touched["content"] && errors["content"]}
                                    </span>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="text-center">
                                        <button type="submit" class="btn btn-save">Save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </article>
    )
}

export default UpdateCancellationPolicy;