import { Modal } from "reactstrap";
import { useState } from "react";
import { toast } from "react-toastify";

const DateFilterModal = ({ dateHandler }) => {
    const [modal, setModal] = useState(false);
    const [state, setstate] = useState({
        startDate: '',
        endDate: ''
    })
    const toggle = () => setModal(!modal);

    const handleDates = (e) => {
        e.preventDefault();
        const { startDate, endDate } = state;
        if (startDate && endDate) {
            dateHandler({ startDate, endDate });
            toggle();
        }
        else
            toast.error('*Please fill both date fields.')


    }

    const handleChange = (e) => {
        const { value, name } = e.target;
        setstate({
            ...state,
            [name]: value
        })

    }

    return (
        <>
            <span class="date-filter" title="Date" onClick={toggle}><i class="fa fa-filter" aria-hidden="true"></i></span>
            <Modal isOpen={modal} toggle={toggle}>
                <div class="modal-body text-capitalize">
                    <button onClick={toggle} type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <form class="form-style">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 text-center mb-4">
                                <h4>Select Date</h4>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-6">
                                <div class="form-group">
                                    <label>From</label>
                                    <div class="input-container">
                                        <input
                                            type="date"
                                            name="startDate"
                                            class="form-control pl-5"
                                            placeholder="11/01/2021"
                                            value={state.startDate}
                                            onChange={handleChange}
                                        />
                                        <i class="fa fa-calendar input-img" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-6">
                                <div class="form-group">
                                    <label>To</label>
                                    <div class="input-container">
                                        <input
                                            type="date"
                                            class="form-control pl-5"
                                            placeholder="15/03/2021"
                                            name="endDate"
                                            min={state.startDate}
                                            value={state.endDate}
                                            onChange={handleChange}
                                        />
                                        <i class="fa fa-calendar input-img" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12 mt-3">
                                <div class="text-center">
                                    <button onClick={handleDates} class="btn btn-save">Done</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    )
}

export default DateFilterModal;