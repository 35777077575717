import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import SelectBoxModal from "../../../components/selectBox";
import { addGroup } from './groupSlice';

const AddGroup = () => {
    const [selecboxarr, setselecboxarr] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleUploadSelectBoxModal = (val) => {
        setselecboxarr([val])
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        var formdata = new FormData();
        const object = selecboxarr.length > 0 && selecboxarr[0];
        for (const key in object) {
            if (Object.hasOwnProperty.call(object, key)) {
                if (Array.isArray(object[key])) {
                    formdata.append(key, JSON.stringify(object[key]))
                }
                else {
                    formdata.append(key, object[key])
                }

            }
        }
        dispatch(addGroup(formdata)).then(data => {
            if (data.payload.response_code === 200) {
                history.push('/admin/manage-group')
                toast.success(data.payload.message);

            }
            else toast.success(data.payload.message)
        })
    }

    // console.log(selecboxarr)
    return (
        <article class="col-lg-9">
            <div class="article-body store-bd-gy">
                <div class="row align-items-center col-rever">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <h2 class="head-title">Add New Group</h2>
                    </div>
                </div>
                <div class="row">
                    <form onSubmit={handleSubmit} class="form-style">
                        <div class="row">
                            <div class="col-sm-12">

                                <div class="form-group product-type py-2" id="color">
                                    <label>SelectBox Input</label>
                                    <SelectBoxModal handleUpload={handleUploadSelectBoxModal} />
                                    <div class="input-container">
                                        <input
                                            readOnly
                                            class="form-control pl-5"
                                            placeholder="Add Selectbox Attachment " />
                                        <img
                                            alt=""
                                            src={`assets/images/small-cate.png`}
                                            class="input-img" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="form-group p-2">
                                    <label>Input & SelectBox Attachments</label>
                                    <hr />
                                    <div class="">

                                        <label className="m-0 mt-2 mb-2">SelectBox  Attachments</label>
                                        <div className="selectbox-wrapper">
                                            {
                                                selecboxarr && selecboxarr.length > 0 ? selecboxarr.map((data, index) => {
                                                    return (
                                                        <div class="position-relative ">
                                                            {/* <img alt="" src={data.image} class="thumb-img" /> */}
                                                            <p>{data.label}</p>
                                                            <span style={{ fontWeight: 'bold' }}>Options :</span>
                                                            {
                                                                data.option.map(elem => {
                                                                    return <div>{elem.value}</div>
                                                                })
                                                            }
                                                            {/* <Link to="#" onClick={() => handleRemove(index, "select")} class="remove-thum-img shadow"><img alt="" src="assets/images/reject.png" /></Link> */}
                                                        </div>
                                                    )
                                                })
                                                    :
                                                    <div className="">No SelectBox Added</div>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12 mt-5">
                                <div class="text-center">
                                    <button
                                        type="submit" class="btn btn-save">Save</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </article>
    )
}

export default AddGroup;