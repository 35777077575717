import { toast } from "react-toastify";
import { authHeader, authHeaderUrlEncoded } from "../helper";
import axios from "axios";

// export const baseURI = "http://18.213.29.142/bookyourgift-api/";
export const baseURI = "https://api.bookyourgift.in/bookyourgift-api/";

// Example POST method implementation:
export async function postEncodedData(url = "", data = {}) {
  // Default options are marked with *
  try {
    const response = await fetch(baseURI + url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  } catch (ex) {
    toast.error("*Check Network Connection and try again later..!!");
  }
}

export async function postFormData(url = "", data = {}) {
  // Default options are marked with *xx

  try {
    const response = await fetch(baseURI + url, {
      method: "POST",
      body: data,
    });
    return response.json();
  } catch (ex) {
    toast.error("*Check Network Connection and try again later..!!");
  }
}

export async function postFormDataWithToken(url = "", data = {}) {
  // Default options are marked with *xx
  const Token = JSON.parse(localStorage.getItem("bookyourgift-admin"));

  try {
    const response = await fetch(baseURI + url, {
      method: "POST",
      body: data,
      headers: {
        Authorization: `Bearer ${Token.token}`,
        Accept: "application/json",
      },
    });
    return response.json();
  } catch (ex) {
    toast.error("*Check Network Connection and try again later..!!");
  }
}

export const postFormDataNew = async (url = "", data = {}) => {
  console.log(data, "datagyehujm");
  try {
    const Token = JSON.parse(localStorage.getItem("bookyourgift-admin"));

    const formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.post(baseURI + url, formData, {
      headers: { Authorization: `Bearer ${Token.token}` },
    });

    return response.data;
  } catch (error) {
    // toast.error(error.message);
    // toast.error(error.response.data);
    return error.response.data;
  }
};

export async function postRawData(url = "", data = {}) {
  // Default options are marked with *
  try {
    const response = await fetch(baseURI + url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
    return response.json();
  } catch (ex) {
    toast.error("*Check Network Connection and try again later..!!");
  }
}

export async function getData(url = "", params = {}) {
  // Default options are marked with *
  try {
    let uri = new URL(baseURI + url);
    uri.search = new URLSearchParams(params).toString();
    console.log(uri, "URI");
    const response = await fetch(uri, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: authHeader(),
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return response.json();
  } catch (ex) {
    toast.error("*Check Network Connection and try again later..!!");
  }
}

export async function deleteData(url = "", data = {}) {
  // Default options are marked with *
  try {
    var urlencoded = new URLSearchParams();
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        urlencoded.append(key, element);
      }
    }
    const response = await fetch(baseURI + url, {
      method: "DELETE",

      credentials: "same-origin",
      headers: authHeaderUrlEncoded(),
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: urlencoded,
    });
    return response.json();
  } catch (ex) {
    toast.error("*Check Network Connection and try again later..!!");
  }
}

export async function putFormData(url = "", data = {}) {
  // Default options are marked with *
  try {
    const Token = JSON.parse(localStorage.getItem("bookyourgift-admin"));
    const response = await fetch(baseURI + url, {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        Authorization: `Bearer ${Token.token}`,
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
    return response.json();
  } catch (ex) {
    toast.error("*Check Network Connection and try again later..!!");
  }
}

export async function putEncodedData(url = "", data = {}) {
  // Default options are marked with *
  var urlencoded = new URLSearchParams();
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const element = data[key];
      urlencoded.append(key, element);
    }
  }
  try {
    const Token = JSON.parse(localStorage.getItem("bookyourgift-admin"));
    const response = await fetch(baseURI + url, {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${Token.token}`,
        Accept: "application/json",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: urlencoded, // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  } catch (ex) {
    toast.error("*Check Network Connection and try again later..!!");
  }
}
