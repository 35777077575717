import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData } from "../../../services/httphandler";

const initialState = {
  isLoader: false,
  categoryReportList: [],
  total: 0,
};

export const sales = (state) => state.categoryReport.categoryReportList;

export const GetCategoryReport = createAsyncThunk(
  "categoryReport/get",
  async (object) => {
    let response = await getData("categoryReport", object);
    return response.data;
  }
);

const CategoryReportSlice = createSlice({
  name: "categoryReport",
  initialState,
  extraReducers: {
    [GetCategoryReport.fulfilled]: (state, action) => {
      state.categoryReportList = action.payload;
    },
  },
});

export default CategoryReportSlice.reducer;
