import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import DeleteModal from "../../../components/deleteModal";
import { toast } from "react-toastify";
import { couponList, deleteCoupon } from "./couponSlice";
import { putEncodedData } from "../../../services/httphandler";
import { useCallback } from "react";
import { debounce } from "lodash";

const CouponList = () => {
  const dispatch = useDispatch();
  const History = useHistory();
  const [modal, setModal] = useState(false);
  const [id, setDeleteId] = useState("");
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    search: "",
    active: 1,
  });
  const data = useSelector((state) => state.coupon.couponList?.coupons?.data);
  const total = useSelector((state) => state.coupon.couponList?.coupons?.total);

  const listing = useCallback(() => {
    dispatch(couponList(filters));
  }, [dispatch, filters]);

  useEffect(() => {
    listing();
  }, [listing]);

  const handlePageChange = (pageNumber) => {
    setFilters({
      ...filters,
      page: pageNumber,
    });
  };

  const handleChange = debounce((e) => {
    setFilters({
      ...filters,
      search: e.target.value,
    });
  }, 300);
  const toggleTab = (val) => {
    setFilters({
      ...filters,
      active: val,
    });
  };

  const toggle = () => {
    setModal(!modal);
  };

  const deleteCat = () => {
    dispatch(deleteCoupon({ id, filters })).then((data) => {
      if (data?.payload?.response_code === 200) {
        toggle();
        toast.success("Success");
      } else {
        toast.error(data?.payload?.message);
      }
    });
  };

  const handleToggle = (id) => {
    putEncodedData("coupon/toggle", { id: id }).then((data) => {
      if (data?.response_code === 200) {
        dispatch(couponList(filters));
        toast.success("Success");
      } else {
        toast.error(data?.message);
      }
    });
  };

  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-6 col-md-6 col-lg-6">
            <div class="link-active">
              <img src="assets/images/Users-selected.png" alt="" />
              <span>
                <p>{total && total}</p>
                <p>Total Coupons</p>
              </span>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6">
            <div class="d-flex align-items-center lg-justify-content-end">
              <form class="has-search">
                <span class="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search Coupon by name"
                  onChange={handleChange}
                />
              </form>
              <Link to="/admin/manage-coupons/add" class="add-new">
                + Add New
              </Link>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-5 mb-3">
            <ul class="nav nav-tabs group-nav" role="tablist">
              <li class="nav-item">
                <Link
                  className={`nav-link ` + (filters.active ? "active" : "")}
                  onClick={() => toggleTab(1)}
                  data-toggle="tab"
                  to="#"
                >
                  active
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  className={`nav-link ` + (filters.active ? "" : "active")}
                  onClick={() => toggleTab(0)}
                  data-toggle="tab"
                  to="#"
                >
                  inactive
                </Link>
              </li>
            </ul>
          </div>
          <div class="col-sm-12">
            <div class="table-responsive text-center">
              <table class="table storetable">
                <thead class="text-center">
                  <tr>
                    <th scope="col">s.no.</th>
                    <th scope="col">coupon Image</th>
                    <th scope="col">coupon code</th>
                    <th scope="col">coupon type</th>
                    <th scope="col">Coupon Offer</th>
                    <th scope="col">action</th>
                  </tr>
                  <tr class="border-0 bg-none invisible">
                    <td class="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((dataElem, i) => {
                      return (
                        <tr key={dataElem.id}>
                          <td>{i + 1 + (filters.page - 1) * 10}</td>
                          <td>
                            <div
                              style={{
                                height: "80px",
                                width: "80px",
                                margin: "0 auto",
                                borderRadius: "10px",
                                overflow: "hidden",
                              }}
                            >
                              <img
                                alt=""
                                src={
                                  dataElem.image || "assets/images/employe.png"
                                }
                                class="object-fit-cover"
                              />
                            </div>
                          </td>
                          <td>{dataElem.code}</td>
                          <td>{dataElem.discount_type}</td>
                          <td>
                            {dataElem.discount_type === "percentage"
                              ? `${dataElem.discount}%`
                              : dataElem.discount_type === "fixed"
                              ? `${dataElem.fixed_amount}`
                              : dataElem.discount_type === "bogo"
                              ? `Buy ${dataElem.buy_one} Get ${dataElem.get_one}`
                              : dataElem.discount_type === "freeShipping"
                              ? `Free Shipping`
                              : "NA"}
                          </td>
                          <td>
                            <div class="d-flex justify-content-center action">
                              <button
                                onClick={() =>
                                  History.push(
                                    `/admin/manage-coupons/edit/${dataElem.id}`
                                  )
                                }
                                class="btn"
                              >
                                <img alt="" src="assets/images/edit.png" />
                              </button>
                              <button class="btn">
                                <input
                                  checked={dataElem.active}
                                  onChange={() => handleToggle(dataElem.id)}
                                  type="checkbox"
                                  id={dataElem.id}
                                  class="toggle"
                                  alt=""
                                />
                              </button>
                              <button
                                onClick={() => {
                                  setDeleteId(dataElem.id);
                                  toggle();
                                }}
                                class="btn"
                              >
                                <img src="assets/images/delete.png" alt="" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4}>No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {total && total > 10 ? (
          <div>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.limit}
              totalItemsCount={total}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <DeleteModal deleteCategory={deleteCat} modal={modal} toggle={toggle} />
    </article>
  );
};
export default CouponList;
