import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Editor from "../../../components/editor";
import FormInputText from "../../../components/FormInputText";
import { productDetails } from "./productSlice";
import Select from "react-select";
import DeleteModal from "../../../components/deleteModal";
import {
  deleteData,
  postFormDataWithToken,
} from "../../../services/httphandler";
import { toast } from "react-toastify";
import ReviewModal from "../../../components/reviewModal";

const ProductDetails = () => {
  const history = useHistory();
  const [delId, setDeleteId] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const [relation, setRelation] = useState([]);
  const [inputarr, setinputarr] = useState([]);
  const [selecboxarr, setselecboxarr] = useState([]);
  const [modal, setModal] = useState(false);
  const [occasion, setOccasion] = useState([]);

  const [params, setparams] = useState({
    sub_category: [],
    category: [],
    name: "",
    price: "",
    unit: "",
    type: "",
    discount: "",
    description: "",
    images: "",
    size: "",
    descArr: [],
    colorArr: [],
    content: "",
    reviews: "",
    sku_id: "",
  });
  useEffect(() => {
    window.scroll(0, 0);
    dispatch(productDetails({ id })).then((data) => {
      setparams({
        sku_id: data.payload.product?.sku_id,

        sub_category: data?.payload?.product?.subcategory?.map((it) => {
          return {
            value: it?.id,
            label: it?.product_sub_category?.name,
          };
        }),
        category: data?.payload?.product?.category?.map((it) => {
          return {
            value: it?.id,
            label: it?.product_category?.name,
          };
        }),
        name: data.payload.product.name,
        price: data.payload.product.price,
        unit: data.payload.product.unit,
        // type: data.payload.product.type,
        discount: data.payload.product.discount,
        description: data.payload.product.description,
        id: data.payload.product.id,
        size: data.payload.product.sizes.map((data) => data.size).join(","),
        reviews: data.payload.product.reviews,
        images: data.payload.product.images.map((data) => {
          return { name: data.name };
        }),
        descArr: data.payload.product.description_images.map((data) => {
          return {
            name: data.name,
            image: data.image,
          };
        }),

        colorArr: data.payload.product.colours.map((data) => {
          return {
            code: data.colour_code,
            image: data.image,
          };
        }),

        content: data.payload.product.features,
        suitable_for: data.payload.product.suitable_for,
      });

      setRelation(
        data.payload.product.product_relation.map((data) => {
          return {
            label: data.relation.name,
            value: data.relation.id,
          };
        })
      );
      setOccasion(
        data.payload.product.product_occasion.map((data) => {
          return {
            label: data.occasion.name,
            value: data.occasion.id,
          };
        })
      );
      setinputarr(
        data.payload.product.text.map((data) => {
          return {
            label: data.label,
          };
        })
      );

      setselecboxarr(
        data.payload.product.select.map((data) => {
          return {
            label: data.label,
            option: data.options.map((elem) => {
              return {
                value: elem.option,
              };
            }),
          };
        })
      );
    });
  }, [dispatch, id]);

  const toggle = () => {
    setModal(!modal);
  };

  const deleteCat = async () => {
    var response = await deleteData("product/reviewDelete", { id: delId });
    if (response.response_code === 200) {
      toggle();
      toast.success("Success");
      dispatch(productDetails({ id })).then((data) => {
        setparams({
          sub_category: data?.payload?.product?.subcategory?.map((it) => {
            return {
              value: it?.id,
              label: it?.product_sub_category?.name,
            };
          }),
          category: data?.payload?.product?.category?.map((it) => {
            return {
              value: it?.id,
              label: it?.product_category?.name,
            };
          }),
          name: data.payload.product.name,
          price: data.payload.product.price,
          unit: data.payload.product.unit,
          // type: data.payload.product.type,
          discount: data.payload.product.discount,
          description: data.payload.product.description,
          id: data.payload.product.id,
          size: data.payload.product.sizes.map((data) => data.size).join(","),
          reviews: data.payload.product.reviews,
          images: data.payload.product.images.map((data) => {
            return { name: data.name };
          }),
          descArr: data.payload.product.description_images.map((data) => {
            return {
              name: data.name,
              image: data.image,
            };
          }),

          colorArr: data.payload.product.colours.map((data) => {
            return {
              code: data.colour_code,
              image: data.image,
            };
          }),
          content: data.payload.product.features,
        });

        setRelation(
          data.payload.product.product_relation.map((data) => {
            return {
              label: data.relation.name,
              value: data.relation.id,
            };
          })
        );
        setOccasion(
          data.payload.product.product_occasion.map((data) => {
            return {
              label: data.occasion.name,
              value: data.occasion.id,
            };
          })
        );
      });
    } else {
      toast.error(response?.message);
    }
  };

  const handleReview = async (val) => {
    let formdata = new FormData();
    formdata.append("product_id", id);
    for (const key in val) {
      if (Object.hasOwnProperty.call(val, key)) {
        const element = val[key];

        formdata.append(key, element);
      }
    }

    let response = await postFormDataWithToken("product/addReviews", formdata);
    if (response && response.response_code === 200) {
      toast.success("review successfully done !!");
      dispatch(productDetails({ id })).then((data) => {
        setparams({
          ...params,
          reviews: data.payload.product.reviews,
        });
      });
    }
  };

  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <h2 class="head-title">Product Details</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <form class="form-style">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label>Product Images</label>
                    <hr />
                    <div class="product-thum-img text-center">
                      {params.images && params.images.length > 0 ? (
                        params.images.map((data, index) => {
                          return (
                            <span class="position-relative">
                              <img alt="" src={data.name} class="thumb-img" />
                              {/* <p>{data.name}</p> */}
                              {/* <Link to="#" onClick={() => handleRemoveDescriptionImage(index)} class="remove-thum-img shadow"><img alt="" src="assets/images/reject.png" /></Link> */}
                            </span>
                          );
                        })
                      ) : (
                        <div>No Images Added</div>
                      )}
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 col-md-6 col-lg-6">
                  <FormInputText
                    readOnly={true}
                    placeholder="Enter SKU ID"
                    type="text"
                    image="product.png"
                    name="sku_id"
                    value={params.sku_id}
                    title="SKU ID"
                  />
                </div>

                <div class="col-sm-6 col-md-6 col-lg-6">
                  <FormInputText
                    readOnly={true}
                    placeholder="Enter Product Name"
                    type="text"
                    image="product.png"
                    name="name"
                    value={params.name}
                    title="Product Name"
                  />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <FormInputText
                    readOnly={true}
                    placeholder="Enter Price"
                    type="number"
                    image="product.png"
                    name="price"
                    value={params.price}
                    title="Price"
                  />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <FormInputText
                    readOnly={true}
                    placeholder="100"
                    type="number"
                    image="small-cate.png"
                    name="unit"
                    value={params.unit}
                    title="Unit"
                  />
                </div>

                {/* <div class="col-sm-6 col-md-6 col-lg-6">
                                <FormInputText
                                 readOnly={true}
                                    placeholder="Enter Product type"
                                    type="text"
                                    image="small-cate.png"

                                    name="type"
                                    value={params.type}
                                    title="Product Type"
                                />
                            </div> */}
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <FormInputText
                    readOnly={true}
                    placeholder="10%"
                    type="number"
                    image="small-cate.png"
                    name="discount"
                    value={params.discount}
                    title="Discount"
                  />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Relation</label>
                    <div class="input-container">
                      <Select
                        value={relation}
                        // getOptionLabel={relation && relation.map(data => data.relation.name,
                        // )}
                        // getOptionValue={relation && relation.map(data => data.id,
                        // )}

                        closeMenuOnSelect={false}
                        // onChange={handleChangeInput}
                        isMulti={true}
                        isDisabled={true}
                        placeholder="Select Relation"
                        options={relation}
                      />
                      {/* <select
                                                onChange={handleChange}
                                                name="relation"
                                                value={values.relation}
                                                class="form-control pl-5" >
                                                <option value="">Select Relation</option>
                                                {relationlist && relationlist.map(data => {
                                                    return (
                                                        <option key={data.id} value={data.id}>{data.name}</option>
                                                    )
                                                })}
                                            </select> */}
                      <img
                        src="assets/images/small-cate.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label>Occasion</label>
                    <div class="input-container">
                      <Select
                        value={occasion}
                        closeMenuOnSelect={false}
                        // onChange={handleChangeInputTwo}
                        isMulti={true}
                        isDisabled={true}
                        placeholder="Select Occasion"
                        options={occasion}
                      />
                      {/* <select
                                                onChange={handleChange}
                                                name="occasion"
                                                value={values.occasion}
                                                class="form-control pl-5" >
                                                <option value="">Select Occastion</option>
                                                {occasionlist && occasionlist.map(data => {
                                                    return (
                                                        <option key={data.id} value={data.id}>{data.name}</option>
                                                    )
                                                })}
                                            </select> */}
                      <img
                        src="assets/images/small-cate.png"
                        class="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <FormInputText
                    readOnly={true}
                    placeholder=""
                    type="text"
                    image="small-cate.png"
                    value={params?.category?.map((it) => it?.label)?.toString()}
                    title="Category"
                  />
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <FormInputText
                    readOnly={true}
                    placeholder=""
                    type="text"
                    image="small-cate.png"
                    value={params?.sub_category
                      ?.map((it) => it?.label)
                      ?.toString()}
                    title="Sub Category"
                  />
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label>Color</label>
                    <hr />
                    <div class="product-thum-img text-center">
                      {params.colorArr && params.colorArr.length > 0 ? (
                        params.colorArr.map((data, index) => {
                          return (
                            <span class="position-relative">
                              <img alt="" src={data.image} class="thumb-img" />
                              <p>{data.code}</p>
                              {/* <Link to="#" onClick={() => handleRemoveColor(index)} class="remove-thum-img shadow"><img alt="" src="assets/images/reject.png" /></Link> */}
                            </span>
                          );
                        })
                      ) : (
                        <div>No Colors Added</div>
                      )}
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label>Description Image</label>
                    <hr />
                    <div class="product-thum-img text-center">
                      {params.descArr && params.descArr.length > 0 ? (
                        params.descArr.map((data, index) => {
                          return (
                            <span class="position-relative">
                              <img alt="" src={data.image} class="thumb-img" />
                              <p>{data.name}</p>
                              {/* <Link to="#" onClick={() => handleRemoveDescriptionImage(index)} class="remove-thum-img shadow"><img alt="" src="assets/images/reject.png" /></Link> */}
                            </span>
                          );
                        })
                      ) : (
                        <div>No Images Added</div>
                      )}
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 col-md-6 col-lg-6">
                  <FormInputText
                    readOnly={true}
                    placeholder="13x12, 12x12"
                    type="text"
                    image="small-cate.png"
                    name="size"
                    value={params.size}
                    title="Sizes - use comma ( , ) for multiple sizes"
                  />
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label>Description</label>
                    <div class="input-container">
                      <textarea
                        readOnly={true}
                        value={params.description}
                        name="description"
                        class="textarea-control"
                        rows="7"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label>Feature & Details</label>
                    <div class="input-container">
                      <Editor readOnly={true} value={params.content} />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label>Suitable For</label>
                    <div class="input-container">
                      <Editor readOnly={true} value={params.suitable_for} />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 my-3">
                <label style={{ fontSize: "15px", fontWeight: "bold" }}>
                  Attachments
                </label>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="form-group p-2">
                  <label>Input & SelectBox Attachments</label>
                  <hr />
                  <div class="">
                    <label className="m-0 mb-2">Input Attachments</label>
                    <div className="selectbox-wrapper">
                      {inputarr && inputarr.length > 0 ? (
                        inputarr.map((data, index) => {
                          return (
                            <div class="position-relative col-md-12">
                              {/* <img alt="" src={data.image} class="thumb-img" /> */}
                              <p>{data.label}</p>
                              {/* <Link to="#" onClick={() => handleRemove(index, "label")} class="remove-thum-img shadow"><img alt="" src="assets/images/reject.png" /></Link> */}
                            </div>
                          );
                        })
                      ) : (
                        <div className="">No Input Added</div>
                      )}
                    </div>

                    <label className="m-0 mt-2 mb-2">
                      SelectBox Attachments
                    </label>
                    <div className="selectbox-wrapper">
                      {selecboxarr && selecboxarr.length > 0 ? (
                        selecboxarr.map((data, index) => {
                          return (
                            <div class="position-relative ">
                              {/* <img alt="" src={data.image} class="thumb-img" /> */}
                              <p>{data.label}</p>
                              <span style={{ fontWeight: "bold" }}>
                                Options :
                              </span>
                              {data.option.map((elem) => {
                                return <div>{elem.value}</div>;
                              })}
                              {/* <Link to="#" onClick={() => handleRemove(index, "select")} class="remove-thum-img shadow"><img alt="" src="assets/images/reject.png" /></Link> */}
                            </div>
                          );
                        })
                      ) : (
                        <div className="">No SelectBox Added</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 p-0">
                <div class="form-group">
                  <div className="d-flex justify-content-between align-items-center p-2">
                    <label>Reviews</label>
                    <ReviewModal handleUpload={(val) => handleReview(val)} />
                  </div>

                  <div class="table-responsive text-center">
                    <table class="table reviewtable">
                      <thead class="text-center">
                        <tr>
                          <th scope="col">Review</th>
                          <th scope="col">Review By</th>
                          <th scope="col">Email (Review By)</th>
                          <th scope="col">action</th>
                        </tr>
                        <tr class="border-0 bg-none invisible">
                          <td class="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        {params.reviews && params.reviews.length > 0 ? (
                          params.reviews.map((dataElem) => {
                            return (
                              <tr key={dataElem.id}>
                                <td>{dataElem.review_message}</td>
                                <td>
                                  {dataElem.reviwed_by.first_name || "N/A"}
                                </td>
                                <td>{dataElem.reviwed_by.email || "N/A"}</td>
                                <td>
                                  <div class="d-flex justify-content-center action">
                                    <img
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setDeleteId(dataElem.id);
                                        toggle();
                                      }}
                                      src="assets/images/delete.png"
                                      alt=""
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={7}>No Data Found.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <DeleteModal deleteCategory={deleteCat} modal={modal} toggle={toggle} />
    </article>
  );
};

export default ProductDetails;
