import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import { getData } from "../../../services/httphandler";
import { useState } from "react";
import moment from "moment";

function CartDetails() {
  const { key } = useParams();
  const params = useParams();
  const [details, setDetails] = useState([]);

  useEffect(() => {
    getData("cart/detail", { id: key }).then((res) => {
      console.log(res, "Cart detail");
      if (res && res?.response_code === 200) {
        setDetails(res?.data);
      }
    });
  }, []);

  const handleChange = () => {};
  const handleChangeStatus = () => {};

  return (
    <article class="col-lg-9">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <h2 class="head-title">Cart Detail</h2>
            <div class="user-id">
              <span>
                Customer ID:{" "}
                <span style={{ color: "#e50142" }}>
                  {details && details?.bog_id}
                </span>
              </span>
            </div>
          </div>
        </div>
        <form>
          <div class="row">
            <div class="col-sm-12">
              <div class="bg-white px-2 pb-4 pt-2 mt-4">
                <div class="row">
                  <div class="col-sm-12 col-md-4 col-lg-4 mb-4">
                    <div class="Order-Summary">
                      <i class="fa fa-user fa-icon-bg" aria-hidden="true"></i>
                      <h5 class="mb-0">Customer</h5>
                      <p class="mb-0">
                        {details
                          ? details?.first_name + " " + details?.last_name ||
                            "N/A"
                          : "N/A"}
                      </p>
                      <p
                        class="mb-0"
                        style={{
                          textTransform: "lowercase",
                        }}
                      >
                        {details && (details?.email || "N/A")}
                      </p>
                      <p class="mb-0">
                        {details && (details?.mobile_number || "N/A")}
                      </p>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="checkout-style-1 shadow">
                      <div class="table-responsive text-center">
                        <table class="table order-table">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Image</th>
                              <th>Product Name</th>
                              <th>Quantity</th>
                              <th>Attachments</th>
                              <th>Price</th>
                              {/* <th>action</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {details &&
                              details?.products?.length > 0 &&
                              details?.products?.map((data) => {
                                return (
                                  <tr key={data.product.id}>
                                    <td>{data.product.id}</td>
                                    <td>
                                      <img
                                        src={data.product.images[0].name}
                                        alt="Product"
                                        class="table-img"
                                      />
                                    </td>
                                    <td>{data.product.name}</td>
                                    <td>{data.quantity}</td>
                                    <td>
                                      {data?.order_product_answers?.length > 0
                                        ? data?.order_product_answers?.map(
                                            (data) => {
                                              return (
                                                <div>
                                                  <label
                                                    style={{
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {data?.product_label
                                                      ?.label || "N/A"}
                                                  </label>

                                                  <span>: {data.answer}</span>
                                                </div>
                                              );
                                            }
                                          )
                                        : "N/A"}
                                    </td>
                                    <td>
                                      <i
                                        class="fa fa-inr"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {data.product.price}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="col-sm-12">
              <div class="view-btns mt-5">
                <button
                  class="btn btn-save"
                  onClick={handleChangeStatus}
                  disabled={true}
                >
                  Send Email
                </button>
              </div>
            </div> */}
          </div>
        </form>
      </div>
    </article>
  );
}

export default CartDetails;
